import { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import { AdminApplicationLayoutStore } from '@stores';
import { colors, miscVariables } from '@styles';
import { observer } from 'mobx-react-lite';
import { DialogSmartButtons } from '../../../components/DialogSmartButtons/DialogSmartButtons';

const useStyles = makeStyles({
  dialogAction: {
    background: colors.gray,
    textTransform: 'none',
    color: colors.black,
    margin: '0 8px',
  },
  dialogRoot: {
    minWidth: miscVariables.dialog_root_min_width,
  },
  actionsWrapper: {
    padding: '16px 0',
  },
});

export const ReferenceNoteDialog = observer(
  ({ note, onSave, onClose, open }) => {
    const classes = useStyles();
    const { isMobile } = AdminApplicationLayoutStore;
    const [noteValue, setNoteValue] = useState(note);

    const handleNoteChange = (event) => {
      setNoteValue(event.target.value);
    };

    return (
      <Dialog
        className={classes.dialogRoot}
        open={open}
        fullScreen={isMobile}
        scroll="paper"
        maxWidth="md">
        <DialogTitle>Enter a note regarding this action</DialogTitle>
        <DialogContent className={classes.dialogRoot}>
          <TextField
            multiline
            fullWidth
            rows={10}
            value={noteValue}
            variant="outlined"
            onChange={handleNoteChange}
          />
        </DialogContent>
        <DialogActions className={classes.actionsWrapper}>
          <DialogSmartButtons
            onSave={() => onSave(noteValue)}
            onClose={onClose}
            disabled={false}
          />
        </DialogActions>
      </Dialog>
    );
  },
);
