import { privateResolverWithPayload } from '@services';
import { makeAutoObservable } from 'mobx';
import ErrorStore from '../../../../../stores/ErrorStore';
import { Education } from '../models/Education';
import EducationSectionService from './educationSection.service';

class EducationSectionStore {
  constructor() {
    this.education = null;
    this.selectedEducation = null;
    this.educationLevelTypes = null;
    this.showEducationDetailsDialog = false;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  getEducationLevelName(type) {
    return this.educationLevelTypes.find(
      (educationType) => educationType.value === type,
    )?.display_name;
  }

  clear() {
    this.education = null;
    this.educationLevelTypes = null;
  }

  clearSelectedEducation() {
    this.selectedEducation = null;
  }

  setOpenEducationDetailsDialog() {
    this.showEducationDetailsDialog = true;
  }

  setCloseEducationDetailsDialog() {
    this.showEducationDetailsDialog = false;
  }

  setSelectedEducation(id) {
    this.selectedEducation = this.education.find(
      (educationItem) => educationItem.id === id,
    );
    this.setOpenEducationDetailsDialog();
  }

  setEducation(educationArray) {
    this.education = educationArray.map(
      (education) => new Education(education),
    );
  }

  setEducationTypes(types) {
    this.educationLevelTypes = types;
  }

  getEducationTypes(applicationId) {
    ErrorStore.dropErrors();
    EducationSectionService.fetchEducationTypes(applicationId).then((data) => {
      const types = data.actions.POST.level.choices;
      this.setEducationTypes(types);
    });
  }

  getEducation(applicationId) {
    privateResolverWithPayload({
      fetcher: EducationSectionService.fetchEducation,
      payload: applicationId,
      setter: this.setEducation,
    });
  }
}

export default new EducationSectionStore();
