import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { NonFieldErrors, TextField } from '@components';
import { AuthStore, ErrorStore } from '@stores';
import { generateErrorMessages } from '@utils';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import * as Yup from 'yup';

const ResetRequestForm = observer(() => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    ErrorStore.dropErrors();
  }, []);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Required'),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await AuthStore.resetPasswordRequest(values);
        history.push('/password-reset/sent');
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="md:px-20 py-6">
        <TextField
          disabled={loading}
          label="Email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          id="email"
          errors={generateErrorMessages('email', formik, ErrorStore, 'email')}
        />
      </div>
      <NonFieldErrors />
      <div className="text-center my-8">
        <button
          disabled={loading}
          type="submit"
          className="px-8 py-3 text-2xl bg-primary text-white rounded-md">
          Reset Password
        </button>
      </div>
    </form>
  );
});

export default ResetRequestForm;
