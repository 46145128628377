import { useEffect } from 'react';
import { Loader } from '@components';
import {
  AdminApplicationLayoutStore,
  ApplicationStore,
  UtilsStore,
} from '@stores';
import { observer } from 'mobx-react-lite';
import { SectionsWrapper } from '../../components';
import { sectionsNames, sectionsTypes } from '../../services/constants';
import SectionNoteDialogStore from '../../services/SectionNoteDialogStore';
import SectionStatusesStore from '../../services/SectionStatusesStore';
import { UserSectionContent } from './components';
import UserSectionStore from './services/UserSectionStore';

export const UserSectionScene = observer(() => {
  useEffect(() => {
    const applicationId = ApplicationStore.verifiableApplication.id;
    UserSectionStore.getUserInformation(
      ApplicationStore.verifiableApplication.applicant.id,
    );
    SectionStatusesStore.getSectionStatuses(applicationId, sectionsNames.user);
    SectionNoteDialogStore.getSectionNote(sectionsTypes.user, applicationId);

    return () => {
      SectionStatusesStore.clear();
      UserSectionStore.clear();
      SectionNoteDialogStore.clear();
    };
  }, []);

  return (
    <>
      {UserSectionStore.user &&
      SectionStatusesStore.sectionStatuses &&
      UtilsStore.institutions ? (
        <SectionsWrapper
          sectionTitle="Personal Information"
          sectionName={sectionsNames.user}
          sectionFullName={sectionsNames.user}
          status={SectionStatusesStore.sectionStatuses}
          onCloseSection={AdminApplicationLayoutStore.setCloseUserDialog}
          open={AdminApplicationLayoutStore.showUserDialog}>
          <UserSectionContent userInfo={UserSectionStore.user} />
        </SectionsWrapper>
      ) : (
        <Loader message="Loading Personal Information..." />
      )}
    </>
  );
});
