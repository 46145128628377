import { ApplicationService } from '@services';
import { ApplicationStore } from '@stores';
import { makeAutoObservable } from 'mobx';

class EducationStore {
  constructor() {
    this.section = null;
    this.educations = [];
    this.options = null;
    makeAutoObservable(this);
  }

  getEducationLevelName(value) {
    const levelList = this.options.actions.POST.level.choices;
    return levelList.find((countryType) => countryType.value === value)
      ?.display_name;
  }

  async createApplicationEducation({
    institution,
    institutionOther,
    level,
    degree,
    notes,
    periodOfStudy,
  }) {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    const body = {
      institution: institution,
      level: level,
      degree: degree,
      period_of_study: periodOfStudy,
      note: notes,
      institution_other: institutionOther,
      institution_type: institutionOther ? 'O' : 'I',
      application: ApplicationStore.application.id,
    };

    await ApplicationService.createApplicationSectionItem({
      body: body,
      applicationId: ApplicationStore.application.id,
      section: 'education',
    });
    await this.getApplicationEducations();
  }

  async updateApplicationEducation(
    { institution, level, degree, notes, periodOfStudy, institutionOther },
    id,
  ) {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    const body = {
      institution: institution,
      level: level,
      degree: degree,
      period_of_study: periodOfStudy,
      institution_other: institutionOther,
      institution_type: institutionOther ? 'O' : 'I',
      note: notes,
      application: ApplicationStore.application.id,
    };

    await ApplicationService.updateApplicationSectionItem({
      body: body,
      applicationId: ApplicationStore.application.id,
      section: 'education',
      id: id,
    });
    await this.getApplicationEducations();
  }

  async getApplicationEducations() {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    const result = await ApplicationService.getCurrentApplicationSectionItem({
      applicationId: ApplicationStore.application.id,
      section: 'education',
    });
    this.educations = result.data;
  }

  async getApplicationEducationsSection() {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    const result = await ApplicationService.getCurrentApplicationSection({
      applicationId: ApplicationStore.application.id,
      section: 'education',
    });
    this.section = result.data[0];
  }

  async acknowledgeApplicationEducationsSection() {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }
    if (!this.section) {
      await this.getApplicationEducationsSection();
    }

    const body = {
      acknowledged: true,
      application: this.section.application,
    };

    const result = await ApplicationService.updateApplicationSection({
      body: body,
      applicationId: ApplicationStore.application.id,
      section: 'education',
      id: this.section.id,
    });
    this.section = result.data;
  }

  async unacknowledgeApplicationEducationsSection() {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }
    if (!this.section) {
      await this.getApplicationEducationsSection();
    }

    const body = {
      acknowledged: false,
      application: this.section.application,
    };

    const result = await ApplicationService.updateApplicationSection({
      body: body,
      applicationId: ApplicationStore.application.id,
      section: 'education',
      id: this.section.id,
    });
    this.section = result.data;
  }

  async getApplicationEducationsOptions() {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    const result = await ApplicationService.getCurrentApplicationSectionOptions(
      {
        applicationId: ApplicationStore.application.id,
        section: 'education',
      },
    );
    this.options = result.data;
  }

  async deleteApplicationEducation(id) {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    await ApplicationService.deleteApplicationSectionItem({
      id: id,
      section: 'education',
      applicationId: ApplicationStore.application.id,
    });

    await this.getApplicationEducations();
  }
}

export default new EducationStore();
