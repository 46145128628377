import { useEffect } from 'react';
import { Loader, NoDataEntered } from '@components';
import {
  AdminApplicationLayoutStore,
  ApplicationStore,
  UtilsStore,
} from '@stores';
import { observer } from 'mobx-react-lite';
import { SectionsWrapper } from '../../components';
import { sectionsNames, sectionsTypes } from '../../services/constants';
import SectionNoteDialogStore from '../../services/SectionNoteDialogStore';
import SectionStatusesStore from '../../services/SectionStatusesStore';
import { AddressItemDialog, AddressSectionContent } from './components';
import AddressesSectionStore from './services/AddressesSectionStore';

export const AddressesSectionScene = observer(() => {
  useEffect(() => {
    const applicationId = ApplicationStore.verifiableApplication.id;
    SectionStatusesStore.getSectionStatuses(
      applicationId,
      sectionsNames.addresses,
    );
    AddressesSectionStore.getAddresses(applicationId);
    AddressesSectionStore.getAddressesTypes(applicationId);
    SectionNoteDialogStore.getSectionNote(
      sectionsTypes.addresses,
      applicationId,
    );
    UtilsStore.getPhoneTypes({
      id: applicationId,
      section: sectionsNames.addresses,
    });

    return () => {
      SectionStatusesStore.clear();
      AddressesSectionStore.clear();
      SectionNoteDialogStore.clear();
    };
  }, []);

  const renderContent = () => {
    if (
      AddressesSectionStore.showAddressDetailsDialog &&
      AddressesSectionStore.selectedAddress
    ) {
      return <AddressItemDialog />;
    }

    return (
      <SectionsWrapper
        sectionTitle="Addresses"
        sectionName="address"
        sectionFullName={sectionsNames.addresses}
        status={SectionStatusesStore.sectionStatuses}
        onCloseSection={AdminApplicationLayoutStore.setCloseAddressesDialog}
        open={AdminApplicationLayoutStore.showAddressesDialog}>
        {AddressesSectionStore.addresses.length > 0 ? (
          <AddressSectionContent
            addressesInfo={AddressesSectionStore.addresses}
          />
        ) : (
          <NoDataEntered />
        )}
      </SectionsWrapper>
    );
  };

  return (
    <>
      {AddressesSectionStore.addresses &&
      SectionStatusesStore.sectionStatuses &&
      AddressesSectionStore.addressesTypes &&
      UtilsStore.phoneTypes ? (
        renderContent()
      ) : (
        <Loader message="Loading Addresses Section..." />
      )}
    </>
  );
});
