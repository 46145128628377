import { useCallback } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { observer } from 'mobx-react-lite';

export const OrganizationsSectionContent = observer(({ organizations }) => {
  const renderOrganizationItem = useCallback(
    (organization) => (
      <ListItem key={organization.id}>
        <ListItemText primary={organization.name} />
      </ListItem>
    ),
    [],
  );

  return (
    <List disablePadding>{organizations.map(renderOrganizationItem)}</List>
  );
});
