export const colors = {
  black: '#000000',
  black_light: '#525252',
  white: '#fff',
  white_01: '#e7e7e7',
  gray: '#c4c4c4',
  gray_01: '#c3c3c3',
  gray_02: '#ced4da',
  gray_light: '#d5e0ea',
  blue: '#219ed2',
  blue_light: '#41daff',
  blue_dark: '#004d67',
  green: '#00bd39',
  green_01: '#30b84c',
  red: '#eb5757',
};

// tailwind theme provider colors
export const colorsTailwind = {
  tailwind_primary: '#004D67',
  'tailwind_primary-dark': '#053747',
  tailwind_highlight: '#FBEFD2',
  tailwind_secondary: '#D1ECF1',
  'tailwind_btn-secondary': '#007BFF',
  'tailwind_tab-active': '#FBEFD2',
};
