import makeStyles from '@material-ui/core/styles/makeStyles';
import { colors } from '@styles';
import { toJS } from 'mobx';
import { MainCardContent } from './MainCardContent';
import { SecondCardContent } from './SecondCardContent';

const useStyles = makeStyles({
  contentWrapper: {
    padding: '18px 34px',
    width: '100%',
    height: '100%',
    color: colors.black,
  },
});

export const CompetitionCard = ({ competition }) => {
  const classes = useStyles();
  const currentCompetition = toJS(competition);

  return (
    <div className={classes.contentWrapper}>
      <MainCardContent currentCompetition={currentCompetition} />
      <SecondCardContent currentCompetition={currentCompetition} />
    </div>
  );
};
