import { useEffect, useState } from 'react';
import { DialogSmartButtons, WarningDialog } from '@components';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useIsMobile } from '@styles';
import { observer } from 'mobx-react-lite';
import ReferencesStore from '../services/ReferencesStore';
import { ReferenceItemCard } from './ReferenceItemCard';
import { ReferenceNoteDialog } from './ReferenceNoteDialog';

const useStyles = makeStyles({
  actionsWrapper: {
    padding: '16px 0',
  },
});

export const ReferenceDetailsDialog = observer(() => {
  const {
    setCloseReferenceDetailsDialog,
    showReferenceDetailsDialog,
    verifiableReference,
    clearDetails,
  } = ReferencesStore;
  const classes = useStyles();
  const isMobile = useIsMobile();
  const [savingData, setSavingData] = useState(false);
  const [showNoteDialog, setShowNoteDialog] = useState(false);
  const [warningDialogParams, setWarningDialogParams] = useState({
    showWarningDialog: false,
    actionStatus: '',
  });
  const [documentParams, setDocumentParams] = useState({
    editable: verifiableReference.editable,
    approved: verifiableReference.approved,
    reviewed_status: verifiableReference.reviewed_status,
    note: verifiableReference.note,
  });

  useEffect(
    () => () => {
      clearDetails();
    },
    [clearDetails],
  );

  const handleStatusFieldChange = (name, value) => {
    if (name === 'editable' && value) {
      setShowNoteDialog(true);
      return setDocumentParams({
        ...documentParams,
        editable: true,
        approved: false,
      });
    }
    if (
      documentParams.editable &&
      documentParams.note &&
      name !== 'reviewed_status'
    ) {
      return setWarningDialogParams({
        showWarningDialog: true,
        actionStatus: name,
      });
    }
    if (name === 'approved' && value) {
      return setDocumentParams({
        ...documentParams,
        editable: false,
        approved: true,
      });
    }
    setDocumentParams({ ...documentParams, [name]: value });
  };

  const handleNoteDelete = () => {
    if (warningDialogParams.actionStatus === 'editable') {
      setDocumentParams({
        ...documentParams,
        editable: false,
        approved: false,
        note: '',
      });
    }
    if (warningDialogParams.actionStatus === 'approved') {
      setDocumentParams({
        ...documentParams,
        editable: false,
        approved: true,
        note: '',
      });
    }
    setWarningDialogParams({ showWarningDialog: false, actionStatus: '' });
  };

  const handleWarningDialogClose = () => {
    setWarningDialogParams({
      ...warningDialogParams,
      showWarningDialog: false,
    });
  };

  const handleNoteSave = (value) => {
    setDocumentParams({
      ...documentParams,
      note: value,
    });
    setShowNoteDialog(false);
  };

  const handleReviewSave = () => {
    setSavingData(true);
    verifiableReference.patch(
      documentParams,
      ReferencesStore.setCloseReferenceDetailsDialog,
    );
  };

  return (
    <>
      {showNoteDialog ? (
        <ReferenceNoteDialog
          open={showNoteDialog}
          note={documentParams.note}
          onSave={handleNoteSave}
          onClose={() => setShowNoteDialog(false)}
        />
      ) : null}
      {warningDialogParams.showWarningDialog ? (
        <WarningDialog
          open={warningDialogParams.showWarningDialog}
          onClose={handleWarningDialogClose}
          onSave={handleNoteDelete}
          title="The note will be deleted. Proceed?"
          submitButtonText="Delete"
        />
      ) : null}
      <Dialog
        open={showReferenceDetailsDialog}
        fullScreen={isMobile}
        scroll="paper"
        maxWidth="md">
        <DialogContent>
          <ReferenceItemCard
            onChange={handleStatusFieldChange}
            letterUrl={verifiableReference.letter}
            editableDocumentParams={documentParams}
            handleNoteOpen={() => setShowNoteDialog(true)}
            declinedReason={verifiableReference.declined_reason}
          />
        </DialogContent>
        <DialogActions className={classes.actionsWrapper}>
          <DialogSmartButtons
            disabled={false}
            loading={savingData}
            onClose={setCloseReferenceDetailsDialog}
            onSave={handleReviewSave}
          />
        </DialogActions>
      </Dialog>
    </>
  );
});
