import { makeAutoObservable } from 'mobx';

export default class ShortApplicationInfo {
  constructor(info) {
    this.id = info?.id || '';
    this.project_title = info?.project_title || '';
    this.project_field_1 = info?.project_field_1 || '';
    this.project_field_2 = info?.project_field_2 || '';
    this.project_field_1_name = info?.project_field_1_name;
    this.project_field_2_name = info?.project_field_2_name;
    this.joint_applicant_name = info?.joint_applicant_name || '';
    this.website_1 = info?.website_1 || '';
    this.website_2 = info?.website_2 || '';
    makeAutoObservable(this, {}, { autoBind: true });
  }
}
