import {
  plainRequestPrivateResolver,
  privateResolverWithPayload,
  UtilsService,
} from '@services';
import { makeAutoObservable } from 'mobx';

class UtilsStore {
  constructor() {
    this.groupsets = [];
    this.institutions = [];
    this.countries = [];
    this.reviewedStatuses = [];
    this.accountTC = null;
    this.applicationTC = null;
    this.passwordCriteria = null;
    this.metaTexts = null;
    this.foundInstitution = null;
    this.states = [];
    this.phoneTypes = null;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  getPhoneTypeName(type) {
    return this.phoneTypes.find((phoneType) => phoneType.value === type)
      ?.display_name;
  }

  setPhoneTypes(phoneTypes) {
    this.phoneTypes =
      phoneTypes.actions.POST.address.children.phone_type.choices;
  }

  getCountryName(type) {
    return this.countries.find((countryType) => countryType.value === type)
      ?.display_name;
  }

  setCountries(countries) {
    this.countries = countries;
  }

  setMetaTexts(data) {
    this.metaTexts = data;
  }

  setStatuses(data) {
    this.reviewedStatuses = data.actions.PUT.reviewed_status.choices;
  }

  setAccountTC(data) {
    this.accountTC = data;
  }

  setPasswordCriteria(data) {
    this.passwordCriteria = data;
  }

  setGroupsets(data) {
    this.groupsets = data;
  }

  getGroupsets() {
    plainRequestPrivateResolver({
      fetcher: UtilsService.getCurrentGroupsets,
      setter: this.setGroupsets,
    });
  }

  async getInstitutionStates() {
    const response = await UtilsService.getInstitutionStates();
    this.states = response.data;
  }

  async getCountries() {
    const { data } = await UtilsService.getCountries();
    this.setCountries(data.actions.POST.country.choices);
    this.setUSAndCanadaCountriesTop();
  }

  async getInstitutions() {
    const response = await UtilsService.getInstitutions();
    this.institutions = response.data;
  }

  getInstitutionName(id) {
    this.foundInstitution = this.institutions?.find(
      (institutionItem) => institutionItem.id === id,
    );
  }

  async getAccountTC() {
    const { data } = await UtilsService.getAccountTermsAndConditions();
    this.setAccountTC(data);
  }

  async getMetaPasswordCriteria() {
    const { data } = await UtilsService.fetchMetaPasswordCriteria();
    this.setPasswordCriteria(data);
  }

  async getMetaTexts() {
    const { data } = await UtilsService.getMetaTexts();
    this.setMetaTexts(data);
  }

  async getMetaText(metaTextName) {
    if (!this.metaTexts) {
      await this.getMetaTexts();
    }

    return (
      this.metaTexts.filter((item) => item.name === metaTextName)[0]?.value ||
      ''
    );
  }

  getInstitution(typeId) {
    return this.institutions?.find(
      (referenceType) => referenceType.id === typeId,
    )?.name;
  }

  getPhoneTypes(payload) {
    privateResolverWithPayload({
      fetcher: UtilsService.fetchPhoneTypes,
      payload,
      setter: this.setPhoneTypes,
    });
  }

  getReviewedStatusesOptions(applicationId) {
    privateResolverWithPayload({
      fetcher: UtilsService.getStatuses,
      payload: applicationId,
      setter: this.setStatuses,
    });
  }

  setUSAndCanadaCountriesTop() {
    const countriesToTop = this.countries.filter(
      (item) => item.value === 'US' || item.value === 'CA',
    );
    const countriesWithoutTop = this.countries.filter(
      (item) => item.value !== 'US' && item.value !== 'CA',
    );

    this.setCountries([
      countriesToTop[1],
      countriesToTop[0],
      ...countriesWithoutTop,
    ]);
  }
}

export default new UtilsStore();
