import { useEffect, useState } from 'react';
import { NonFieldErrors, Select, TextField } from '@components';
import { CircularProgress } from '@material-ui/core';
import { ErrorStore, SectionsStore } from '@stores';
import { generateErrorMessages } from '@utils';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import * as Yup from 'yup';
import { LanguagesStore } from '../services';

const LanguagesForm = observer(({ fields, submitHandler }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    ErrorStore.dropErrors();
  }, []);

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      language: fields?.name ?? '',
      readingFluency: fields?.rating_read ?? '',
      writingFluency: fields?.rating_write ?? '',
      speakingFluency: fields?.rating_speak ?? '',
    },
    validationSchema: Yup.object({
      language: Yup.string().required('Required'),
      readingFluency: Yup.string().required('Required'),
      writingFluency: Yup.string().required('Required'),
      speakingFluency: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      setLoading(true);

      try {
        if (fields) {
          await LanguagesStore.updateApplicationLanguage(values, fields.id);
        } else {
          await LanguagesStore.createApplicationLanguage(values);
        }
        await LanguagesStore.unacknowledgeApplicationLanguagesSection();
        await SectionsStore.getApplicationSectionsStatuses();
      } finally {
        setLoading(false);
        submitHandler();
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <h2 className="text-black text-2xl px-2">
        {fields ? 'Edit Language' : 'Add Language'}
      </h2>
      <div className="px-2 py-2">
        <TextField
          label={
            <label
              htmlFor="language"
              className="text-gray-700 dark:text-gray-200">
              Language <span className="text-red-600">*</span>
            </label>
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.language}
          id="language"
          errors={generateErrorMessages(
            'language',
            formik,
            ErrorStore,
            'language',
          )}
        />
      </div>
      <div className="px-2 py-2">
        <Select
          label={
            <label
              htmlFor="readingFluency"
              className="text-gray-700 dark:text-gray-200">
              Reading Fluency <span className="text-red-600">*</span>
            </label>
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.readingFluency}
          id="readingFluency"
          defaultEmptyValue
          itemName="display_name"
          itemValue="value"
          items={LanguagesStore.options.actions.POST.rating_read.choices}
          errors={generateErrorMessages(
            'readingFluency',
            formik,
            ErrorStore,
            'rating_read',
          )}
        />
      </div>
      <div className="px-2 py-2">
        <Select
          label={
            <label
              htmlFor="writingFluency"
              className="text-gray-700 dark:text-gray-200">
              Writing Fluency <span className="text-red-600">*</span>
            </label>
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.writingFluency}
          id="writingFluency"
          defaultEmptyValue
          itemName="display_name"
          itemValue="value"
          items={LanguagesStore.options.actions.POST.rating_write.choices}
          errors={generateErrorMessages(
            'writingFluency',
            formik,
            ErrorStore,
            'rating_write',
          )}
        />
      </div>
      <div className="px-2 py-2">
        <Select
          label={
            <label
              htmlFor="speakingFluency"
              className="text-gray-700 dark:text-gray-200">
              Speaking Fluency <span className="text-red-600">*</span>
            </label>
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.speakingFluency}
          id="speakingFluency"
          defaultEmptyValue
          itemName="display_name"
          itemValue="value"
          items={LanguagesStore.options.actions.POST.rating_speak.choices}
          errors={generateErrorMessages(
            'speakingFluency',
            formik,
            ErrorStore,
            'rating_speak',
          )}
        />
      </div>
      <NonFieldErrors />
      <div className="flex flex-col md:flex-row justify-center mt-2">
        <button
          onClick={submitHandler}
          disabled={loading}
          type="button"
          className="
                          w-max
                          md:mx-2
                          px-8
                          py-3
                          text-xl
                          bg-gray-400
                          text-black
                          rounded-md
                          text-center
                        ">
          Cancel
        </button>
        <button
          type="submit"
          disabled={loading}
          className="
                          w-max
                          md:mx-2
                          px-8
                          py-3
                          text-xl
                          bg-primary
                          text-white
                          rounded-md
                          text-center
                        ">
          {loading ? <CircularProgress color="white" /> : 'Save'}
        </button>
      </div>
    </form>
  );
});

export default LanguagesForm;
