import { makeAutoObservable, runInAction } from 'mobx';
import Note from '../models/Note';
import NotesService from './notes.service';

class SectionNoteDialogStore {
  constructor() {
    this.showSectionNoteDialog = false;
    this.verifiableSectionName = '';
    this.disabledNote = false;
    this.sectionTypes = null;
    this.sectionNote = null;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  clear() {
    this.verifiableSectionName = '';
    this.disabledNote = false;
    this.sectionNote = null;
  }

  setNote(newNote) {
    if (newNote) {
      this.sectionNote = new Note(newNote);
    }
  }

  setVerifiableSectionName(sectionName, disabled) {
    this.verifiableSectionName = sectionName;
    this.disabledNote = disabled;
    this.setOpenNoteDialog();
  }

  setOpenNoteDialog() {
    this.showSectionNoteDialog = true;
  }

  setCloseNoteDialog() {
    this.showSectionNoteDialog = false;
  }

  getSectionTypeItem() {
    return this.sectionTypes.find(
      (sectionType) => sectionType.display_name === this.verifiableSectionName,
    )?.value;
  }

  setSectionTypes(sectionTypes) {
    this.sectionTypes = sectionTypes;
  }

  async createSectionNote() {
    await NotesService.createNoteItem({
      user: this.sectionNote.user,
      note: this.sectionNote.note,
      section: this.sectionNote.section,
      application: this.sectionNote.application,
      type: 'r',
    });
  }

  getSectionTypes(applicationId) {
    NotesService.fetchSectionTypes(applicationId).then((data) => {
      const types = data.actions.POST.section.choices;
      this.setSectionTypes(types);
    });
  }

  getSectionNote(noteType, applicationId) {
    NotesService.fetchNoteItem(noteType, applicationId).then((result) => {
      runInAction(() => {
        this.setNote(result.data[0]);
      });
    });
  }
}

export default new SectionNoteDialogStore();
