import { useEffect, useState } from 'react';
import { NonFieldErrors, TextField } from '@components';
import { CircularProgress } from '@material-ui/core';
import { ErrorStore, SectionsStore } from '@stores';
import { generateErrorMessages } from '@utils';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import * as Yup from 'yup';
import { OrganizationsStore } from '../services';

const OrganisationsForm = observer(({ fields, submitHandler }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    ErrorStore.dropErrors();
  }, []);

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      name: fields?.name ?? '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      setLoading(true);

      try {
        if (fields) {
          await OrganizationsStore.updateApplicationOrganization(
            values,
            fields.id,
          );
        } else {
          await OrganizationsStore.createApplicationOrganization(values);
        }
        await OrganizationsStore.unacknowledgeApplicationOrganizationsSection();
        await SectionsStore.getApplicationSectionsStatuses();
      } finally {
        setLoading(false);
        submitHandler();
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <h2 className="text-black text-2xl px-2">
        {fields ? 'Edit Organization' : 'Add Organization'}
      </h2>
      <div className="px-2 py-2">
        <TextField
          label={
            <label htmlFor="name" className="text-gray-700 dark:text-gray-200">
              Organization <span className="text-red-600">*</span>
            </label>
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
          id="name"
          errors={generateErrorMessages('name', formik, ErrorStore, 'name')}
        />
      </div>
      <NonFieldErrors />
      <div className="flex flex-col md:flex-row justify-center mt-2">
        <button
          disabled={loading}
          onClick={submitHandler}
          type="button"
          className="
                          w-max
                          md:mx-2
                          px-8
                          py-3
                          text-xl
                          bg-gray-400
                          text-black
                          rounded-md
                          text-center
                        ">
          Cancel
        </button>
        <button
          type="submit"
          disabled={loading}
          className="
                          w-max
                          md:mx-2
                          px-8
                          py-3
                          text-xl
                          bg-primary
                          text-white
                          rounded-md
                          text-center
                        ">
          {loading ? <CircularProgress color="white" /> : 'Save'}
        </button>
      </div>
    </form>
  );
});

export default OrganisationsForm;
