import { WorkExamplesService } from '@services';
import { makeAutoObservable } from 'mobx';

class WorkExamplesStore {
  constructor() {
    this.linkToWorkExamples = null;
    makeAutoObservable(this);
  }

  setLinkToWorkExamples(linkToWorkExamples) {
    this.linkToWorkExamples = linkToWorkExamples;
  }

  async createWorkExamplesSession() {
    const { data } = await WorkExamplesService.createWorkExamplesSession();
    this.setLinkToWorkExamples(data.redirect);
  }
}

export default new WorkExamplesStore();
