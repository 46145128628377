import { makeAutoObservable } from 'mobx';

export class ApplicationInfo {
  constructor(info) {
    this.id = info.id;
    this.fm_id = info.fm_id;
    this.application = info.application;
    this.project_title = info.project_title;
    this.project_field_1 = info.project_field_1;
    this.project_field_2 = info.project_field_2;
    this.joint_applicant_name = info.joint_applicant_name;
    this.joint_applicant_email = info.joint_applicant_email;
    this.website_1 = info.website_1;
    this.website_2 = info.website_2;
    this.date_created = info.date_created;
    this.date_last_modified = info.date_last_modified;
    this.project_field_1_name = info.project_field_1_name;
    this.project_field_2_name = info.project_field_2_name;
    makeAutoObservable(this, {}, { autoBind: true });
  }
}
