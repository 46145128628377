import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { AuthStore, ProgressStore, UserStore } from '@stores';
import { observer } from 'mobx-react-lite';

const JurorsLink = observer(() => {
  const navigateToJurors = async () => {
    try {
      ProgressStore.setInProgress();
      await AuthStore.refresh();
      window.location.replace(
        `${process.env.REACT_APP_API_BASE_URL}/api/jury/session/?access=${AuthStore.accessToken}`,
      );
    } finally {
      ProgressStore.setNotInProgress();
    }
  };

  if (!UserStore.user.user_summary.juror) {
    return null;
  }

  return (
    <li>
      <div className="user-navbar__item" onClick={navigateToJurors}>
        <div>Review Applicants</div>
        <RadioButtonUncheckedIcon className="user-navbar__item-icon user-navbar__item-icon_unchecked" />
      </div>
    </li>
  );
});

export default JurorsLink;
