import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const AppFooter = () => (
  <footer className="p-4 bg-gray-100">
    <p className="text-lg text-black text-center">
      For help with issues not covered in the Help system&nbsp;
      <HelpOutlineIcon className="h-16 w-16 text-gray-500" /> or the
      Instructions (
      <a
        target="_blank"
        className="text-blue-500 hover:text-blue-800"
        href="https://www.gf.org/wp-content/uploads/2022/08/Guide-to-the-JSGMF-2023.pdf"
        rel="noreferrer">
        App Guide
      </a>
      ), please contact us by email at:&nbsp;
      <a
        target="_blank"
        className="text-blue-500 hover:text-blue-800"
        href="mailto:competition@gf.org"
        rel="noreferrer">
        competition@gf.org
      </a>
    </p>
  </footer>
);

export default AppFooter;
