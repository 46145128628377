export const DisplayStatus = [
  { fieldName: 'In Progress', fieldColor: '#ADADAD' },
  { fieldName: 'Submitted', fieldColor: '#7977FF' },
  { fieldName: 'Revision Requested', fieldColor: '#FFF487' },
  { fieldName: 'Revision Received', fieldColor: '#7977FF' },
  { fieldName: 'Reviewed', fieldColor: 'orange' },
  { fieldName: 'Approved', fieldColor: '#7BE44E' },
  { fieldName: 'Rejected', fieldColor: 'pink' },
  { fieldName: 'Withdrawn', fieldColor: 'pink' },
  { fieldName: 'On Hold', fieldColor: '#b2c4d2' },
  {
    fieldName: 'Revision Requested (Pending)',
    fieldColor: '#FFF487',
  },
];

export const applicationColumns = [
  {
    id: 'name',
    label: 'Applicant Name',
    sortable: true,
  },
  {
    id: 'project_title',
    label: 'Project title',
    sortable: true,
  },
  {
    id: 'project_field_1',
    label: 'Field 1',
    sortable: true,
  },
  {
    id: 'project_field_2',
    label: 'Field 2',
    sortable: true,
  },
  {
    id: 'display_status',
    label: 'Status',
    sortable: false,
  },
];

export const applicationFilterFields = [
  {
    filterName: 'applicant_first_name',
    label: 'First Name',
    type: 'string',
  },
  {
    filterName: 'applicant_last_name',
    label: 'Last Name',
    type: 'string',
  },
  {
    filterName: 'applicant_email',
    label: 'Email',
    type: 'string',
  },
  {
    filterName: 'project_title',
    label: 'Project Title',
    type: 'string',
  },
  {
    filterName: 'birth_date',
    label: 'Birth Date',
    type: 'date',
  },
  {
    filterName: 'joint_applicant_name',
    label: 'Joint Applicant Name',
    type: 'string',
  },
  {
    filterName: 'joint',
    label: 'Joint',
    type: 'boolean',
  },
  {
    filterName: 'groups_approved',
    label: 'Groups Approved',
    type: 'boolean',
  },
  {
    filterName: 'approved_for_references',
    label: 'Approved for References',
    type: 'boolean',
  },
  {
    filterName: 'approved_for_advisors',
    label: 'Approved for Advisors',
    type: 'boolean',
  },
  {
    filterName: 'winner_not_approved',
    label: 'Winner (not approved)',
    type: 'boolean',
  },
  {
    filterName: 'winner_approved',
    label: 'Winner (approved)',
    type: 'boolean',
  },
];
