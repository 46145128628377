import { ApplicationService } from '@services';
import { ApplicationStore } from '@stores';
import { makeAutoObservable } from 'mobx';

class LanguagesStore {
  constructor() {
    this.section = null;
    this.languages = [];
    this.options = null;
    makeAutoObservable(this);
  }

  async createApplicationLanguage({
    language,
    readingFluency,
    writingFluency,
    speakingFluency,
  }) {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    const body = {
      name: language,
      rating_read: readingFluency,
      rating_speak: speakingFluency,
      rating_write: writingFluency,
      application: ApplicationStore.application.id,
    };

    await ApplicationService.createApplicationSectionItem({
      body: body,
      applicationId: ApplicationStore.application.id,
      section: 'languages',
    });
    await this.getApplicationLanguages();
  }

  async updateApplicationLanguage(
    { language, readingFluency, writingFluency, speakingFluency },
    id,
  ) {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    const body = {
      name: language,
      rating_read: readingFluency,
      rating_speak: speakingFluency,
      rating_write: writingFluency,
      application: ApplicationStore.application.id,
    };

    await ApplicationService.updateApplicationSectionItem({
      body: body,
      applicationId: ApplicationStore.application.id,
      section: 'languages',
      id: id,
    });
    await this.getApplicationLanguages();
  }

  async getApplicationLanguages() {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    const result = await ApplicationService.getCurrentApplicationSectionItem({
      applicationId: ApplicationStore.application.id,
      section: 'languages',
    });
    this.languages = result.data;
  }

  async getApplicationLanguagesSection() {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    const result = await ApplicationService.getCurrentApplicationSection({
      applicationId: ApplicationStore.application.id,
      section: 'languages',
    });
    this.section = result.data[0];
  }

  async acknowledgeApplicationLanguagesSection() {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }
    if (!this.section) {
      await this.getApplicationLanguagesSection();
    }

    const body = {
      acknowledged: true,
      application: this.section.application,
    };

    const result = await ApplicationService.updateApplicationSection({
      body: body,
      applicationId: ApplicationStore.application.id,
      section: 'languages',
      id: this.section.id,
    });
    this.section = result.data;
  }

  async unacknowledgeApplicationLanguagesSection() {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }
    if (!this.section) {
      await this.getApplicationLanguagesSection();
    }

    const body = {
      acknowledged: false,
      application: this.section.application,
    };

    const result = await ApplicationService.updateApplicationSection({
      body: body,
      applicationId: ApplicationStore.application.id,
      section: 'languages',
      id: this.section.id,
    });
    this.section = result.data;
  }

  async getApplicationLanguagesOptions() {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    const result = await ApplicationService.getCurrentApplicationSectionOptions(
      {
        applicationId: ApplicationStore.application.id,
        section: 'languages',
      },
    );
    this.options = result.data;
  }

  async deleteApplicationLanguage(id) {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    await ApplicationService.deleteApplicationSectionItem({
      id: id,
      section: 'languages',
      applicationId: ApplicationStore.application.id,
    });

    await this.getApplicationLanguages();
  }
}

export default new LanguagesStore();
