import { makeAutoObservable } from 'mobx';
import ReferencesService from './ReferencesService';

class ReferencesStore {
  constructor() {
    this.references = null;
    this.options = null;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setReferences(data) {
    this.references = data;
  }

  setOptions(data) {
    this.options = data;
  }

  async getReferenceOptions({ userId }) {
    const { data } = await ReferencesService.getReferenceOptions({ userId });
    this.setOptions(data);
  }

  async getReferences({ userId }) {
    const { data } = await ReferencesService.getReferences({ userId });
    this.setReferences(data);
  }

  async declineReference({ userId, declinedReason, referenceId }) {
    const body = {
      declined_reason: declinedReason,
    };
    await ReferencesService.declineReference({ userId, body, referenceId });
    await this.getReferences({ userId });
  }

  async acceptReference({ userId, document, referenceId }) {
    const body = new FormData();

    body.append('letter', document);

    await ReferencesService.acceptReference({ userId, body, referenceId });
    await this.getReferences({ userId });
  }
}

export default new ReferencesStore();
