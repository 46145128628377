import makeStyles from '@material-ui/core/styles/makeStyles';
import { colors, fontSizes, radius, spacing } from '@styles';

export const useApplicationCardStyles = makeStyles((theme) => ({
  contentWrapper: {
    flexGrow: 1,
    display: 'flex',
    marginTop: spacing.spacing_lg,
    background: colors.white,
    border: `1px solid ${colors.gray_light}`,
    boxSizing: 'border-box',
    boxShadow: '0px 4px 4px rgba(0, 77, 103, 0.07)',
    borderRadius: radius.radius_m,
    paddingBottom: '10px',
  },
  tabsMenu: {
    borderRight: `1px solid ${theme.palette.divider}`,
    position: 'relative',
  },
  tabItem: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '12px 16px',
    lineHeight: '24px',
    cursor: 'pointer',
    position: 'relative',
    transition: '0.2s',
    '&:hover': {
      fontSize: '17px',
      color: colors.black_light,
    },
  },
  secondaryText: {
    color: 'red',
    fontSize: fontSizes.font_size_xs,
    position: 'absolute',
    top: spacing.spacing_lg,
    left: spacing.spacing_mdl,
  },
  tabItemCheckbox: {
    padding: '3px 16px',
  },
  lockIcon: {
    width: '22px',
  },
  fullSize: {
    width: '100%',
    height: '100%',
    fontSize: fontSizes.font_size_s,
  },
  columnTitleEditable: {
    position: 'absolute',
    top: '-24px',
    right: '5px',
    fontSize: fontSizes.font_size_xs,
  },
  columnTitleReview: {
    position: 'absolute',
    top: '-24px',
    left: '5px',
    fontSize: fontSizes.font_size_xs,
  },
  menuWrapper: {
    position: 'relative',
  },
}));
