import { useEffect } from 'react';
import { Loader } from '@components';
import { AdminLayoutStore, CompetitionStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { CompetitionCard } from './components/CompetitionCard';

export const AdminHomeScene = observer(() => {
  useEffect(() => {
    AdminLayoutStore.setPageTitle('ADMINISTRATION - Home');
    CompetitionStore.getCurrentCompetition();
  }, []);

  return (
    <>
      {CompetitionStore.currentCompetition ? (
        <CompetitionCard competition={CompetitionStore.currentCompetition} />
      ) : (
        <Loader />
      )}
    </>
  );
});
