import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import './dropdown.css';

const Dropdown = ({ activator, items }) => {
  const [show, setShow] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (show && !ref.current?.contains(e.target)) {
        setShow(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [show]);

  return (
    <div className="relative inline-block text-left">
      <button type="button" onClick={() => setShow(!show)}>
        {activator}
      </button>
      <div
        ref={ref}
        className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
        tabIndex="-1"
        style={{
          display: show ? 'block' : 'none',
          zIndex: '10',
        }}>
        <div className="py-1" role="none">
          {items?.map((item) => {
            if (item.type === 'link') {
              return (
                <Link
                  onClick={() => setShow(false)}
                  key={uuid()}
                  to={item.href}
                  className="text-gray-700 block px-4 py-2 text-sm w-full text-center hover:bg-gray-100">
                  {item.label}
                </Link>
              );
            }
            if (item.type === 'button') {
              return (
                <button
                  key={uuid()}
                  className="text-gray-700 block px-4 py-2 text-sm w-full text-center hover:bg-gray-100"
                  onClick={() => {
                    item.onClick();
                    setShow(false);
                  }}>
                  {item.label}
                </button>
              );
            }

            return null;
          })}
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
