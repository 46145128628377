import { useCallback } from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import { AdminApplicationLayoutStore } from '@stores';
import { observer } from 'mobx-react-lite';
import AddressesSectionStore from '../services/AddressesSectionStore';
import { AddressesSectionStyles } from '../services/styles';

const GreenRadio = withStyles({
  root: {
    '&$checked': {
      color: 'green',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export const AddressSectionContent = observer(({ addressesInfo }) => {
  const classes = AddressesSectionStyles();
  const { isMobile } = AdminApplicationLayoutStore;

  const showAddressDetails = (selectedAddress) => {
    AddressesSectionStore.setSelectedAddress(selectedAddress);
    AddressesSectionStore.setOpenAddressItemDialog();
  };

  const renderPreferredAddressFlag = useCallback(
    (preferredFlag, addressType) => (
      <Typography
        className={addressType === 'a' ? classes.hide : null}
        color="textSecondary"
        component="div">
        <GreenRadio
          className={classes.radioColor}
          size="small"
          checked={preferredFlag}
        />
        <Typography variant="caption">Preferred Address</Typography>
      </Typography>
    ),
    [classes],
  );

  const renderAddressCard = useCallback(
    (address) => (
      <Card
        key={address.id}
        variant="outlined"
        className={isMobile ? classes.cardRootMobile : classes.cardRoot}>
        <CardContent className={classes.cardContent}>
          <Typography variant="h6" gutterBottom>
            {AddressesSectionStore.getAddressName(address.address.address_type)}
            {address.address.address_type !== 'of' ? (
              <span className="text-red-600 ml-1">*</span>
            ) : null}
          </Typography>
          {renderPreferredAddressFlag(
            address.address.preferred,
            address.address.address_type,
          )}
          <Typography
            className={classes.addressCard}
            variant="body2"
            component="p">
            {address.address.address_line_1}
            <br />
            {address.address.city}
            <br />
            {address.address.state}
            <br />
            {address.address.zip}
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
          <Button
            className={classes.cardAction}
            size="small"
            onClick={() => showAddressDetails(address)}>
            View Address
          </Button>
        </CardActions>
      </Card>
    ),
    [classes, isMobile, renderPreferredAddressFlag],
  );

  return (
    <Grid
      className={classes.cardsWrapper}
      container
      justify={addressesInfo.length < 3 ? 'flex-start' : 'space-between'}
      alignItems="flex-start"
      spacing={2}>
      {addressesInfo.map(renderAddressCard)}
    </Grid>
  );
});
