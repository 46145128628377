import { Redirect } from 'react-router-dom';
import { AuthStore, UserStore } from '@stores';
import { observer } from 'mobx-react-lite';

const Index = observer(() => {
  if (!AuthStore.isAuthenticated) {
    return <Redirect to="/login" />;
  }

  if (UserStore.user?.is_staff) {
    return <Redirect to="/administration" />;
  }

  if (UserStore.shouldUserNavigateToStart()) {
    return <Redirect to="/user/profile" />;
  }

  return <Redirect to="/home" />;
});

export default Index;
