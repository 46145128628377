import { InputSelectField, InputTextField } from '@components';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { colors, miscVariables, mixins, useIsMobile } from '@styles';
import { PencilBoxOutline } from 'mdi-material-ui';
import { observer } from 'mobx-react-lite';
import ReferencesStore from '../services/ReferencesStore';

const useStyles = makeStyles({
  cardRoot: {
    width: miscVariables.card_root_width,
    margin: '0 auto',
  },
  cardRootMobile: {
    margin: '8px auto',
    width: miscVariables.card_root_width,
    height: miscVariables.card_root_height,
    padding: '12px 0 0',
  },
  cardContent: {
    ...mixins.flexColumn,
    alignItems: 'flex-start',
  },
  letterLink: {
    color: colors.blue,
    marginTop: '20px !important',
    cursor: 'pointer',
  },
  cardFooter: {
    ...mixins.flexColumn,
    alignItems: 'flex-end',
  },
});

export const ReferenceItemCard = observer(
  ({
    onChange,
    letterUrl,
    editableDocumentParams,
    handleNoteOpen,
    declinedReason,
  }) => {
    const classes = useStyles();
    const isMobile = useIsMobile();

    return (
      <Card
        variant="outlined"
        className={isMobile ? classes.cardRootMobile : classes.cardRoot}>
        <CardContent className={classes.cardContent}>
          <Typography variant="h6" gutterBottom>
            Reference Letter
          </Typography>
          <InputSelectField
            label="Reviewer Status"
            name="reviewed_status"
            disabled={false}
            options={ReferencesStore.reviewedStatusOptions}
            value={editableDocumentParams?.reviewed_status}
            onClick={onChange}
          />
          <FormControlLabel
            labelPlacement="end"
            control={
              <Checkbox
                color="default"
                name="approved"
                checked={editableDocumentParams?.approved}
                onClick={(event) =>
                  onChange(event.target.name, event.target.checked)
                }
              />
            }
            label="Approved"
          />
          <div>
            <FormControlLabel
              labelPlacement="end"
              control={
                <Checkbox
                  color="default"
                  name="editable"
                  checked={editableDocumentParams?.editable}
                  onClick={(event) =>
                    onChange(event.target.name, event.target.checked)
                  }
                />
              }
              label="Editable"
            />
            {editableDocumentParams?.editable ? (
              <IconButton onClick={handleNoteOpen} size="small" color="default">
                <PencilBoxOutline />
              </IconButton>
            ) : null}
          </div>
        </CardContent>
        <CardActions disableSpacing className={classes.cardFooter}>
          {Boolean(declinedReason) ? (
            <InputTextField
              value={ReferencesStore.getDeclineReason(declinedReason)}
              label="Declined Reason"
            />
          ) : (
            <div className={classes.cardFooter}>
              <Tooltip
                title="There is no letter"
                arrow
                placement="top"
                disableHoverListener={letterUrl}>
                <Link
                  target="_blank"
                  disabled={!letterUrl}
                  href={letterUrl}
                  className={classes.letterLink}>
                  View document
                </Link>
              </Tooltip>
            </div>
          )}
        </CardActions>
      </Card>
    );
  },
);
