import { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import {
  AdminApplicationLayoutStore,
  ApplicationStore,
  AuthStore,
} from '@stores';
import { colors, miscVariables } from '@styles';
import { observer } from 'mobx-react-lite';
import SectionNoteDialogStore from '../../services/SectionNoteDialogStore';

const useStyles = makeStyles({
  dialogAction: {
    background: colors.gray,
    textTransform: 'none',
    color: colors.black,
    margin: '0 8px',
  },
  dialogRoot: {
    minWidth: miscVariables.dialog_root_min_width,
  },
  actionsWrapper: {
    padding: '16px 0',
  },
});

export const SectionNoteDialog = observer(() => {
  const classes = useStyles();
  const { isMobile } = AdminApplicationLayoutStore;
  const {
    getSectionTypeItem,
    setNote,
    sectionNote,
    disabledNote,
    showSectionNoteDialog,
    setCloseNoteDialog,
  } = SectionNoteDialogStore;
  const [inputValue, setInputValue] = useState(sectionNote?.note);

  const handleInputFieldChange = (event) => {
    const { value } = event.target;
    setInputValue(value);
  };

  const handleActionsClick = (valueNote) => {
    setCloseNoteDialog();

    if (sectionNote?.id) {
      return sectionNote.updateNote(valueNote);
    }

    return setNote({
      application: ApplicationStore.verifiableApplication.id,
      user: AuthStore.id,
      section: getSectionTypeItem(),
      note: valueNote || '',
    });
  };

  const renderDialogActions = () => (
    <>
      {disabledNote ? (
        <Grid container justify="center" alignItems="center">
          <Button
            className={classes.dialogAction}
            onClick={setCloseNoteDialog}
            color="primary">
            Done
          </Button>
        </Grid>
      ) : (
        <Grid container justify="center" alignItems="center">
          <Button
            className={classes.dialogAction}
            onClick={() => handleActionsClick(sectionNote?.note)}
            color="primary">
            Cancel
          </Button>
          <Button
            className={classes.dialogAction}
            onClick={() => handleActionsClick(inputValue)}
            color="primary">
            Save
          </Button>
        </Grid>
      )}
    </>
  );

  return (
    <Dialog
      className={classes.dialogRoot}
      open={showSectionNoteDialog}
      fullScreen={isMobile}
      scroll="paper"
      maxWidth="md">
      <DialogTitle>Enter a note regarding this action</DialogTitle>
      <DialogContent className={classes.dialogRoot}>
        <TextField
          multiline
          fullWidth
          rows={10}
          value={inputValue}
          variant="outlined"
          onChange={handleInputFieldChange}
          InputProps={{
            readOnly: disabledNote,
          }}
        />
      </DialogContent>
      <DialogActions className={classes.actionsWrapper}>
        {renderDialogActions()}
      </DialogActions>
    </Dialog>
  );
});
