import { useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { UserNavbar } from '@components';
import { ApplicationStore, CompetitionStore, UserStore } from '@stores';
import { observer } from 'mobx-react-lite';
import {
  ApplicationCreationClosedCard,
  ApplicationSubmitClosedCard,
  ApplicationSubmittedCard,
  ApplicationNoCard,
  ApplicationRevisionRequestCard,
} from './components';

const ApplicationScene = observer(() => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  if (UserStore.shouldUserNavigateToStart()) {
    return <Redirect to="/user/profile" />;
  }

  useEffect(() => {
    async function fetchData() {
      await CompetitionStore.getCurrentCompetition();
      await ApplicationStore.getCurrentUserApplication();
      setLoading(false);
    }

    fetchData();
  }, [history]);

  const renderContent = () => {
    if (ApplicationStore.application?.is_submitted) {
      return <ApplicationSubmittedCard />;
    }

    if (ApplicationStore.application?.revision_requested) {
      return <ApplicationRevisionRequestCard />;
    }

    if (
      !CompetitionStore.currentCompetition.allow_application_edit &&
      !ApplicationStore.application?.is_submitted &&
      ApplicationStore.application?.is_editable &&
      !ApplicationStore.application?.revision_requested
    ) {
      return <ApplicationSubmitClosedCard />;
    }

    if (
      !CompetitionStore.currentCompetition.allow_applicaton_create &&
      !ApplicationStore.application
    ) {
      return <ApplicationCreationClosedCard />;
    }

    if (ApplicationStore.application) {
      return <Redirect to="/application/info" />;
    }

    return <ApplicationNoCard />;
  };

  return (
    <>
      <div className="mt-10">
        <h1 className="text-4xl text-black font-calibri font-normal mt-2">
          Home
        </h1>
      </div>
      <div className="border rounded-md border-gray-300 md:mt-10 shadow-lg">
        <div className="flex flex-col md:flex-row">
          <UserNavbar />
          <div className="p-8 w-full md:w-3/4">
            {loading ? null : renderContent()}
          </div>
        </div>
      </div>
    </>
  );
});

export default ApplicationScene;
