import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { fontSizes, miscVariables } from '@styles';
import { observer } from 'mobx-react-lite';
import LanguagesSectionStore from '../services/LanguagesSectionStore';

const useStyles = makeStyles({
  table: {
    minWidth: miscVariables.table_min_width,
  },
  tableHead: {
    fontWeight: 'bold',
  },
});

const StyledTableCell = withStyles(() => ({
  body: {
    fontSize: fontSizes.font_size_s,
    border: 'none',
  },
}))(TableCell);

export const LanguagesTable = observer(({ languages }) => {
  const classes = useStyles();

  return (
    <TableContainer>
      <Table className={classes.table} aria-label="caption table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHead}>
              Language <span className="text-red-600">*</span>
            </TableCell>
            <TableCell className={classes.tableHead}>
              Reading Fluency <span className="text-red-600">*</span>
            </TableCell>
            <TableCell className={classes.tableHead}>
              Writing Fluency <span className="text-red-600">*</span>
            </TableCell>
            <TableCell className={classes.tableHead}>
              Speaking Fluency <span className="text-red-600">*</span>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {languages.map((languageItem) => (
            <TableRow key={languageItem.id}>
              <StyledTableCell component="th" scope="row">
                {languageItem.name}
              </StyledTableCell>
              <StyledTableCell>
                {LanguagesSectionStore.getDisplayNameRating(
                  languageItem.rating_read,
                )}
              </StyledTableCell>
              <StyledTableCell>
                {LanguagesSectionStore.getDisplayNameRating(
                  languageItem.rating_write,
                )}
              </StyledTableCell>
              <StyledTableCell>
                {LanguagesSectionStore.getDisplayNameRating(
                  languageItem.rating_speak,
                )}
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
});
