import makeStyles from '@material-ui/core/styles/makeStyles';
import { colors, miscVariables, mixins } from '@styles';

export const useDocumentsSectionStyles = makeStyles({
  toolbarDesktop: {
    ...mixins.flexToolBar,
    minWidth: miscVariables.toolbar_desktop_min_width,
  },
  toolbar: {
    ...mixins.flexToolBar,
  },
  dialogContent: {
    margin: '8px 0',
  },
  cardsWrapper: {
    padding: '15px 0',
  },
  dialogWrapper: {
    padding: '16px 0',
  },
  dialogAction: {
    background: colors.gray,
    textTransform: 'none',
    color: colors.black,
    margin: '0 8px',
  },
  loadedAction: {
    position: 'relative',
  },
  buttonProgress: {
    color: 'green',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginLeft: -12,
    marginTop: -12,
  },
  documentTitleMark: {
    color: 'red',
    marginLeft: '5px',
  },
  cardRoot: {
    width: miscVariables.card_root_width,
    margin: '0 auto',
  },
  cardRootMobile: {
    margin: '8px auto',
    width: miscVariables.card_root_width,
    height: miscVariables.card_root_height,
    padding: '12px 0 0',
  },
  cardContent: {
    ...mixins.flexColumn,
    alignItems: 'flex-start',
  },
  preferredText: {
    fontSize: '13px',
  },
  nameDocumentCard: {
    color: colors.blue,
    marginTop: '20px !important',
  },
  cardAction: {
    ...mixins.flexColumn,
    alignItems: 'flex-end',
  },
});
