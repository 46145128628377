import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthStore } from '@stores';

const ActivationRequestScene = () => {
  const { uidb64, token } = useParams();
  const history = useHistory();

  useEffect(() => {
    AuthStore.activate({ uidb64, token })
      .then(() => {
        history.push('/activation/success');
      })
      .catch((err) => {
        toast('Error on account activation', {
          type: 'error',
        });
        history.push('/login');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default ActivationRequestScene;
