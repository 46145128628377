import { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteIcon from '@material-ui/icons/Delete';
import { ProgressStore, SectionsStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { v4 } from 'uuid';
import {
  SectionContentList,
  SectionContentListBody,
  SectionContentListHeader,
  SectionContentListHeaderActions,
  SectionContentListHeaderTitle,
  SectionContentListItem,
  SectionContentListItemActions,
  SectionContentListItemTitle,
} from '../../../components';
import { ReferencesStore } from '../services';
import ReferencesForm from './ReferencesForm';

const ReferencesList = observer(() => {
  const [showDelete, setShowDelete] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [fields, setFields] = useState(null);

  useEffect(() => {
    async function fetchData() {
      await ReferencesStore.getApplicationReferences();
      await ReferencesStore.getApplicationReferencesOptions();
      await setLoading(false);
    }

    void fetchData();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFields(null);
  };

  const updateReference = (reference) => {
    setFields(reference);
    handleClickOpen();
  };

  const handleOpenDelete = (id) => {
    setDeleteItem(id);
    setShowDelete(true);
  };

  const handleCloseDelete = () => {
    setShowDelete(false);
  };

  const deleteReference = async () => {
    handleCloseDelete();
    ProgressStore.setInProgress();

    await ReferencesStore.deleteApplicationReference(deleteItem)
      .then(() => ReferencesStore.unacknowledgeApplicationReferencesSection())
      .then(() => SectionsStore.getApplicationSectionsStatuses())
      .finally(() => ProgressStore.setNotInProgress());
  };

  const renderDeleteDialog = () => (
    <Dialog open={showDelete}>
      <DialogTitle>Attention</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure want do delete?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={deleteReference}
          variant="contained"
          color="secondary"
          startIcon={<DeleteIcon />}>
          Delete
        </Button>
        <Button onClick={handleCloseDelete}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );

  const renderInstitutionName = (referenceAddressItem) => (
    <>
      {referenceAddressItem.institution?.name
        ? referenceAddressItem.institution.name
        : referenceAddressItem.institution_other}
    </>
  );

  const renderList = () => (
    <SectionContentList>
      <SectionContentListHeader>
        <SectionContentListHeaderTitle>
          References
        </SectionContentListHeaderTitle>
        <SectionContentListHeaderActions>
          {ReferencesStore.references.results.length < 4 ? (
            <button
              type="button"
              className="
            px-2
            text-md
            bg-primary
            text-white
            rounded-md
            text-center
          "
              onClick={handleClickOpen}>
              Enter another Reference
            </button>
          ) : (
            <p className="text-lg text-black py-2 font-calibri font-bold">
              Thank you for entering your maximum of four References
            </p>
          )}
        </SectionContentListHeaderActions>
      </SectionContentListHeader>
      <SectionContentListBody>
        {ReferencesStore.references.results.map((reference, index) => {
          let highlight = false;

          if (index % 2 === 0 || index === 0) {
            highlight = true;
          }

          return (
            <SectionContentListItem highlight={highlight} key={v4()}>
              <SectionContentListItemTitle>
                {reference.address.name_first} {reference.address.name_last}{' '}
                <span className="ml-10">
                  {renderInstitutionName(reference.address)}
                </span>
              </SectionContentListItemTitle>
              <SectionContentListItemActions>
                <button
                  className="px-2 py-1"
                  onClick={() => {
                    updateReference(reference);
                  }}>
                  Edit
                </button>
                <button
                  className="text-red-600 px-2 py-1"
                  onClick={() => {
                    handleOpenDelete(reference.id);
                  }}>
                  Delete
                </button>
              </SectionContentListItemActions>
            </SectionContentListItem>
          );
        })}
      </SectionContentListBody>
    </SectionContentList>
  );

  const renderEmptyList = () => (
    <div className="my-16">
      <div
        className="
          flex flex-col
          md:flex-nowrap
          justify-center
          p-8
        ">
        <h2 className="text-center text-2xl text-blue-900">
          You have not entered any references
        </h2>
        <button
          type="button"
          className="
            w-max
            px-8
            py-1
            text-md
            bg-primary
            text-white
            rounded-md
            text-center
            mt-2
            mx-auto
          "
          onClick={handleClickOpen}>
          Enter a Reference
        </button>
      </div>
    </div>
  );

  const renderContent = () => {
    if (ReferencesStore.references.results.length) {
      return renderList();
    } else {
      return renderEmptyList();
    }
  };

  return (
    <>
      {loading ? null : renderContent()}
      <Dialog
        disableBackdropClick
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <ReferencesForm fields={fields} submitHandler={handleClose} />
        </DialogContent>
      </Dialog>
      {renderDeleteDialog()}
    </>
  );
});

export default ReferencesList;
