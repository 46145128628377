import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { fontSizes, miscVariables, spacing } from '@styles';
import { observer } from 'mobx-react-lite';

const useStyles = makeStyles({
  table: {
    minWidth: miscVariables.table_min_width,
  },
  tableHead: {
    fontWeight: 'bold',
    paddingBottom: spacing.spacing_md,
  },
  buttonView: {
    fontSize: fontSizes.font_size_s,
    textTransform: 'none',
  },
});

const StyledTableCell = withStyles(() => ({
  head: {
    border: 'none',
  },
  body: {
    fontSize: fontSizes.font_size_s,
    border: 'none',
  },
}))(TableCell);

export const ReferencesTable = observer(({ onClick, references }) => {
  const classes = useStyles();

  return (
    <TableContainer>
      <Table className={classes.table} aria-label="caption table">
        <TableHead>
          <TableRow>
            <StyledTableCell
              padding="none"
              size="small"
              className={classes.tableHead}>
              Name
            </StyledTableCell>
            <StyledTableCell
              padding="none"
              size="small"
              className={classes.tableHead}>
              Institution
            </StyledTableCell>
            <StyledTableCell
              padding="none"
              size="small"
              className={classes.tableHead}>
              Title
            </StyledTableCell>
            <StyledTableCell
              padding="none"
              size="small"
              className={classes.tableHead}>
              {' '}
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {references.map((referenceItem) => (
            <TableRow key={referenceItem.id}>
              <StyledTableCell
                padding="none"
                size="small"
                component="th"
                scope="row">
                {referenceItem.address.name_first}
                &nbsp;
                {referenceItem.address.name_last}
              </StyledTableCell>
              <StyledTableCell padding="none" size="small">
                {referenceItem.address.institution?.name ||
                  referenceItem.address.institution_other}
              </StyledTableCell>
              <StyledTableCell padding="none" size="small">
                {referenceItem.address.title}
              </StyledTableCell>
              <StyledTableCell align="right" padding="none" size="small">
                <Button
                  size="small"
                  variant="text"
                  color="primary"
                  onClick={() => onClick(referenceItem.id)}
                  className={classes.buttonView}>
                  view
                </Button>
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
});
