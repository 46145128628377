import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { UserCircleIcon } from '@heroicons/react/solid';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { AuthStore, UserStore } from '@stores';
import { colors, fontSizes, mixins } from '@styles';
import { spacing } from '@styles';
import { observer } from 'mobx-react-lite';

const useStyles = makeStyles({
  list: mixins.resettingMargins,
  menuList: mixins.resettingMargins,
  iconBtn: mixins.resettingMargins,
  menuItem: {
    fontSize: fontSizes.font_size_s,
    borderBottom: `1px solid ${colors.white_01}`,
  },
  popper: {
    zIndex: 1000,
  },
  icon: {
    fontSize: fontSizes.font_size_m,
    marginRight: spacing.spacing_md,
    color: 'rgba(0, 0, 0, 0.54)',
  },
});

export const UserMenu = observer(() => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const classes = useStyles();
  const history = useHistory();
  const toggleMenu = () => setOpen((prevOpen) => !prevOpen);

  const handleClose = () => setOpen(false);

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const logoutHandler = () => {
    AuthStore.logout();
    UserStore.clearCurrentUser();
    history.push('/logout');
  };

  const goToHome = () => {
    history.push('/administration');
    setOpen(false);
  };

  const goToProfile = () => {
    setOpen(false);
  };

  return (
    <>
      <Popper
        className={classes.popper}
        anchorEl={anchorRef.current}
        open={open}
        placement="bottom-end"
        transition
        disablePortal>
        {({ TransitionProps }) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Fade {...TransitionProps} timeout={350}>
            <Paper className={classes.list}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  className={classes.menuList}
                  onKeyDown={handleListKeyDown}>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={goToHome}
                    button>
                    Home
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={goToProfile}
                    button>
                    Account
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={logoutHandler}
                    button>
                    Log Out
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
      <IconButton
        ref={anchorRef}
        className={classes.iconBtn}
        aria-label="user-menu"
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={toggleMenu}>
        <UserCircleIcon
          id="menu-button"
          aria-expanded="false"
          aria-haspopup="true"
          className="h-7 w-7 text-white cursor-pointer"
        />
      </IconButton>
    </>
  );
});
