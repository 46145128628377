import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { fontSizes, miscVariables } from '@styles';
import { observer } from 'mobx-react-lite';

const useStyles = makeStyles({
  table: {
    minWidth: miscVariables.table_min_width,
  },
  tableHead: {
    fontWeight: 'bold',
  },
});

const StyledTableCell = withStyles(() => ({
  body: {
    fontSize: fontSizes.font_size_s,
    border: 'none',
  },
}))(TableCell);

export const PositionsTable = observer(({ positions }) => {
  const classes = useStyles();

  return (
    <TableContainer>
      <Table className={classes.table} aria-label="caption table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHead}>
              Institution / Company Name *
            </TableCell>
            <TableCell className={classes.tableHead}>
              Position / Title *
            </TableCell>
            <TableCell className={classes.tableHead}>Department</TableCell>
            <TableCell className={classes.tableHead}>
              Dates (start/end) *
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {positions.map((positionItem) => (
            <TableRow key={positionItem.id}>
              <StyledTableCell component="th" scope="row">
                {positionItem.institution?.name ||
                  positionItem.institution_other}
              </StyledTableCell>
              <StyledTableCell>{positionItem.position_title}</StyledTableCell>
              <StyledTableCell>{positionItem.department}</StyledTableCell>
              <StyledTableCell>
                {positionItem.start_and_end_dates}
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
});
