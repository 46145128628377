import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { SectionsStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { v4 } from 'uuid';

const ApplicationNavbar = observer(() => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const titles = {
    info: 'Application Information',
    address: 'Addresses',
    reference: 'References',
    position: 'Positions',
    language: 'Languages',
    organization: 'Organizations',
    education: 'Educational Summary',
    document: 'Supporting Documents',
  };

  const classes =
    'py-4 px-8 border-b font-calibri text-xl flex justify-between font-normal cursor-pointer font-bold';

  useEffect(() => {
    async function fetchData() {
      await SectionsStore.getApplicationSectionsStatuses();
      setLoading(false);
    }

    fetchData();
  }, []);

  const renderNavbar = () =>
    SectionsStore.sections ? (
      <ol className="flex flex-col w-full md:w-1/4 border-r border-gray-300">
        {Object.keys(SectionsStore.sections).map((key) => {
          const link = `/application/${key}`;
          let linkClass = '';
          if (location.pathname === link) {
            linkClass = 'bg-tab-active';
          }

          if (!titles[key]) {
            return null;
          }

          return (
            <li className={linkClass} key={v4()}>
              <Link className={classes} to={link}>
                <div>{titles[key]}</div>
                {SectionsStore.sections[key] ? (
                  SectionsStore.sections[key].acknowledged ? (
                    <CheckCircleOutlineIcon className="h-7 w-7 my-auto text-green-500" />
                  ) : (
                    <RadioButtonUncheckedIcon className="h-7 w-7 my-auto text-gray-500" />
                  )
                ) : null}
              </Link>
            </li>
          );
        })}
        <li
          className={
            location.pathname === '/application/submit' ? 'bg-tab-active' : null
          }>
          <Link className={classes} to="/application/submit">
            <div>Review and Submit</div>
            <RadioButtonUncheckedIcon className="h-7 w-7 my-auto text-gray-500" />
          </Link>
        </li>
      </ol>
    ) : null;

  return loading ? (
    <div className="p-2">
      <CircularProgress />
    </div>
  ) : (
    renderNavbar()
  );
});

export default ApplicationNavbar;
