import { makeAutoObservable } from 'mobx';

export class Organization {
  constructor(organization) {
    this.id = organization.id;
    this.fm_id = organization.fm_id;
    this.application = organization.application;
    this.name = organization.name;
    this.date_created = organization.date_created;
    this.date_last_modified = organization.date_last_modified;
    makeAutoObservable(this, {}, { autoBind: true });
  }
}
