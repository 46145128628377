import { useEffect, useState } from 'react';
import { Select } from '@components';
import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { AuthStore } from '@stores';
import ReferencesStore from '../services/ReferencesStore';
import ReferenceAcceptDialog from './ReferenceAcceptDialog';
import ReferenceDeclineDialog from './ReferenceDeclineDialog';

const ReferenceViewDialog = ({ reference }) => {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showDecline, setShowDecline] = useState(false);
  const [showAccept, setShowAccept] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      await ReferencesStore.getReferenceOptions({ userId: AuthStore.id });
      setLoading(false);
    }

    fetchData();
  }, []);

  const renderDeclinedContent = () => (
    <Select
      id="declinedReason"
      label="Declined"
      disabled
      value={reference.declined_reason}
      items={ReferencesStore.options.actions.POST.declined_reason.choices}
      itemName="display_name"
      itemValue="value"
    />
  );

  const renderAcceptedContent = () => (
    <>
      <p className="text-md text-blue-400 my-6">
        Reference Letter submitted, thank you
      </p>
      <Button
        href={reference.letter}
        target="_blank"
        color="primary"
        variant="contained">
        View Reference Letter
      </Button>
    </>
  );

  const renderContent = () => {
    if (loading) {
      return <CircularProgress />;
    }

    return (
      <div className="m-20">
        <div className="text-center">
          {reference.declined_reason ? renderDeclinedContent() : null}
          {reference.letter ? renderAcceptedContent() : null}
          {!reference.declined_reason && !reference.letter ? (
            <a
              target="_blank"
              href={reference.applicant_plan}
              className="text-md text-blue-400 my-6"
              rel="noreferrer">
              View Application Plan
            </a>
          ) : null}
        </div>
        {!reference.declined_reason && !reference.letter ? (
          <div className="flex flex-col p-4 w-full">
            <Button
              style={{
                marginBottom: '10px',
              }}
              variant="contained"
              color="primary"
              onClick={() => setShowAccept(true)}>
              Upload Reference Letter
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setShowDecline(true)}>
              Decline
            </Button>
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <>
      <Button color="primary" variant="contained" onClick={() => setShow(true)}>
        View Reference
      </Button>
      <Dialog
        open={show}
        onClose={() => setShow(false)}
        disableBackdropClick
        maxWidth="lg">
        <DialogTitle className="text-center">
          {reference.applicant_name}
        </DialogTitle>
        <DialogContent>{renderContent()}</DialogContent>
        <DialogActions>
          <Button onClick={() => setShow(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      <ReferenceAcceptDialog
        reference={reference}
        open={showAccept}
        onClose={() => setShowAccept(false)}
      />
      <ReferenceDeclineDialog
        reference={reference}
        open={showDecline}
        onClose={() => setShowDecline(false)}
      />
    </>
  );
};

export default ReferenceViewDialog;
