import { useEffect, useState } from 'react';
import { NonFieldErrors, TextField } from '@components';
import { AuthStore, ErrorStore } from '@stores';
import { generateErrorMessages } from '@utils';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import * as Yup from 'yup';

const LoginForm = observer(() => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    ErrorStore.dropErrors();
  }, []);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Required'),
      password: Yup.string().required('Required'),
    }),
    onSubmit: async (credentials) => {
      ErrorStore.dropErrors();
      setLoading(true);
      try {
        await AuthStore.login(credentials);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="px-4 md:px-16 py-2">
        <TextField
          disabled={loading}
          label="Email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          id="email"
          errors={generateErrorMessages('email', formik, ErrorStore, 'email')}
        />
      </div>
      <div className="px-4 md:px-16 py-2">
        <TextField
          disabled={loading}
          label="Password"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          id="password"
          type="password"
          errors={generateErrorMessages(
            'password',
            formik,
            ErrorStore,
            'password',
          )}
        />
      </div>
      <NonFieldErrors />
      <div className="flex flex-col justify-center my-4 mx-4 md:mx-0">
        <button
          disabled={loading}
          type="submit"
          className="px-8 py-3 text-2xl bg-primary text-white rounded-md w-full md:w-max md:mx-auto">
          Sign In
        </button>
      </div>
    </form>
  );
});

export default LoginForm;
