import { makeAutoObservable } from 'mobx';

export default class DocumentParams {
  constructor(document) {
    this.id = document.id;
    this.fm_id = document.fm_id;
    this.file = document.file;
    this.name = document.name;
    this.date_created = document.date_created;
    this.date_last_modified = document.date_last_modified;
    this.document_type = document.document_type;
    this.reviewed_status = document.reviewed_status;
    this.approved = document.approved;
    this.editable = document.editable;
    this.note = document.note;
    this.field_values = document.field_values;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  update(newDocumentParams) {
    Object.keys(newDocumentParams).map(
      (params) => (this[params] = newDocumentParams[params]),
    );
  }
}
