import { useEffect } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { AuthStore, ProgressStore, UserStore } from '@stores';
import { observer } from 'mobx-react-lite';

const AuthRoute = observer(({ children, exact, path, isAdmin }) => {
  const history = useHistory();
  useEffect(() => {
    if (!AuthStore.isAuthenticated) {
      AuthStore.logout();
      history.push('/login');
    }

    void getCurrentUserAndUpdateProgress();

    async function getCurrentUserAndUpdateProgress() {
      if (!UserStore.user) {
        ProgressStore.setInProgress();

        await UserStore.getCurrentUser().finally(() =>
          ProgressStore.setNotInProgress(),
        );
      }
    }
  }, [history]);

  return (
    <>
      {UserStore.user ? (
        <Route
          exact={exact}
          path={path}
          render={({ location }) => {
            if (AuthStore.isAuthenticated) {
              if (!isAdmin && !UserStore.user.is_staff) {
                return children;
              }
              if (!isAdmin && UserStore.user.is_staff) {
                return <Redirect to="/administration" />;
              }
              if (isAdmin && UserStore.user.is_staff) {
                return children;
              }
              return <Redirect to="/404" />;
            }
            return (
              <Redirect
                to={{
                  pathname: '/login',
                  state: { from: location },
                }}
              />
            );
          }}
        />
      ) : null}
    </>
  );
});

export default AuthRoute;
