import { Link } from 'react-router-dom';

const PasswordResetSuccessScene = () => (
  <div className="text-center text-black font-calibri font-normal mt-64">
    <h1 className="text-5xl">Your password has been reset.</h1>
    <p className="mt-4 text-3xl">Please continue to login.</p>
    <Link
      className="px-8 py-3 text-2xl bg-primary text-white rounded-md mt-4"
      to="/login">
      Sign In
    </Link>
  </div>
);

export default PasswordResetSuccessScene;
