import { ApplicationService } from '@services';
import { makeAutoObservable } from 'mobx';
import ApplicationStore from '../ApplicationStore';

class ApplicationUserSectionStore {
  constructor() {
    this.section = null;
    this.user = null;
    this.options = null;
    makeAutoObservable(this);
  }

  async getApplicationUserSection() {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    const result = await ApplicationService.getCurrentApplicationSection({
      applicationId: ApplicationStore.application.id,
      section: 'user',
    });
    this.section = result.data[0];
  }

  async acknowledgeApplicationUserSection() {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }
    if (!this.section) {
      await this.getApplicationUserSection();
    }

    const body = {
      acknowledged: true,
      application: this.section.application,
    };

    const result = await ApplicationService.updateApplicationSection({
      body: body,
      applicationId: ApplicationStore.application.id,
      section: 'user',
      id: this.section.id,
    });
    this.section = result.data;
  }
}

export default new ApplicationUserSectionStore();
