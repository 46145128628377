import makeStyles from '@material-ui/core/styles/makeStyles';
import { colors, miscVariables, mixins, spacing } from '@styles';

export const AdminApplicationItemStyles = makeStyles({
  applicationPageWrapper: {
    padding: '16px 48px 0 48px',
    width: '100%',
  },
  textPosition: {
    textAlign: 'right',
  },
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  hide: {
    display: 'none',
  },
  appBar: {
    boxShadow: 'none',
    zIndex: 1,
    height: miscVariables.app_bar_height,
    background: colors.red,
  },
  appBarSuccess: {
    boxShadow: 'none',
    zIndex: 1,
    height: miscVariables.app_bar_height,
    background: colors.green_01,
  },
  toolbar: {
    height: '100%',
    ...mixins.flexToolBar,
  },
  toolbarMobile: {
    ...mixins.flexColumn,
    alignItems: 'center',
  },
  selectsControl: {
    display: 'flex',
    alignItems: 'center',
  },
  contentWrapper: {
    position: 'relative',
    marginTop: '65px',
  },
  revisionRequestButton: {
    textTransform: 'none',
    border: 'none',
    background: colors.gray_02,
    margin: '0',
    padding: '4px 10px',
  },
  displayStatusApproved: {
    color: colors.green_01,
  },
  displayStatus: {
    color: colors.red,
  },
  userName: {
    marginRight: spacing.spacing_mdl,
  },
  backButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
  },
});
