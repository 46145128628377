import { observer } from 'mobx-react-lite';
import EducationSectionStore from '../services/EducationSectionStore';
import { EducationTable } from './EducationTable';

export const EducationSectionContent = observer(({ education }) => {
  const handlerEducationItemClick = (educationId) => {
    EducationSectionStore.setSelectedEducation(educationId);
  };

  return (
    <EducationTable onClick={handlerEducationItemClick} education={education} />
  );
});
