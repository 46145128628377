import { privateResolverWithPayload } from '@services';
import { makeAutoObservable } from 'mobx';
import { Organization } from '../models/Organization';
import { OrganizationsSectionService } from './organizationsSection.service';

class OrganizationsSectionStore {
  constructor() {
    this.organizations = null;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  clear() {
    this.organizations = null;
  }

  setOrganizations(organizationsArray) {
    this.organizations = organizationsArray.map(
      (organizationItem) => new Organization(organizationItem),
    );
  }

  getOrganizations(applicationId) {
    privateResolverWithPayload({
      fetcher: OrganizationsSectionService.fetchOrganizations,
      payload: applicationId,
      setter: this.setOrganizations,
    });
  }
}

export default new OrganizationsSectionStore();
