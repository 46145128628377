import { http } from '@utils';

export class ReferencesService {
  static fetchReferences(payload) {
    return http.get('/api/references/', {
      params: payload,
    });
  }

  static fetchReviewedStatusOptions() {
    return http.options('/api/references/');
  }

  static patchReference({ id, ...fields }) {
    return http.patch(`/api/references/${id}`, fields);
  }
}
