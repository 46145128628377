import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { colors, spacing } from '@styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: colors.white,
    display: 'block',
  },
  title: {
    color: colors.white,
    fontSize: '27px',
    marginTop: '360px',
    wordWrap: 'break-word',
  },
  root: {
    textAlign: 'center',
  },
  circular: {
    textAlign: 'center',
    marginTop: spacing.spacing_xl,
  },
}));

export const Loader = ({ message }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open>
        <div className={classes.title}>{message}</div>
        <CircularProgress color="inherit" className={classes.circular} />
      </Backdrop>
    </div>
  );
};
