const ReferencesNoCard = () => (
  <div
    className="
                  flex flex-col
                  md:flex-nowrap
                  justify-center
                  md:justify-around
                  p-8
                ">
    <h2 className="text-center text-3xl my-60 text-blue-900">Nothing to do!</h2>
  </div>
);

export default ReferencesNoCard;
