import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { NonFieldErrors, TextField } from '@components';
import { AuthStore, ErrorStore } from '@stores';
import { generateErrorMessages } from '@utils';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import * as Yup from 'yup';

const ResetConfirmForm = observer(() => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { uid, token } = useParams();

  useEffect(() => {
    ErrorStore.dropErrors();
  }, []);

  const formik = useFormik({
    initialValues: {
      newPassword1: '',
      newPassword2: '',
    },
    validationSchema: Yup.object({
      newPassword1: Yup.string()
        .min(8, 'Must be 8 characters and more')
        .required('Required'),
      newPassword2: Yup.string()
        .oneOf([Yup.ref('newPassword1'), null], 'Passwords must match')
        .required('Required'),
    }),
    onSubmit: async (values) => {
      const body = values;
      body.uid = uid;
      body.token = token;
      setLoading(true);
      try {
        await AuthStore.resetPassword(body);
        history.push('/password-reset/success');
      } finally {
        setLoading(false);
      }
    },
  });

  const resetError = () => {
    toast('Error, try again!', { type: 'error' });
    history.push('/login');
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="md:px-20 py-6">
        <TextField
          disabled={loading}
          label="New Password"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.newPassword1}
          id="newPassword1"
          type="password"
          errors={generateErrorMessages(
            'newPassword1',
            formik,
            ErrorStore,
            'new_password1',
          )}
        />
      </div>
      <div className="md:px-20 py-6">
        <TextField
          disabled={loading}
          label="Repeat New Password"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.newPassword2}
          id="newPassword2"
          type="password"
          errors={generateErrorMessages(
            'newPassword2',
            formik,
            ErrorStore,
            'new_password2',
          )}
        />
      </div>
      <NonFieldErrors />
      {ErrorStore.errors?.uid || ErrorStore.errors?.token ? resetError() : null}

      <div className="text-center my-8">
        <button
          type="submit"
          className="px-8 py-3 text-2xl bg-primary text-white rounded-md">
          Reset Password
        </button>
      </div>
    </form>
  );
});

export default ResetConfirmForm;
