import { InputTextField } from '@components';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { ApplicationStore } from '@stores';
import { colors, fontSizes } from '@styles';

const useStyles = makeStyles({
  fieldLabel: {
    flexGrow: 1,
    fontWeight: 300,
    fontSize: 13,
    marginBottom: '0',
  },
  link: {
    color: colors.black,
    fontSize: fontSizes.font_size_s,
    textDecoration: 'underline',
  },
});

export const ApplicationSectionContent = ({ applicationInfo }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2} justify="space-around" alignItems="center">
      <Grid item xs={12} md={12}>
        <InputTextField
          name="project_title"
          value={applicationInfo.project_title}
          label="Project Title"
          required
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputTextField
          name="project_field_1"
          value={
            ApplicationStore.verifiableApplication.info.project_field_1_name
          }
          label="Project Field"
          required
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputTextField
          name="joint_applicant_name"
          value={applicationInfo.joint_applicant_name}
          label="Joint Applicant Name"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputTextField
          name="project_field_2"
          value={
            ApplicationStore.verifiableApplication.info.project_field_2_name
          }
          label="Project Field, additional"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputTextField
          name="joint_applicant_email"
          value={applicationInfo.joint_applicant_email}
          label="Joint Applicant Email"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1" className={classes.fieldLabel}>
          Websites
        </Typography>
      </Grid>
      <Grid item xs={12} md={10}>
        <InputTextField
          name="website_1"
          value={applicationInfo.website_1}
          label="Websites"
          visibleLabel={false}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        {applicationInfo.website_1 ? (
          <Link
            className={classes.link}
            target="_blank"
            href={applicationInfo.website_1}>
            Preview URL
          </Link>
        ) : null}
      </Grid>
      <Grid item xs={12} md={10}>
        <InputTextField
          name="website_2"
          value={applicationInfo.website_2}
          label="Websites"
          visibleLabel={false}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        {applicationInfo.website_2 ? (
          <Link
            className={classes.link}
            target="_blank"
            href={applicationInfo.website_2}>
            Preview URL
          </Link>
        ) : null}
      </Grid>
    </Grid>
  );
};
