import { useMediaQuery } from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';

/**
 * `useIsMobile` listens for matches to the CSS media query
 *  for mobile device size, and returns a boolean value
 *  indicating whether the app is being viewed on a mobile device.
 *  It returns `true` when the app is being viewed on a mobile device.
 *  Otherwise, it will return `false`.
 */

export const useIsMobile = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
};
