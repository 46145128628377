import { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { TextField as MTextField } from '@material-ui/core';
import { ErrorStore, UserStore, UtilsStore } from '@stores';
import { generateErrorMessages } from '@utils';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import { v4 } from 'uuid';
import * as Yup from 'yup';
import { HelpInfo } from '../HelpInfo';
import InstitutionDialog from '../InstitutionField/InstitutionDialog';
import { NonFieldErrors } from '../NonFieldErrors';
import { Select } from '../Select';
import { TextField } from '../TextField';

const PersonalInformationForm = observer(({ submitHandler }) => {
  const [showInstitution, setShowInstitution] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      await UserStore.getUserPersonalInfoOptions();
      await UserStore.getCurrentUser();
      await UtilsStore.getInstitutions();
    }

    fetchData();
    ErrorStore.dropErrors();
  }, []);

  const values = {
    acceptedToc: UserStore.user.accepted_toc,
    citizenship: UserStore.user.citizenship,
    citizenshipNote: UserStore.user.citizenship_note,
    company: UserStore.user.company,
    country: UserStore.user.country,
    dateOfBirth: UserStore.user.date_of_birth ?? '',
    firstName: UserStore.user.first_name,
    gender: UserStore.user.gender,
    genderOther: UserStore.user.gender_other,
    id: UserStore.user.id,
    lastName: UserStore.user.last_name,
    middleName: UserStore.user.middle_name,
    namePrefix: UserStore.user.name_prefix,
    nameSuffix: UserStore.user.name_suffix,
    note: UserStore.user.note,
    placeOfBirth: UserStore.user.place_of_birth,
    title: UserStore.user.title,
    institution: UserStore.user.institution || '',
    institutionOther: UserStore.user.institution_other || '',
    institutionType: UserStore.user.institutionType || '',
  };

  const currDate = new Date();
  const maxDate = new Date();
  const minDate = new Date();

  maxDate.setFullYear(currDate.getFullYear() - 18);
  minDate.setFullYear(currDate.getFullYear() - 120);

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: values,
    validationSchema: !UserStore.user.user_summary.juror
      ? Yup.object({
          firstName: Yup.string().required('Required'),
          lastName: Yup.string().required('Required'),
          dateOfBirth: Yup.date()
            .min(minDate, 'Date is outside of allowed range')
            .max(maxDate, 'Date is outside of allowed range')
            .required('Required'),
          placeOfBirth: Yup.string().required('Required'),
          citizenship: Yup.string().required('Required'),
          gender: Yup.string().required('Required'),
          title: Yup.string()
            .max(120, 'Must be 120 characters or less')
            .required('Required'),
          institutionOther: Yup.string().when('showOther', {
            is: true,
            then: Yup.string().required('Required'),
          }),
          institution: Yup.string().when('institutionOther', {
            is: (val) => Boolean(val) !== false,
            then: Yup.string(),
            otherwise: Yup.string().required('Required'),
          }),
        })
      : Yup.object({}),
    onSubmit: async (values) => {
      await setLoading(true);
      try {
        await UserStore.updateUserPersonalInfo(values);
      } finally {
        setLoading(false);
        submitHandler();
      }
    },
  });

  const renderInstitutionOther = () => {
    if (!formik.values.showOther && !formik.values.institutionOther)
      return null;

    return (
      <div className="px-2 py-2">
        <TextField
          label="Institution Other"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.institutionOther}
          id="institutionOther"
          disabled
          errors={generateErrorMessages(
            'institutionOther',
            formik,
            ErrorStore,
            'institution_other',
          )}
        />
      </div>
    );
  };

  const renderGenderOtherField = () => {
    if (formik.values.gender !== 'o') {
      return null;
    }

    return (
      <div className="flex flex-row justify-between">
        <div className="w-1/4 m-4">
          <div className="px-2 py-2">
            <TextField
              label="Gender Other"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.genderOther}
              id="genderOther"
              errors={generateErrorMessages(
                'genderOther',
                formik,
                ErrorStore,
                'gender_other',
              )}
            />
          </div>
        </div>
      </div>
    );
  };

  const onSave = (value) => {
    if (value.institution) {
      formik.setFieldValue('institution', value.institution.id);
      formik.setFieldValue('institutionOther', '');
      setShowInstitution(true);
      formik.setFieldValue('showOther', false);
    } else {
      formik.setFieldValue('institutionOther', value.institutionOther);
      formik.setFieldValue('institution', '');
      setShowInstitution(false);
      formik.setFieldValue('showOther', true);
    }
  };

  const renderInstitutionValue = () => {
    const currentValue = UtilsStore.institutions.filter(
      (item) => item.id === formik.values.institution,
    );

    return currentValue?.map((item) => (
      <option key={v4()} value={item.id}>
        {item.name}
      </option>
    ));
  };

  return (
    <form onSubmit={formik.handleSubmit} className="w-full">
      <h2 className="text-black text-2xl px-2">
        Edit Personal Information{' '}
        <HelpInfo metaTextName="account-personal-information" />
      </h2>
      <div className="flex flex-row justify-between">
        <div className="w-1/5 m-4">
          <div className="px-2 py-2">
            <TextField
              label="Name Prefix"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.namePrefix}
              id="namePrefix"
              errors={generateErrorMessages(
                'namePrefix',
                formik,
                ErrorStore,
                'name_prefix',
              )}
            />
          </div>
        </div>
        <div className="w-1/5 m-4">
          <div className="px-2 py-2">
            <TextField
              label={
                <label
                  htmlFor="firstName"
                  className="text-gray-700 dark:text-gray-200">
                  First Name <span className="text-red-600">*</span>
                </label>
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
              id="firstName"
              errors={generateErrorMessages(
                'firstName',
                formik,
                ErrorStore,
                'first_name',
              )}
            />
          </div>
        </div>
        <div className="w-1/5 m-4">
          <div className="px-2 py-2">
            <TextField
              label="Middle Name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.middleName}
              id="middleName"
              errors={generateErrorMessages(
                'middleName',
                formik,
                ErrorStore,
                'middle_name',
              )}
            />
          </div>
        </div>
        <div className="w-1/5 m-4">
          <div className="px-2 py-2">
            <TextField
              label={
                <label
                  htmlFor="lastName"
                  className="text-gray-700 dark:text-gray-200">
                  Last Name <span className="text-red-600">*</span>
                </label>
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
              id="lastName"
              errors={generateErrorMessages(
                'lastName',
                formik,
                ErrorStore,
                'last_name',
              )}
            />
          </div>
        </div>
        <div className="w-1/5 m-4">
          <div className="px-2 py-2">
            <TextField
              label="Name Suffix"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.nameSuffix}
              id="nameSuffix"
              errors={generateErrorMessages(
                'nameSuffix',
                formik,
                ErrorStore,
                'name_suffix',
              )}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-between">
        <div className="w-1/4 m-4">
          <div className="px-2 py-2">
            <label
              htmlFor="dateOfBirth"
              className="text-gray-700 dark:text-gray-200">
              Date of Birth <span className="text-red-600">*</span>
            </label>
            <div className="mt-2">
              <MTextField
                id="dateOfBirth"
                name="dateOfBirth"
                type="date"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.dateOfBirth}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            {formik.touched.dateOfBirth && formik.errors.dateOfBirth ? (
              <div className="text-red-500 py-1">
                {formik.errors.dateOfBirth}
              </div>
            ) : null}
            {ErrorStore.errors?.date_of_birth
              ? ErrorStore.errors.date_of_birth.map((item) => (
                  <div key={v4()} className="text-red-500 py-1">
                    {item}
                  </div>
                ))
              : null}
          </div>
        </div>
        <div className="w-1/4 m-4">
          <div className="px-2 py-2">
            <TextField
              label={
                <label
                  htmlFor="placeOfBirth"
                  className="text-gray-700 dark:text-gray-200">
                  Place of Birth <span className="text-red-600">*</span>
                </label>
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.placeOfBirth}
              id="placeOfBirth"
              errors={generateErrorMessages(
                'placeOfBirth',
                formik,
                ErrorStore,
                'place_of_birth',
              )}
            />
          </div>
        </div>
        <div className="w-1/4 m-4">
          <div className="px-2 py-2">
            <TextField
              label={
                <label
                  htmlFor="citizenship"
                  className="text-gray-700 dark:text-gray-200">
                  Citizenship <span className="text-red-600">*</span>
                </label>
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.citizenship}
              id="citizenship"
              errors={generateErrorMessages(
                'citizenship',
                formik,
                ErrorStore,
                'citizenship',
              )}
            />
            <p className="text-red-400 text-xs ml-1">
              If you are not a U.S. or Canadian citizen, indicate your permanent{' '}
              resident status in the Citizenship Note field
            </p>
          </div>
        </div>
        <div className="w-1/4 m-4">
          <div className="px-2 py-2">
            <TextField
              label="Citizenship Note"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.citizenshipNote}
              id="citizenshipNote"
              errors={generateErrorMessages(
                'citizenshipNote',
                formik,
                ErrorStore,
                'citizenship_note',
              )}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-between">
        <div className="w-1/4 m-4">
          <div className="px-2 py-2">
            <Select
              label={
                <label
                  htmlFor="gender"
                  className="text-gray-700 dark:text-gray-200">
                  Gender <span className="text-red-600">*</span>
                </label>
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.gender}
              id="gender"
              defaultEmptyValue
              itemName="label"
              itemValue="value"
              items={[
                {
                  value: 'm',
                  label: 'Male',
                },
                {
                  value: 'f',
                  label: 'Female',
                },
                {
                  value: 'o',
                  label: 'Other',
                },
              ]}
              errors={generateErrorMessages(
                'gender',
                formik,
                ErrorStore,
                'gender',
              )}
            />
          </div>
        </div>
        <div className="w-1/4 m-4">
          <div className="px-2 py-2">
            <label
              htmlFor="institution"
              className="text-gray-700 dark:text-gray-200">
              Institution / Company <span className="text-red-600">*</span>
            </label>

            {showInstitution && !formik.values.institutionOther ? (
              <select
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.institution}
                id="institution"
                disabled
                required
                className="
                  appearance-none
                  block
                  w-full
                  px-4
                  py-2
                  mt-2
                  text-gray-700
                  bg-white
                  border
                  rounded-md
                  focus:outline-none
                  focus:ring
                  border-gray-300
                  focus:border-blue-300
                  mb-2
                ">
                <option value="" disabled defaultValue></option>
                {formik.values.institution ? renderInstitutionValue() : null}
              </select>
            ) : null}

            <div className="flex">
              <InstitutionDialog onSave={onSave} />
              <p className="text-red-400 text-xs ml-1 px-2">
                No institutional affiliation? Click (?) Help above for further
                instructions.
              </p>
            </div>

            {formik.touched.institution && formik.errors.institution ? (
              <div className="text-red-500 py-1">
                {formik.errors.institution}
              </div>
            ) : null}
            {ErrorStore.errors?.institution
              ? ErrorStore.errors.institution.map((item) => (
                  <div key={v4()} className="text-red-500 py-1">
                    {item}
                  </div>
                ))
              : null}
          </div>
          {renderInstitutionOther()}
        </div>
        <div className="w-1/4 m-4">
          <div className="px-2 py-2">
            <TextField
              label={
                <label
                  htmlFor="title"
                  className="text-gray-700 dark:text-gray-200">
                  Title/Position <span className="text-red-600">*</span>
                </label>
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.title}
              id="title"
              errors={generateErrorMessages(
                'title',
                formik,
                ErrorStore,
                'title',
              )}
            />
          </div>
        </div>
      </div>
      {renderGenderOtherField()}

      <NonFieldErrors />
      <div className="flex flex-col md:flex-row justify-center mt-2">
        <button
          disabled={loading}
          onClick={submitHandler}
          type="button"
          className="
            w-max
            md:mx-2
            px-8
            py-3
            text-xl
            bg-gray-400
            text-black
            rounded-md
            text-center
          ">
          Cancel
        </button>
        <button
          disabled={loading}
          type="submit"
          className="
            w-max
            md:mx-2
            px-8
            py-3
            text-xl
            bg-primary
            text-white
            rounded-md
            text-center
          ">
          {loading ? <CircularProgress color="white" /> : 'Save'}
        </button>
      </div>
    </form>
  );
});

export default PersonalInformationForm;
