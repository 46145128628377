import { useEffect } from 'react';
import { Loader } from '@components';
import { AdminApplicationLayoutStore, ApplicationStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { SectionsWrapper } from '../../components';
import { sectionsNames, sectionsTypes } from '../../services/constants';
import SectionNoteDialogStore from '../../services/SectionNoteDialogStore';
import SectionStatusesStore from '../../services/SectionStatusesStore';
import { ApplicationSectionContent } from './components/ApplicationSectionContent';
import ApplicationSectionStore from './services/ApplicationSectionStore';

export const ApplicationSectionScene = observer(() => {
  useEffect(() => {
    const applicationId = ApplicationStore.verifiableApplication.id;
    SectionStatusesStore.getSectionStatuses(
      applicationId,
      sectionsNames.application,
    );
    ApplicationSectionStore.getApplicationInfo(applicationId);
    SectionNoteDialogStore.getSectionNote(
      sectionsTypes.application,
      applicationId,
    );

    return () => {
      SectionStatusesStore.clear();
      ApplicationSectionStore.clear();
      SectionNoteDialogStore.clear();
    };
  }, []);

  return (
    <>
      {SectionStatusesStore.sectionStatuses &&
      ApplicationSectionStore.applicationInfo ? (
        <SectionsWrapper
          sectionTitle="Application Information"
          sectionName={sectionsNames.application}
          sectionFullName={sectionsNames.application}
          status={SectionStatusesStore.sectionStatuses}
          onCloseSection={
            AdminApplicationLayoutStore.setCloseApplicationInfoDialog
          }
          open={AdminApplicationLayoutStore.showApplicationInfoDialog}>
          <ApplicationSectionContent
            applicationInfo={ApplicationSectionStore.applicationInfo}
          />
        </SectionsWrapper>
      ) : (
        <Loader message="Loading Info Section..." />
      )}
    </>
  );
});
