import { http } from '@utils';

export default class AuthService {
  static signUp(data) {
    return http.post('/auth/SignUp', {
      ...data,
    });
  }

  static signIn({ email, password }) {
    return http.post('/api/token/', {
      email,
      password,
    });
  }

  static logIn({ email, password }) {
    return http.post('/api/login/', {
      email,
      password,
    });
  }

  static refreshToken({ refreshToken }) {
    return http.post('/api/token/refresh/', {
      refresh: refreshToken,
    });
  }

  static passwordResetRequest({ email }) {
    return http.post('/api/password/reset/', {
      email,
    });
  }

  static passwordReset({ uid, token, newPassword1, newPassword2 }) {
    return http.post('/api/password/reset/confirm/', {
      uid,
      token,
      new_password1: newPassword1,
      new_password2: newPassword2,
    });
  }

  static activate({ uidb64, token }) {
    return http.post('/api/users/activate/', {
      uidb64,
      token,
    });
  }

  static passwordResetConfirm(data) {
    return http.post('/api/password/reset/', {
      uid: data.uid,
      token: data.token,
      new_password1: data.newPassword1,
      new_password2: data.newPassword2,
    });
  }
}
