import { AuthService, UserService } from '@services';
import { makeAutoObservable } from 'mobx';

class AuthStore {
  constructor() {
    this.accessToken = localStorage.getItem('access_token') || null;
    this.refreshToken = localStorage.getItem('refresh_token') || null;
    this.isAuthenticated = !!(
      localStorage.getItem('access_token') &&
      localStorage.getItem('refresh_token')
    );
    this.id = localStorage.getItem('id') || null;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setId(id) {
    localStorage.setItem('id', id);
    this.id = id;
  }

  setAccessToken(token) {
    this.accessToken = token;
    localStorage.setItem('access_token', token);
  }

  setRefreshToken(token) {
    this.refreshToken = token;
    localStorage.setItem('refresh_token', token);
  }

  setIsAuthenticated(value) {
    this.isAuthenticated = value;
  }

  setInProgress(value) {
    this.inProgress = value;
  }

  async login({ email, password }) {
    const response = await AuthService.logIn({ email, password });
    this.setRefreshToken(response.data.refresh_token);
    this.setAccessToken(response.data.access_token);
    this.setId(response.data.user.pk);
    this.setIsAuthenticated(true);
  }

  async register({ name, surname, email, password, country, terms }) {
    await UserService.createUser({
      name,
      surname,
      email,
      password,
      country,
      terms,
    });
  }

  async refresh() {
    const response = await AuthService.refreshToken({
      refreshToken: this.refreshToken,
    });
    this.setAccessToken(response.data.access);
  }

  async resetPasswordRequest({ email }) {
    await AuthService.passwordResetRequest({ email });
  }

  async resetPassword({ uid, token, newPassword1, newPassword2 }) {
    await AuthService.passwordReset({
      uid,
      token,
      newPassword1,
      newPassword2,
    });
  }

  async activate({ uidb64, token }) {
    await AuthService.activate({ uidb64, token });
  }

  logout() {
    localStorage.clear();
    this.isAuthenticated = false;
    this.id = null;
    this.accessToken = null;
    this.refreshToken = null;
  }
}

export default new AuthStore();
