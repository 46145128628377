import { InputTextField } from '@components';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { UtilsStore } from '@stores';
import { colors } from '@styles';
import AddressesSectionStore from '../services/AddressesSectionStore';

const useStyles = makeStyles({
  dialogWrapper: {
    padding: '16px 0',
  },
  dialogActions: {
    background: colors.gray,
    textTransform: 'none',
    color: colors.black,
    margin: '0 8px',
  },
  fieldLabel: {
    flexGrow: 1,
    fontWeight: 400,
    fontSize: '1rem',
  },
});

export const AlternateAddress = ({ address }) => {
  const classes = useStyles();

  return (
    <>
      <DialogContent>
        <Grid container spacing={2} justify="space-around" alignItems="center">
          <Grid item xs={12} md={4}>
            <InputTextField
              name="name_prefix"
              value={address.address.name_prefix}
              label="Name Prefix"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputTextField
              name="name_first"
              value={address.address.name_first}
              label="First Name"
              required
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputTextField
              name="name_last"
              value={address.address.name_last}
              label="Last Name"
              required
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputTextField
              name="email"
              value={address.address.email}
              label="Email"
              required
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputTextField
              name="phone_type"
              value={UtilsStore.getPhoneTypeName(address.address.phone_type)}
              label="Phone Type"
              required
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputTextField
              name="phone_number"
              value={address.address.phone_number}
              label="Phone Number"
              required
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputTextField
              name="country"
              value={UtilsStore.getCountryName(address.address.country)}
              label="Country"
              required
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputTextField
              name="address_line_1"
              value={address.address.address_line_1}
              label="Address Line 1"
              required
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputTextField
              name="address_line_2"
              value={address.address.address_line_2}
              label="Address Line 2"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputTextField
              name="city"
              value={address.address.city}
              label="City/Town"
              required
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputTextField
              name="state"
              value={address.address.state}
              label="State"
              required
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputTextField
              name="zip"
              value={address.address.zip}
              label="Zip"
              required
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogWrapper}>
        <Grid container justify="center" alignItems="center">
          <Button
            className={classes.dialogActions}
            onClick={AddressesSectionStore.setCloseAddressItemDialog}
            color="primary">
            Done
          </Button>
        </Grid>
      </DialogActions>
    </>
  );
};
