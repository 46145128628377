import { ApplicationService, privateResolverWithPayload } from '@services';
import fileDownload from 'js-file-download';
import { makeAutoObservable } from 'mobx';
import { Application } from '../scenes/AdminApplicationItem/scenes/ApplicationSection/models/Application';
import AdminSectionsService from '../scenes/AdminApplicationItem/services/adminSections.service';
import AuthStore from './AuthStore';

class ApplicationStore {
  constructor() {
    this.application = null;
    this.verifiableApplication = null;
    this.disabledApprovedStatus = false;
    this.applications = [];
    this.totalCount = 0;
    this.sectionStatus = null;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  clearApplications() {
    this.applications = [];
  }

  clearVerifiableApplication() {
    this.verifiableApplication = null;
    this.sectionStatus = null;
  }

  setApplications(applicationsParams) {
    this.totalCount = applicationsParams.count;
    this.applications = applicationsParams.results.map(
      (application) => new Application(application),
    );
  }

  setSectionStatus(status) {
    this.disabledApprovedStatus = Object.keys(status.section_status).some(
      (key) => status.section_status[key].approved === false,
    );
    this.sectionStatus = status.section_status;
  }

  setVerifiableApplication(verifiableApplication) {
    this.verifiableApplication = new Application(verifiableApplication);
  }

  async filterSectionStatus(newStatus, nameStatus) {
    await this.getVerifiableApplication({ id: this.verifiableApplication.id });
    await this.getSectionStatus(this.verifiableApplication.id);
    this.sectionStatus[nameStatus] = newStatus;
  }

  getSectionStatus(applicationId) {
    privateResolverWithPayload({
      fetcher: AdminSectionsService.fetchSectionsStatuses,
      payload: applicationId,
      setter: this.setSectionStatus,
    });
  }

  getApplicationList(payload) {
    privateResolverWithPayload({
      fetcher: ApplicationService.getApplicationList,
      payload,
      setter: this.setApplications,
      progress: true,
    });
  }

  getVerifiableApplication({ id }) {
    privateResolverWithPayload({
      fetcher: ApplicationService.getApplicationItem,
      payload: id,
      setter: this.setVerifiableApplication,
    });
  }

  updateVerifiableApplication(payload) {
    privateResolverWithPayload({
      fetcher: ApplicationService.patchApplication,
      payload,
      setter: this.getVerifiableApplication,
      progress: true,
    });
  }

  async getCurrentUserApplication() {
    const result = await ApplicationService.getCurrentApplication({
      userId: AuthStore.id,
    });
    this.application = result.data[0];
  }

  async getCompletedApplicationPDF() {
    await ApplicationService.downloadCompletedApplicationPDF({
      id: this.application.id,
    }).then((response) => {
      fileDownload(response.data, 'application.pdf');
    });
  }

  async createApplication() {
    const body = {};

    await ApplicationService.createApplication({
      body: body,
    });
    await this.getCurrentUserApplication();
  }

  async submitApplication({ applicationId }) {
    await ApplicationService.submitApplication({
      applicationId,
    });
  }
}

export default new ApplicationStore();
