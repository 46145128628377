import { useEffect } from 'react';
import { Loader } from '@components';
import { ApplicationStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { sectionsNames, sectionsTypes } from '../../services/constants';
import SectionNoteDialogStore from '../../services/SectionNoteDialogStore';
import SectionStatusesStore from '../../services/SectionStatusesStore';
import { DocumentsSectionContent } from './components';
import DocumentsSectionStore from './services/DocumentsSectionStore';

export const DocumentsSectionScene = observer(() => {
  useEffect(() => {
    const applicationId = ApplicationStore.verifiableApplication.id;
    SectionStatusesStore.getSectionStatuses(
      applicationId,
      sectionsNames.documents,
    );
    DocumentsSectionStore.getDocuments(applicationId);
    SectionNoteDialogStore.getSectionNote(
      sectionsTypes.documents,
      applicationId,
    );
    DocumentsSectionStore.getDocumentReviewedStatus(applicationId);

    return () => {
      DocumentsSectionStore.clear();
      SectionStatusesStore.clear();
      SectionNoteDialogStore.clear();
    };
  }, []);

  return (
    <>
      {SectionStatusesStore.sectionStatuses &&
      DocumentsSectionStore.documents &&
      DocumentsSectionStore.documentReviewedStatusTypes ? (
        <DocumentsSectionContent
          documents={DocumentsSectionStore.documents}
          status={SectionStatusesStore.sectionStatuses}
          documentReviewedStatusTypes={
            DocumentsSectionStore.documentReviewedStatusTypes
          }
        />
      ) : (
        <Loader message="Loading Documents Section..." />
      )}
    </>
  );
});
