import { Redirect } from 'react-router-dom';
import { HomeAddressCard, OfficeAddressCard, UserNavbar } from '@components';
import { UserStore } from '@stores';
import { observer } from 'mobx-react-lite';

const AddressesScene = observer(() => {
  if (UserStore.shouldUserNavigateToStart()) {
    return <Redirect to="/user/profile" />;
  }

  return (
    <div className="border rounded-md border-gray-300 md:mt-10 shadow-lg">
      <div className="flex flex-col md:flex-row">
        <UserNavbar />
        <div className="p-8 mx-auto w-full flex flex-row flex-wrap">
          <div className="w-1/3 p-2">
            <HomeAddressCard />
          </div>
          <div className="w-1/3 p-2">
            <OfficeAddressCard />
          </div>
        </div>
      </div>
    </div>
  );
});
export default AddressesScene;
