import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  AdminApplicationLayoutStore,
  ApplicationStore,
  UtilsStore,
} from '@stores';
import { useIsMobile } from '@styles';
import { observer } from 'mobx-react-lite';
import {
  ApplicationNoteDialog,
  RevisionRequestDialog,
  SectionNoteDialog,
} from '../../../scenes/AdminApplicationItem/components';
import {
  AddressesSectionScene,
  ApplicationSectionScene,
  DocumentsSectionScene,
  EducationSectionScene,
  LanguagesSectionScene,
  OrganizationsSectionScene,
  PositionsSectionScene,
  ReferencesSectionScene,
  UserSectionScene,
} from '../../../scenes/AdminApplicationItem/scenes';
import ApplicationNoteDialogStore from '../../../scenes/AdminApplicationItem/services/ApplicationNoteDialogStore';
import SectionNoteDialogStore from '../../../scenes/AdminApplicationItem/services/SectionNoteDialogStore';
import { Loader } from '../../Loader/index';

export const AdminApplicationLayout = observer(({ children }) => {
  const { applicationId } = useParams();
  const isMobile = useIsMobile();

  useEffect(() => {
    AdminApplicationLayoutStore.setMobile(isMobile);
  }, [isMobile]);

  useEffect(() => {
    if (applicationId) {
      ApplicationStore.getVerifiableApplication({ id: applicationId });
      ApplicationStore.getSectionStatus(applicationId);
      SectionNoteDialogStore.getSectionTypes(applicationId);
      UtilsStore.getReviewedStatusesOptions(applicationId);
      UtilsStore.getCountries();
      UtilsStore.getInstitutions();
    }

    return () => {
      ApplicationStore.clearVerifiableApplication();
    };
  }, [applicationId]);

  return (
    <>
      {ApplicationNoteDialogStore.showApplicationNoteDialog ? (
        <ApplicationNoteDialog />
      ) : null}
      {AdminApplicationLayoutStore.showRevisionRequestDialog ? (
        <RevisionRequestDialog />
      ) : null}
      {SectionNoteDialogStore.showSectionNoteDialog ? (
        <SectionNoteDialog />
      ) : null}
      {AdminApplicationLayoutStore.showDocumentsDialog ? (
        <DocumentsSectionScene />
      ) : null}
      {AdminApplicationLayoutStore.showUserDialog ? <UserSectionScene /> : null}
      {AdminApplicationLayoutStore.showAddressesDialog ? (
        <AddressesSectionScene />
      ) : null}
      {AdminApplicationLayoutStore.showPositionsDialog ? (
        <PositionsSectionScene />
      ) : null}
      {AdminApplicationLayoutStore.showLanguagesDialog ? (
        <LanguagesSectionScene />
      ) : null}
      {AdminApplicationLayoutStore.showOrganizationsDialog ? (
        <OrganizationsSectionScene />
      ) : null}
      {AdminApplicationLayoutStore.showEducationDialog ? (
        <EducationSectionScene />
      ) : null}
      {AdminApplicationLayoutStore.showReferencesDialog ? (
        <ReferencesSectionScene />
      ) : null}
      {AdminApplicationLayoutStore.showApplicationInfoDialog ? (
        <ApplicationSectionScene />
      ) : null}
      {ApplicationStore.verifiableApplication &&
      ApplicationStore.sectionStatus ? (
        children
      ) : (
        <Loader message="Loading application page..." />
      )}
    </>
  );
});
