import { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteIcon from '@material-ui/icons/Delete';
import { ProgressStore, SectionsStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { v4 } from 'uuid';
import {
  SectionContentList,
  SectionContentListBody,
  SectionContentListHeader,
  SectionContentListHeaderActions,
  SectionContentListHeaderTitle,
  SectionContentListItem,
  SectionContentListItemActions,
  SectionContentListItemTitle,
} from '../../../components';
import { LanguagesStore } from '../services';
import LanguagesForm from './LanguagesForm';

const LanguagesList = observer(() => {
  const [showDelete, setShowDelete] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [fields, setFields] = useState(null);

  useEffect(() => {
    async function fetchData() {
      await LanguagesStore.getApplicationLanguages();
      await LanguagesStore.getApplicationLanguagesOptions();
      await setLoading(false);
    }

    fetchData();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFields(null);
  };

  const updateLanguage = (language) => {
    handleClickOpen();
    setFields(language);
  };

  const handleOpenDelete = (id) => {
    setDeleteItem(id);
    setShowDelete(true);
  };

  const handleCloseDelete = () => {
    setShowDelete(false);
  };

  const deleteLanguage = async () => {
    handleCloseDelete();
    ProgressStore.setInProgress();

    await LanguagesStore.deleteApplicationLanguage(deleteItem)
      .then(() => LanguagesStore.unacknowledgeApplicationLanguagesSection())
      .then(() => SectionsStore.getApplicationSectionsStatuses())
      .finally(() => ProgressStore.setNotInProgress());
  };

  const renderDeleteDialog = () => (
    <Dialog open={showDelete}>
      <DialogTitle>Attention</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure want do delete?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={deleteLanguage}
          variant="contained"
          color="secondary"
          startIcon={<DeleteIcon />}>
          Delete
        </Button>
        <Button onClick={handleCloseDelete}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );

  const renderList = () => (
    <SectionContentList>
      <SectionContentListHeader>
        <SectionContentListHeaderTitle>Languages</SectionContentListHeaderTitle>
        <SectionContentListHeaderActions>
          <button
            type="button"
            className="
            px-2
            text-md
            bg-primary
            text-white
            rounded-md
            text-center
          "
            onClick={handleClickOpen}>
            Enter another Language
          </button>
        </SectionContentListHeaderActions>
      </SectionContentListHeader>
      <SectionContentListBody>
        {LanguagesStore.languages.map((language, index) => {
          let highlight = false;

          if (index % 2 === 0 || index === 0) {
            highlight = true;
          }

          return (
            <SectionContentListItem highlight={highlight} key={v4()}>
              <SectionContentListItemTitle>
                {language.name}
              </SectionContentListItemTitle>
              <SectionContentListItemActions>
                <button
                  className="px-2 py-1"
                  onClick={() => {
                    updateLanguage(language);
                  }}>
                  Edit
                </button>
                <button
                  className="text-red-600 px-2 py-1"
                  onClick={() => {
                    handleOpenDelete(language.id);
                  }}>
                  Delete
                </button>
              </SectionContentListItemActions>
            </SectionContentListItem>
          );
        })}
      </SectionContentListBody>
    </SectionContentList>
  );

  const renderEmptyList = () => (
    <div className="my-16">
      <div
        className="
          flex flex-col
          md:flex-nowrap
          justify-center
          p-8
        ">
        <h2 className="text-center text-2xl text-blue-900">
          You have not entered any Languages
        </h2>
        <button
          type="button"
          className="
            w-max
            px-8
            py-1
            text-md
            bg-primary
            text-white
            rounded-md
            text-center
            mt-2
            mx-auto
          "
          onClick={handleClickOpen}>
          Enter a Language
        </button>
      </div>
    </div>
  );

  const renderContent = () => {
    if (LanguagesStore.languages.length) {
      return renderList();
    } else {
      return renderEmptyList();
    }
  };

  return (
    <>
      {loading ? null : renderContent()}
      <Dialog
        disableBackdropClick
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <LanguagesForm fields={fields} submitHandler={handleClose} />
        </DialogContent>
      </Dialog>
      {renderDeleteDialog()}
    </>
  );
});

export default LanguagesList;
