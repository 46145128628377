import { useEffect } from 'react';
import { InputTextField } from '@components';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { AdminApplicationLayoutStore } from '@stores';
import { colors, mixins } from '@styles';
import { observer } from 'mobx-react-lite';
import EducationSectionStore from '../services/EducationSectionStore';

const useStyles = makeStyles({
  rootDialog: {
    padding: '0 20px',
  },
  toolbar: {
    ...mixins.flexToolBar,
  },
  dialogWrapper: {
    padding: '16px 0',
  },
  dialogAction: {
    background: colors.gray,
    textTransform: 'none',
    color: colors.black,
    margin: '18px 8px 0 0',
  },
});

export const EducationDetailsDialog = observer(({ selectedEducation }) => {
  const { isMobile } = AdminApplicationLayoutStore;
  const {
    showEducationDetailsDialog,
    clearSelectedEducation,
    getEducationLevelName,
    setCloseEducationDetailsDialog,
  } = EducationSectionStore;
  const classes = useStyles();

  useEffect(
    () => () => {
      clearSelectedEducation();
    },
    [clearSelectedEducation],
  );

  return (
    <Dialog
      className={classes.rootDialog}
      maxWidth="md"
      fullScreen={isMobile}
      open={showEducationDetailsDialog}>
      <Toolbar className={classes.toolbar}>
        <Typography variant="h5">Education</Typography>
      </Toolbar>
      <DialogContent>
        <Grid container spacing={2} justify="space-around" alignItems="center">
          <Grid item xs={12}>
            <InputTextField
              name="name"
              value={
                selectedEducation.institution?.name ||
                selectedEducation.institution_other
              }
              label="Institution"
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputTextField
              name="level"
              value={getEducationLevelName(selectedEducation.level)}
              label="Level"
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputTextField
              name="degree"
              value={selectedEducation.degree}
              label="Degree"
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputTextField
              name="note"
              value={selectedEducation.note}
              label="Notes"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputTextField
              name="period_of_study"
              value={selectedEducation.period_of_study}
              label="Period of Study"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogWrapper}>
        <Grid container justify="center" alignItems="center">
          <Button
            className={classes.dialogAction}
            onClick={setCloseEducationDetailsDialog}
            color="primary">
            Done
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
});
