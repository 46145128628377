import { useHistory } from 'react-router-dom';
import { InputCheckboxField, InputSelectField } from '@components';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Menu } from '@material-ui/icons';
import {
  AdminApplicationLayoutStore,
  ApplicationStore,
  UtilsStore,
} from '@stores';
import { observer } from 'mobx-react-lite';
import ApplicationNoteDialogStore from '../../services/ApplicationNoteDialogStore';
import { applicationStatusTypes } from '../../services/constants';
import { AdminApplicationItemStyles } from '../../services/styles';

export const ApplicationAppBar = observer(
  ({ approvedStatus, disabledApplicationStatuses }) => {
    const classes = AdminApplicationItemStyles();
    const { pageTitle } = AdminApplicationLayoutStore;
    const {
      verifiableApplication,
      disabledApprovedStatus,
      updateVerifiableApplication,
    } = ApplicationStore;
    const history = useHistory();
    const urlParams = new URLSearchParams(history.location.search);

    const disabledApplication = !verifiableApplication.is_submitted;

    const isDisableApplicationStatus = (fieldName) =>
      disabledApplication ||
      (disabledApplicationStatuses && !verifiableApplication[fieldName]);

    const backButtonHandler = () => {
      const tableFilters = JSON.parse(
        window.localStorage.getItem('TableFilters'),
      );

      if (tableFilters) {
        Object.keys(tableFilters).forEach((filterName) => {
          urlParams.set(filterName.toString(), tableFilters[filterName]);
        });
      }

      history.push({
        pathname: '/administration/application-list/',
        search: urlParams.toString(),
      });
    };

    const handleCheckboxChange = (event) => {
      const { name, checked } = event.target;

      if (name !== 'approved' && checked) {
        return handleApplicationNoteOpen(name);
      }

      ApplicationStore.updateVerifiableApplication({
        id: verifiableApplication.id,
        [name]: checked,
      });
    };

    const handleApplicationNoteOpen = (name) => {
      ApplicationNoteDialogStore.setOpenNoteDialog(
        name,
        applicationStatusTypes[name],
      );
    };

    const handleReviewedStatusChange = (name, value) => {
      updateVerifiableApplication({
        id: verifiableApplication.id,
        [name]: value,
      });
    };

    return (
      <AppBar
        className={approvedStatus ? classes.appBarSuccess : classes.appBar}>
        <Toolbar variant="dense" className={classes.toolbar}>
          <Typography variant="h6" noWrap>
            {pageTitle}
          </Typography>
          <InputSelectField
            flex
            disabled={disabledApplication}
            label="Reviewer Status"
            name="reviewed_status"
            value={verifiableApplication.reviewed_status}
            options={UtilsStore.reviewedStatuses}
            onClick={handleReviewedStatusChange}
          />
          <div className={classes.selectsControl}>
            <InputCheckboxField
              checked={verifiableApplication.on_hold}
              onChange={handleCheckboxChange}
              disabled={isDisableApplicationStatus('on_hold')}
              label="Hold"
              name="on_hold"
              onClickPencil={handleApplicationNoteOpen}
              showPencilBox
            />
            <InputCheckboxField
              checked={verifiableApplication.withdrawn}
              onChange={handleCheckboxChange}
              disabled={isDisableApplicationStatus('withdrawn')}
              label="Withdrawn"
              name="withdrawn"
              onClickPencil={handleApplicationNoteOpen}
              showPencilBox
            />
            <InputCheckboxField
              checked={verifiableApplication.rejected}
              onChange={handleCheckboxChange}
              disabled={isDisableApplicationStatus('rejected')}
              label="Rejected"
              name="rejected"
              onClickPencil={handleApplicationNoteOpen}
              showPencilBox
            />
            <InputCheckboxField
              checked={verifiableApplication.approved}
              onChange={handleCheckboxChange}
              disabled={
                isDisableApplicationStatus('approved') || disabledApprovedStatus
              }
              label="Approved"
              name="approved"
            />
          </div>
          <button onClick={backButtonHandler}>
            Applications <Menu />
          </button>
        </Toolbar>
      </AppBar>
    );
  },
);
