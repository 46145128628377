import { useEffect } from 'react';
import { InputTextField } from '@components';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { AdminApplicationLayoutStore, UtilsStore } from '@stores';
import { colors, mixins } from '@styles';
import { observer } from 'mobx-react-lite';
import ReferencesSectionStore from '../services/ReferencesSectionStore';

const useStyles = makeStyles({
  rootDialog: {
    padding: '0 20px',
  },
  toolbar: {
    ...mixins.flexToolBar,
  },
  dialogWrapper: {
    padding: '16px 0',
  },
  dialogActions: {
    background: colors.gray_01,
    textTransform: 'none',
    color: colors.black,
    margin: '18px 8px 0 0',
  },
  fieldLabel: {
    flexGrow: 1,
    fontWeight: 300,
    fontSize: 13,
    marginBottom: '5px',
  },
});

export const ReferencesDetailsDialog = observer(({ selectedReferences }) => {
  const { isMobile } = AdminApplicationLayoutStore;
  const classes = useStyles();
  const {
    showReferencesDetailsDialog,
    clearSelectedReferences,
    setCloseReferencesDetailsDialog,
  } = ReferencesSectionStore;

  useEffect(
    () => () => {
      clearSelectedReferences();
    },
    [clearSelectedReferences],
  );

  return (
    <Dialog
      className={classes.rootDialog}
      maxWidth="md"
      fullScreen={isMobile}
      open={showReferencesDetailsDialog}>
      <Toolbar className={classes.toolbar}>
        <Typography variant="h5">References</Typography>
      </Toolbar>
      <DialogContent>
        <Grid container spacing={2} justify="space-around" alignItems="center">
          <Grid item xs={12} md={3}>
            <InputTextField
              name="name_prefix"
              value={selectedReferences.address.name_prefix}
              label="Name Prefix"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputTextField
              name="email"
              value={selectedReferences.address.email}
              label="Email"
              required
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputTextField
              name="phone_type"
              value={UtilsStore.getPhoneTypeName(
                selectedReferences.address.phone_type,
              )}
              label="Phone Type"
              required
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputTextField
              name="phone_number"
              value={selectedReferences.address.phone_number}
              label="Phone Number"
              required
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputTextField
              name="title"
              value={selectedReferences.address.title}
              label="Title"
              required
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputTextField
              name="name_first"
              value={selectedReferences.address.name_first}
              label="First Name"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputTextField
              name="name_middle"
              value={selectedReferences.address.name_middle}
              label="Middle Name"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputTextField
              name="name_last"
              value={selectedReferences.address.name_last}
              label="Last Name"
              required
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputTextField
              name="name_suffix"
              value={selectedReferences.address.name_suffix}
              label="Name Suffix"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputTextField
              name="institution"
              value={
                selectedReferences.address.institution?.name ||
                selectedReferences.address.institution_other
              }
              label={
                selectedReferences.address.institution?.name
                  ? 'Institution'
                  : 'Institution Other'
              }
              required
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputTextField
              name="department"
              value={selectedReferences.address.department}
              label="Department"
              required
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputTextField
              name="hall"
              value={selectedReferences.address.hall}
              label="Hall"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputTextField
              name="country"
              value={UtilsStore.getCountryName(
                selectedReferences.address.country,
              )}
              label="Country"
              required
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputTextField
              name="address_line_1"
              value={selectedReferences.address.address_line_1}
              label="Address Line 1"
              required
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputTextField
              name="address_line_2"
              value={selectedReferences.address.address_line_2}
              label="Address Line 2"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputTextField
              name="city"
              value={selectedReferences.address.city}
              label="City"
              required
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputTextField
              name="state"
              value={selectedReferences.address.state}
              label="State"
              required
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputTextField
              name="zip"
              value={selectedReferences.address.zip}
              label="Zip"
              required
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogWrapper}>
        <Grid container justify="center" alignItems="center">
          <Button
            className={classes.dialogActions}
            onClick={setCloseReferencesDetailsDialog}>
            Done
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
});
