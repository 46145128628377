export const applicationStatusTypes = {
  on_hold: 'h',
  withdrawn: 'w',
  rejected: 'a',
};

export const tabsCard = [
  {
    fieldLabel: 'Personal Information',
    fieldName: 'user',
    handler: 'setOpenUserDialog',
  },
  {
    fieldLabel: 'Application Information',
    fieldName: 'info',
    handler: 'setOpenApplicationInfoDialog',
  },
  {
    fieldLabel: 'Addresses',
    fieldName: 'address',
    handler: 'setOpenAddressesDialog',
  },
  {
    fieldLabel: 'References',
    fieldName: 'reference',
    handler: 'setOpenReferencesDialog',
  },
  {
    fieldLabel: 'Positions',
    fieldName: 'position',
    handler: 'setOpenPositionsDialog',
  },
  {
    fieldLabel: 'Languages',
    fieldName: 'language',
    handler: 'setOpenLanguagesDialog',
  },
  {
    fieldLabel: 'Organizations',
    fieldName: 'organization',
    handler: 'setOpenOrganizationsDialog',
  },
  {
    fieldLabel: 'Educational Summary',
    fieldName: 'education',
    handler: 'setOpenEducationDialog',
  },
  {
    fieldLabel: 'Supporting Documents',
    fieldName: 'document',
    handler: 'setOpenDocumentsDialog',
  },
];

export const gender = [
  {
    value: 'm',
    display_name: 'Male',
  },
  {
    value: 'f',
    display_name: 'Female',
  },
  {
    value: 'o',
    display_name: 'Other',
  },
];

export const sectionsTypes = {
  addresses: 'a',
  application: 'i',
  documents: 'd',
  education: 'e',
  languages: 'l',
  organizations: 'o',
  positions: 'p',
  references: 'r',
  user: 'u',
};

export const sectionsNames = {
  addresses: 'addresses',
  application: 'info',
  documents: 'documents',
  education: 'education',
  languages: 'languages',
  organizations: 'organizations',
  positions: 'positions',
  references: 'references',
  user: 'user',
};
