import { useEffect, useState } from 'react';
import {
  HelpInfo,
  InstitutionDialog,
  NonFieldErrors,
  Select,
  TextField,
} from '@components';
import { CircularProgress } from '@material-ui/core';
import { ErrorStore, SectionsStore, UtilsStore } from '@stores';
import { generateErrorMessages } from '@utils';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import { v4 } from 'uuid';
import * as Yup from 'yup';
import { EducationStore } from '../services';

const EducationalSummaryForm = observer(({ fields, submitHandler }) => {
  const [showInstitution, setShowInstitution] = useState(true);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      institution: fields?.institution?.id ?? '',
      institutionOther: fields?.institution_other ?? '',
      level: fields?.level ?? '',
      degree: fields?.degree ?? '',
      notes: fields?.note ?? '',
      periodOfStudy: fields?.period_of_study ?? '',
      institutionType: 'I',
      showOther: false,
    },
    validationSchema: Yup.object({
      institutionOther: Yup.string().when('showOther', {
        is: true,
        then: Yup.string().required('Required'),
      }),
      institution: Yup.string().when('institutionOther', {
        is: (val) => val !== '',
        then: Yup.string(),
        otherwise: Yup.string().required('Required'),
      }),
      level: Yup.string().required('Required'),
      degree: Yup.string().required('Required'),
      periodOfStudy: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      setLoading(true);

      try {
        if (fields) {
          await EducationStore.updateApplicationEducation(values, fields.id);
        } else {
          await EducationStore.createApplicationEducation(values);
        }
        await EducationStore.unacknowledgeApplicationEducationsSection();
        await SectionsStore.getApplicationSectionsStatuses();
      } finally {
        setLoading(false);
        submitHandler();
      }
    },
  });

  useEffect(() => {
    async function fetchData() {
      await UtilsStore.getInstitutions();
      await EducationStore.getApplicationEducationsOptions();
    }

    fetchData();
  }, []);

  const renderInstitutionValue = () => {
    const currentValue = UtilsStore.institutions.filter(
      (item) => item.id === formik.values.institution,
    );

    return currentValue?.map((item) => (
      <option key={v4()} value={item.id}>
        {item.name}
      </option>
    ));
  };

  const renderInstitutionOther = () => {
    if (!formik.values.showOther && !formik.values.institutionOther)
      return null;

    return (
      <div className="px-2 py-2">
        <TextField
          label="Institution Other"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.institutionOther}
          id="institutionOther"
          errors={generateErrorMessages(
            'institutionOther',
            formik,
            ErrorStore,
            'institution_other',
          )}
        />
      </div>
    );
  };

  const onSave = (value) => {
    if (value.institution) {
      formik.setFieldValue('institution', value.institution.id, true);
      formik.setFieldValue('institutionOther', '', true);
      setShowInstitution(true);
      formik.setFieldValue('showOther', false);
    } else {
      formik.setFieldValue('institutionOther', value.institutionOther, true);
      formik.setFieldValue('institution', '', true);
      setShowInstitution(false);
      formik.setFieldValue('showOther', true);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <h2 className="text-black text-2xl px-2">
        {fields ? 'Edit Education' : 'Add Education'}&nbsp;
        <HelpInfo metaTextName="application-education" />
      </h2>

      <div className="px-2 py-2">
        <label
          htmlFor="institution"
          className="text-gray-700 dark:text-gray-200">
          Institution <span className="text-red-600">*</span>&nbsp;
        </label>

        {showInstitution && !formik.values.institutionOther ? (
          <select
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.institution}
            id="institution"
            disabled
            className="
            appearance-none
            block
            w-full
            px-4
            py-2
            mt-2
            text-gray-700
            bg-white
            border
            rounded-md
            focus:outline-none
            focus:ring
            border-gray-300
            focus:border-blue-300
          ">
            <option value="" disabled defaultValue></option>
            {formik.values.institution ? renderInstitutionValue() : null}
          </select>
        ) : null}
        <div className="flex py-2">
          <InstitutionDialog onSave={onSave} />
          <p className="text-red-400 text-xs ml-1 px-2">
            No institutional affiliation? Click (?) Help above for further
            instructions.
          </p>
        </div>
        {formik.touched.institution && formik.errors.institution ? (
          <div className="text-red-500 py-1">{formik.errors.institution}</div>
        ) : null}
        {ErrorStore.errors?.institution
          ? ErrorStore.errors.institution.map((item) => (
              <div key={v4()} className="text-red-500 py-1">
                {item}
              </div>
            ))
          : null}
      </div>
      {renderInstitutionOther()}
      <div className="px-2 py-2">
        <Select
          label={
            <label htmlFor="level" className="text-gray-700 dark:text-gray-200">
              Level <span className="text-red-600">*</span>
            </label>
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.level}
          id="level"
          defaultEmptyValue
          itemName="display_name"
          itemValue="value"
          items={EducationStore.options.actions.POST.level.choices}
          errors={generateErrorMessages('level', formik, ErrorStore, 'level')}
        />
      </div>
      <div className="px-2 py-2">
        <TextField
          label={
            <label
              htmlFor="degree"
              className="text-gray-700 dark:text-gray-200">
              Degree <span className="text-red-600">*</span>
            </label>
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.degree}
          id="degree"
          errors={generateErrorMessages('degree', formik, ErrorStore, 'degree')}
        />
      </div>
      <div className="px-2 py-2">
        <TextField
          label="Notes"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.notes}
          id="notes"
          errors={generateErrorMessages('notes', formik, ErrorStore, 'notes')}
        />
      </div>
      <div className="px-2 py-2">
        <TextField
          label={
            <label
              htmlFor="periodOfStudy"
              className="text-gray-700 dark:text-gray-200">
              Period of Study <span className="text-red-600">*</span>
            </label>
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.periodOfStudy}
          id="periodOfStudy"
          errors={generateErrorMessages(
            'periodOfStudy',
            formik,
            ErrorStore,
            'period_of_study',
          )}
        />
      </div>
      <NonFieldErrors />
      <div className="flex flex-col md:flex-row justify-center mt-2">
        <button
          disabled={loading}
          onClick={submitHandler}
          type="button"
          className="
                          w-max
                          md:mx-2
                          px-8
                          py-3
                          text-xl
                          bg-gray-400
                          text-black
                          rounded-md
                          text-center
                        ">
          Cancel
        </button>
        <button
          type="submit"
          disabled={loading}
          className="
                          w-max
                          md:mx-2
                          px-8
                          py-3
                          text-xl
                          bg-primary
                          text-white
                          rounded-md
                          text-center
                        ">
          {loading ? <CircularProgress color="white" /> : 'Save'}
        </button>
      </div>
    </form>
  );
});

export default EducationalSummaryForm;
