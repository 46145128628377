import { useState } from 'react';
import { DialogSmartButtons, WarningDialog } from '@components';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
} from '@material-ui/core';
import { ApplicationService } from '@services';
import { AdminApplicationLayoutStore, ApplicationStore } from '@stores';
import { observer } from 'mobx-react-lite';
import ApplicationNoteDialogStore from '../../services/ApplicationNoteDialogStore';

const useStyles = makeStyles(() => ({
  dialogRoot: {
    minWidth: '850px',
  },
  actionsWrapper: {
    padding: '16px 0',
  },
}));

export const ApplicationNoteContent = observer(({ applicationId }) => {
  const classes = useStyles();
  const { isMobile } = AdminApplicationLayoutStore;
  const { verifiableApplication } = ApplicationStore;
  const {
    showApplicationNoteDialog,
    setCloseNoteDialog,
    statusName,
    applicationStatusNote,
    createApplicationNote,
  } = ApplicationNoteDialogStore;
  const [inputData, setInputData] = useState('');
  const [savingData, setSavingData] = useState(false);
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const createdApplicationStatusNote = applicationStatusNote?.id;
  const disabledSection = !verifiableApplication.is_submitted;

  const handleWarningDialogSubmit = async () => {
    await ApplicationStore.updateVerifiableApplication({
      id: verifiableApplication.id,
      is_editable: false,
    });
    await handleSave();
  };

  const handleSave = async () => {
    setShowWarningDialog(false);

    setSavingData(true);
    await ApplicationService.patchApplication({
      id: verifiableApplication.id,
      [statusName]: true,
    })
      .then(({ data }) => {
        ApplicationStore.getVerifiableApplication({ id: data.id });
        if (createdApplicationStatusNote) {
          applicationStatusNote.update();
        } else {
          createApplicationNote(inputData, applicationId);
        }
      })
      .finally(() => {
        setSavingData(false);
      });
    setCloseNoteDialog();
  };

  const handleInputFieldChange = (event) => {
    const { value } = event.target;

    if (createdApplicationStatusNote) {
      return applicationStatusNote.updateNote(value);
    }

    return setInputData(value);
  };

  const handleApplicationNoteSave = () => {
    if (verifiableApplication.is_editable) {
      return setShowWarningDialog(true);
    }

    return handleSave();
  };

  return (
    <>
      {showWarningDialog ? (
        <WarningDialog
          open={showWarningDialog}
          onClose={handleSave}
          onSave={handleWarningDialogSubmit}
          title="The applicant currently has access to edit their application. Continuing will block that access."
          submitButtonText="Continue"
        />
      ) : null}
      <Dialog
        className={classes.dialogRoot}
        open={showApplicationNoteDialog}
        fullScreen={isMobile}
        scroll="paper"
        maxWidth="md">
        <DialogTitle>Enter a note regarding this action</DialogTitle>
        <DialogContent className={classes.dialogRoot}>
          <TextField
            multiline
            fullWidth
            rows={10}
            value={
              applicationStatusNote ? applicationStatusNote.note : inputData
            }
            variant="outlined"
            onChange={handleInputFieldChange}
          />
        </DialogContent>
        <DialogActions className={classes.actionsWrapper}>
          <DialogSmartButtons
            disabled={disabledSection}
            loading={savingData}
            onClose={setCloseNoteDialog}
            onSave={handleApplicationNoteSave}
          />
        </DialogActions>
      </Dialog>
    </>
  );
});
