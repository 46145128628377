import { useEffect } from 'react';
import { Loader, NoDataEntered } from '@components';
import {
  AdminApplicationLayoutStore,
  ApplicationStore,
  UtilsStore,
} from '@stores';
import { observer } from 'mobx-react-lite';
import { SectionsWrapper } from '../../components';
import { sectionsNames, sectionsTypes } from '../../services/constants';
import SectionNoteDialogStore from '../../services/SectionNoteDialogStore';
import SectionStatusesStore from '../../services/SectionStatusesStore';
import { ReferencesDetailsDialog } from './components/ReferencesDetailsDialog';
import { ReferencesSectionContent } from './components/ReferencesSectionContent';
import ReferencesSectionStore from './services/ReferencesSectionStore';

export const ReferencesSectionScene = observer(() => {
  useEffect(() => {
    const applicationId = ApplicationStore.verifiableApplication.id;
    ReferencesSectionStore.getReferences(applicationId);
    SectionStatusesStore.getSectionStatuses(
      applicationId,
      sectionsNames.references,
    );
    SectionNoteDialogStore.getSectionNote(
      sectionsTypes.references,
      applicationId,
    );
    UtilsStore.getPhoneTypes({
      id: applicationId,
      section: sectionsNames.references,
    });

    return () => {
      SectionStatusesStore.clear();
      ReferencesSectionStore.clear();
      SectionNoteDialogStore.clear();
    };
  }, []);

  const renderContent = () => {
    if (
      ReferencesSectionStore.showReferencesDetailsDialog &&
      ReferencesSectionStore.selectedReferences
    ) {
      return (
        <ReferencesDetailsDialog
          selectedReferences={ReferencesSectionStore.selectedReferences}
        />
      );
    }

    return (
      <SectionsWrapper
        sectionTitle="References"
        sectionName="reference"
        sectionFullName={sectionsNames.references}
        status={SectionStatusesStore.sectionStatuses}
        onCloseSection={AdminApplicationLayoutStore.setCloseReferencesDialog}
        open={AdminApplicationLayoutStore.showReferencesDialog}>
        {ReferencesSectionStore.references.length > 0 ? (
          <ReferencesSectionContent
            references={ReferencesSectionStore.references}
          />
        ) : (
          <NoDataEntered />
        )}
      </SectionsWrapper>
    );
  };

  return (
    <>
      {ReferencesSectionStore.references &&
      SectionStatusesStore.sectionStatuses &&
      UtilsStore.phoneTypes ? (
        renderContent()
      ) : (
        <Loader message="Loading References Section..." />
      )}
    </>
  );
});
