import ApplicationLink from './ApplicationLink';
import JurorsLink from './JurorsLink';
import ReferencesLink from './ReferencesLink';
import WorkExamplesLink from './WorkExamplesLink';
import './styles.scss';

const UserNavbar = () => (
  <ul className="user-navbar">
    <ApplicationLink />
    <WorkExamplesLink />
    <ReferencesLink />
    <JurorsLink />
  </ul>
);
export default UserNavbar;
