import { useEffect, useState } from 'react';
import { NonFieldErrors, Select, TextField } from '@components';
import { CircularProgress } from '@material-ui/core';
import { ErrorStore, SectionsStore, UtilsStore } from '@stores';
import { generateErrorMessages } from '@utils';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import * as Yup from 'yup';
import { InfoStore } from '../services';

const InfoForm = observer(({ fields, submitHandler }) => {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      projectTitle: fields?.project_title ?? '',
      projectField: fields?.project_field_1?.id ?? '',
      jointApplicantName: fields?.joint_applicant_name ?? '',
      projectFieldAdditional: fields?.project_field_2?.id ?? '',
      jointApplicantEmail: fields?.joint_applicant_email ?? '',
      website1: fields?.website_1 ?? '',
      website2: fields?.website_2 ?? '',
      previouslyAppliedName: fields?.previous_applied_name ?? '',
      previouslyApplied: fields?.previously_applied ?? false,
    },
    validationSchema: Yup.object({
      projectTitle: Yup.string().required('Required'),
      projectField: Yup.string().required('Required'),
      jointApplicantEmail: Yup.string().email('Invalid email address'),
      website1: Yup.string().url(),
      website2: Yup.string().url(),
    }),
    onSubmit: async (values) => {
      setLoading(true);

      try {
        if (fields) {
          await InfoStore.updateApplicationInfo(values, fields.id);
        } else {
          await InfoStore.createApplicationInfo(values);
        }
        await InfoStore.unacknowledgeApplicationInfoSection();
        await SectionsStore.getApplicationSectionsStatuses();
      } finally {
        setLoading(false);
        submitHandler();
      }
    },
  });

  useEffect(() => {
    async function fetchData() {
      await UtilsStore.getGroupsets();
    }

    fetchData();
    ErrorStore.dropErrors();
  }, []);

  return (
    <form onSubmit={formik.handleSubmit}>
      <h2 className="text-black text-2xl px-2">
        {fields ? 'Edit Info' : 'Add Info'}
      </h2>
      <div className="px-2 py-2">
        <TextField
          label={
            <label
              htmlFor="projectTitle"
              className="text-gray-700 dark:text-gray-200">
              Project Title <span className="text-red-600">*</span>
            </label>
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.projectTitle}
          id="projectTitle"
          errors={generateErrorMessages(
            'projectTitle',
            formik,
            ErrorStore,
            'project_title',
          )}
        />
      </div>
      <div className="flex flex-row flex-wrap">
        <div className="w-1/2 px-2 py-2">
          <Select
            label={
              <label
                htmlFor="projectField"
                className="text-gray-700 dark:text-gray-200">
                Project Field <span className="text-red-600">*</span>
              </label>
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.projectField}
            id="projectField"
            defaultEmptyValue
            itemName="name"
            itemValue="id"
            items={UtilsStore.groupsets}
            errors={generateErrorMessages(
              'projectField',
              formik,
              ErrorStore,
              'project_field_1',
            )}
          />
        </div>
        <div className="w-1/2 px-2 py-2">
          <TextField
            label="Joint Applicant Name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.jointApplicantName}
            id="jointApplicantName"
            errors={generateErrorMessages(
              'jointApplicantName',
              formik,
              ErrorStore,
              'joint_applicant_name',
            )}
          />
        </div>
        <div className="w-1/2 px-2 py-2">
          <Select
            label="Project Field, additional"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.projectFieldAdditional}
            id="projectFieldAdditional"
            defaultEmptyValue
            itemName="name"
            itemValue="id"
            items={UtilsStore.groupsets}
            errors={generateErrorMessages(
              'projectFieldAdditional',
              formik,
              ErrorStore,
              'project_field_2',
            )}
          />
          <div className="text-gray-400 mt-1">
            Enter the fields that your project is associated with. For example
            Music or Photography.
          </div>
        </div>
        <div className="w-1/2 px-2 py-2">
          <TextField
            label="Joint Applicant Email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.jointApplicantEmail}
            id="jointApplicantEmail"
            errors={generateErrorMessages(
              'jointApplicantEmail',
              formik,
              ErrorStore,
              'joint_applicant_email',
            )}
          />
        </div>
      </div>
      <div className="flex flex-col">
        <div className="w-1/2 px-2 py-2">
          <label className="text-black" htmlFor="previouslyApplied">
            <input
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.previouslyApplied}
              checked={formik.values.previouslyApplied}
              id="previouslyApplied"
              type="checkbox"
              className="border-gray-300"
            />
            <span className="pl-1 text-black font-medium">
              I have previously applied to the GF Competition.
            </span>
            {formik.touched.previouslyApplied &&
            formik.errors.previouslyApplied ? (
              <div className="text-red-500 py-1">
                {formik.errors.previouslyApplied}
              </div>
            ) : null}
          </label>
        </div>
        <div className="w-1/2 px-2 py-2">
          <TextField
            label="Previously applied name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.previouslyAppliedName}
            id="previouslyAppliedName"
            errors={generateErrorMessages(
              'previouslyAppliedName',
              formik,
              ErrorStore,
              'previously_applied_name',
            )}
          />
        </div>
      </div>
      <div className="py-2 px-2">
        <label className="text-gray-700 dark:text-gray-200">
          Websites &nbsp;&nbsp;&nbsp; Enter using the full format:
          https://www.website.com
        </label>
        <div className="flex flex-row justify-between">
          <TextField
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.website1}
            id="website1"
            errors={generateErrorMessages(
              'website1',
              formik,
              ErrorStore,
              'website_1',
            )}
          />
          {formik.values.website1 ? (
            <button
              type="button"
              onClick={() => {
                window.open(formik.values.website1, '_blank');
              }}
              className="text-black font-bold underline w-1/5 my-auto">
              Preview URL
            </button>
          ) : null}
        </div>
        <div className="flex flex-row justify-between">
          <TextField
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.website2}
            id="website2"
            errors={generateErrorMessages(
              'website2',
              formik,
              ErrorStore,
              'website_2',
            )}
          />
          {formik.values.website2 ? (
            <button
              type="button"
              onClick={() => {
                window.open(formik.values.website2, '_blank');
              }}
              className="text-black font-bold underline w-1/5 my-auto">
              Preview URL
            </button>
          ) : null}
        </div>
      </div>
      <NonFieldErrors />
      <div className="flex flex-col md:flex-row justify-center mt-2">
        <button
          disabled={loading}
          onClick={submitHandler}
          type="button"
          className="
                          w-max
                          md:mx-2
                          px-8
                          py-3
                          text-xl
                          bg-gray-400
                          text-black
                          rounded-md
                          text-center
                        ">
          Cancel
        </button>
        <button
          type="submit"
          disabled={loading}
          className="
                          w-max
                          md:mx-2
                          px-8
                          py-3
                          text-xl
                          bg-primary
                          text-white
                          rounded-md
                          text-center
                        ">
          {loading ? <CircularProgress color="white" /> : 'Save'}
        </button>
      </div>
    </form>
  );
});

export default InfoForm;
