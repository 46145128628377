export const referenceColumns = [
  {
    id: 'view',
    label: '-',
    sortable: false,
  },
  {
    id: 'reference_name',
    label: 'Reference',
    sortable: true,
  },
  {
    id: 'applicant_name',
    label: 'Applicant',
    sortable: true,
  },
  {
    id: 'requested',
    label: 'Requested',
    sortable: false,
  },
  {
    id: 'received',
    label: 'Received',
    sortable: false,
  },
  {
    id: 'reviewed',
    label: 'Reviewed',
    sortable: false,
  },
  {
    id: 'revision_requested',
    label: 'Revision Requested',
    sortable: false,
  },
  {
    id: 'revision_received',
    label: 'Revision Received',
    sortable: false,
  },
  {
    id: 'approved',
    label: 'Approved',
    sortable: false,
  },
  {
    id: 'display_status',
    label: 'Status',
    sortable: true,
  },
];

export const referenceFilterFields = [
  {
    filterName: 'reference_name',
    label: 'Reference Name',
    type: 'string',
  },
  {
    filterName: 'applicant_name',
    label: 'Applicant Name',
    type: 'string',
  },
];

export const referenceDisplayStatuses = [
  { name: 'Unmatched' },
  { name: 'Matched' },
  { name: 'Requested' },
  { name: 'Declined' },
  { name: 'Reviewed' },
  { name: 'Approved' },
  { name: 'Letter Received' },
  { name: 'Revision Requested' },
  { name: 'Revision Received' },
];
