import withStyles from '@material-ui/core/styles/withStyles';
import TableCell from '@material-ui/core/TableCell';
import { fontSizes } from '@styles';
import { DisplayStatus } from '../../../scenes/AdminApplications/services/constants';

const StyledTableCell = withStyles(() => ({
  body: {
    borderRadius: '2px',
    padding: '0 9px',
    fontSize: fontSizes.font_size_s,
  },
}))(TableCell);

export const StatusCell = ({ status }) => (
  <>
    {DisplayStatus.map((statusItem) => {
      if (statusItem.fieldName === status) {
        return (
          <StyledTableCell
            key={statusItem.fieldName}
            align="left"
            style={{ background: statusItem.fieldColor }}>
            {statusItem.fieldName}
          </StyledTableCell>
        );
      }
      return null;
    })}
  </>
);
