import {
  plainRequestPrivateResolver,
  privateResolverWithPayload,
} from '@services';
import { makeAutoObservable } from 'mobx';
import { Reference } from '../models/Reference';
import { ReferencesService } from './references.service';

class ReferencesStore {
  constructor() {
    this.referenceList = null;
    this.verifiableReference = null;
    this.showReferenceDetailsDialog = false;
    this.reviewedStatusOptions = null;
    this.declinedReasonOptions = null;
    this.totalCount = 0;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setOpenReferenceDetailsDialog(reference) {
    this.verifiableReference = reference;
    this.showReferenceDetailsDialog = true;
  }

  setCloseReferenceDetailsDialog() {
    this.showReferenceDetailsDialog = false;
  }

  clearDetails() {
    this.verifiableReference = null;
  }

  clear() {
    this.referenceList = null;
  }

  setReferencesList(referencesParams) {
    this.totalCount = referencesParams.count;
    this.referenceList = referencesParams.results.map(
      (referenceItem) => new Reference(referenceItem),
    );
  }

  setReferenceOptions(options) {
    this.reviewedStatusOptions = options.actions.POST.reviewed_status.choices;
    this.declinedReasonOptions = options.actions.POST.declined_reason.choices;
  }

  getDeclineReason(value) {
    return this.declinedReasonOptions.find((reason) => reason.value === value)
      ?.display_name;
  }

  getReferenceOptions() {
    plainRequestPrivateResolver({
      fetcher: ReferencesService.fetchReviewedStatusOptions,
      setter: this.setReferenceOptions,
    });
  }

  getReferencesList(payload) {
    privateResolverWithPayload({
      fetcher: ReferencesService.fetchReferences,
      payload: payload,
      setter: this.setReferencesList,
      progress: true,
    });
  }
}

export default new ReferencesStore();
