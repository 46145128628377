import { http } from '@utils';

export default class CompetitionService {
  static getCurrentCompetition() {
    return http.get('/api/competitions/current/');
  }

  static getCompetitions() {
    return http.get('/api/competitions/');
  }
}
