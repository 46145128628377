import { useEffect } from 'react';
import { UserMenu } from '@components';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { AdminLayoutStore, CompetitionStore, UserStore } from '@stores';
import { colors, miscVariables, spacing, useIsMobile } from '@styles';
import { observer } from 'mobx-react-lite';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  appBar: {
    boxShadow: 'none',
    zIndex: 1,
    height: miscVariables.app_bar_height,
    background: colors.blue_dark,
  },
  toolBarTitle: {
    flexGrow: 1,
  },
  contentWrapper: {
    position: 'relative',
    marginTop: '65px',
  },
  userName: {
    marginRight: spacing.spacing_mdl,
  },
});

export const AdminLayout = observer(({ children }) => {
  const classes = useStyles();
  const isMobile = useIsMobile();

  useEffect(() => {
    AdminLayoutStore.setMobile(isMobile);
    CompetitionStore.getCompetitions();
  }, [isMobile]);

  return (
    <>
      <AppBar className={classes.appBar}>
        <Toolbar variant="dense">
          <Typography className={classes.toolBarTitle} variant="h6" noWrap>
            {AdminLayoutStore.pageTitle}
          </Typography>
          <p className={classes.userName}>
            {UserStore.user.first_name} {UserStore.user.last_name}
          </p>
          {UserStore.user && AdminLayoutStore.showUserMenu ? (
            <UserMenu />
          ) : null}
        </Toolbar>
      </AppBar>
      <Grid container className={classes.contentWrapper}>
        {children}
      </Grid>
    </>
  );
});
