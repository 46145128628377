import { ErrorStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { v4 } from 'uuid';

const NonFieldErrors = observer(() =>
  ErrorStore.errors?.non_field_errors
    ? ErrorStore.errors.non_field_errors.map((item) => (
        <div key={v4()} className="text-red-500 py-1 text-center md:text-lg">
          {item}
        </div>
      ))
    : null,
);

export default NonFieldErrors;
