import { useEffect } from 'react';
import { Loader, NoDataEntered } from '@components';
import { AdminApplicationLayoutStore, ApplicationStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { SectionsWrapper } from '../../components';
import { sectionsNames, sectionsTypes } from '../../services/constants';
import SectionNoteDialogStore from '../../services/SectionNoteDialogStore';
import SectionStatusesStore from '../../services/SectionStatusesStore';
import { LanguagesTable } from './components';
import LanguagesSectionStore from './services/LanguagesSectionStore';

export const LanguagesSectionScene = observer(() => {
  useEffect(() => {
    const applicationId = ApplicationStore.verifiableApplication.id;
    LanguagesSectionStore.getLanguages(applicationId);
    SectionStatusesStore.getSectionStatuses(
      applicationId,
      sectionsNames.languages,
    );
    LanguagesSectionStore.getLanguageRatings(applicationId);
    SectionNoteDialogStore.getSectionNote(
      sectionsTypes.languages,
      applicationId,
    );

    return () => {
      SectionStatusesStore.clear();
      LanguagesSectionStore.clear();
      SectionNoteDialogStore.clear();
    };
  }, []);

  return (
    <>
      {LanguagesSectionStore.languages &&
      SectionStatusesStore.sectionStatuses &&
      LanguagesSectionStore.languageRatingList ? (
        <SectionsWrapper
          sectionTitle="Languages"
          sectionName="language"
          sectionFullName={sectionsNames.languages}
          status={SectionStatusesStore.sectionStatuses}
          onCloseSection={AdminApplicationLayoutStore.setCloseLanguagesDialog}
          open={AdminApplicationLayoutStore.showLanguagesDialog}>
          {LanguagesSectionStore.languages.length > 0 ? (
            <LanguagesTable languages={LanguagesSectionStore.languages} />
          ) : (
            <NoDataEntered />
          )}
        </SectionsWrapper>
      ) : (
        <Loader message="Loading Languages Section..." />
      )}
    </>
  );
});
