import { useEffect, useState } from 'react';
import {
  NonFieldErrors,
  HelpInfo,
  InstitutionDialog,
  TextField,
} from '@components';
import { CircularProgress } from '@material-ui/core';
import { ErrorStore, UtilsStore, SectionsStore } from '@stores';
import { generateErrorMessages } from '@utils';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import { v4 } from 'uuid';
import * as Yup from 'yup';
import { PositionsStore } from '../services';

const PositionsForm = observer(({ fields, submitHandler }) => {
  const [showInstitution, setShowInstitution] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      await UtilsStore.getInstitutions();
    }

    void fetchData();
    ErrorStore.dropErrors();
  }, []);

  const schema = Yup.object({
    positionTitle: Yup.string().required('Required'),
    startAndEndDates: Yup.string().required('Required'),
    institutionOther: Yup.string().when('showOther', {
      is: true,
      then: Yup.string().required('Required'),
    }),
    institution: Yup.string().when('institutionOther', {
      is: (val) => Boolean(val) !== false,
      then: Yup.string(),
      otherwise: Yup.string().required('Required'),
    }),
  });

  const values = {
    positionTitle: fields?.position_title ?? '',
    institution: fields?.institution?.id ?? '',
    institutionOther: fields?.institution_other ?? '',
    department: fields?.department ?? '',
    startAndEndDates: fields?.start_and_end_dates ?? '',
    institutionType: 'I',
    showOther: false,
  };

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: values,
    validationSchema: schema,
    onSubmit: async (values) => {
      setLoading(true);

      try {
        if (fields) {
          await PositionsStore.updateApplicationPosition(values, fields.id);
        } else {
          await PositionsStore.createApplicationPosition(values);
        }
        await PositionsStore.unacknowledgeApplicationPositionsSection();
        await SectionsStore.getApplicationSectionsStatuses();
      } finally {
        setLoading(false);
        submitHandler();
      }
    },
  });

  const renderInstitutionValue = () => {
    const currentValue = UtilsStore.institutions.filter(
      (item) => item.id === formik.values.institution,
    );

    return currentValue?.map((item) => (
      <option key={v4()} value={item.id}>
        {item.name}
      </option>
    ));
  };

  const renderInstitutionOther = () => {
    if (!formik.values.showOther && !formik.values.institutionOther)
      return null;

    return (
      <div className="px-2 py-2">
        <TextField
          label="Institution Other"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.institutionOther}
          id="institutionOther"
          errors={generateErrorMessages(
            'institutionOther',
            formik,
            ErrorStore,
            'institution_other',
          )}
        />
      </div>
    );
  };

  const onSave = (value) => {
    if (value.institution) {
      formik.setFieldValue('institution', value.institution.id);
      formik.setFieldValue('institutionOther', '');
      setShowInstitution(true);
      formik.setFieldValue('showOther', false);
    } else {
      formik.setFieldValue('institutionOther', value.institutionOther);
      formik.setFieldValue('institution', '');
      setShowInstitution(false);
      formik.setFieldValue('showOther', true);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <h2 className="text-black text-2xl px-2">
        {fields ? 'Edit Position' : 'Add Position'}
        <HelpInfo metaTextName="application-positions" />
      </h2>
      <div className="px-2 py-2">
        <label
          htmlFor="institution"
          className="text-gray-700 dark:text-gray-200">
          Institution / Company <span className="text-red-600">*</span>&nbsp;
        </label>

        {showInstitution && !formik.values.institutionOther ? (
          <select
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.institution}
            id="institution"
            disabled
            className="
            appearance-none
            block
            w-full
            px-4
            py-2
            mt-2
            text-gray-700
            bg-white
            border
            rounded-md
            focus:outline-none
            focus:ring
            border-gray-300
            focus:border-blue-300
          ">
            <option value="" disabled defaultValue></option>
            {formik.values.institution ? renderInstitutionValue() : null}
          </select>
        ) : null}
        <div className="flex py-2">
          <InstitutionDialog onSave={onSave} />
          <p className="text-red-400 text-xs ml-1 px-2">
            No institutional affiliation? Click (?) Help above for further
            instructions.
          </p>
        </div>
        {formik.touched.institution && formik.errors.institution ? (
          <div className="text-red-500 py-1">{formik.errors.institution}</div>
        ) : null}
        {ErrorStore.errors?.institution
          ? ErrorStore.errors.institution.map((item) => (
              <div key={v4()} className="text-red-500 py-1">
                {item}
              </div>
            ))
          : null}
      </div>
      {renderInstitutionOther()}
      <div className="px-2 py-2">
        <TextField
          label={
            <label
              htmlFor="positionTitle"
              className="text-gray-700 dark:text-gray-200">
              Position / Title <span className="text-red-600">*</span>
            </label>
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.positionTitle}
          id="positionTitle"
          errors={generateErrorMessages(
            'positionTitle',
            formik,
            ErrorStore,
            'position_title',
          )}
        />
      </div>
      <div className="px-2 py-2">
        <TextField
          label="Department"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.department}
          id="department"
          errors={generateErrorMessages(
            'department',
            formik,
            ErrorStore,
            'department',
          )}
        />
      </div>
      <div className="px-2 py-2">
        <TextField
          label={
            <label
              htmlFor="startAndEndDates"
              className="text-gray-700 dark:text-gray-200">
              Dates (start/end) <span className="text-red-600">*</span>
            </label>
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.startAndEndDates}
          id="startAndEndDates"
          errors={generateErrorMessages(
            'startAndEndDates',
            formik,
            ErrorStore,
            'start_and_end_dates',
          )}
        />
      </div>
      <NonFieldErrors />
      <div className="flex flex-col md:flex-row justify-center mt-2">
        <button
          disabled={loading}
          onClick={submitHandler}
          type="button"
          className="
                          w-max
                          md:mx-2
                          px-8
                          py-3
                          text-xl
                          bg-gray-400
                          text-black
                          rounded-md
                          text-center
                        ">
          Cancel
        </button>
        <button
          type="submit"
          disabled={loading}
          className="
                          w-max
                          md:mx-2
                          px-8
                          py-3
                          text-xl
                          bg-primary
                          text-white
                          rounded-md
                          text-center
                        ">
          {loading ? <CircularProgress color="white" /> : 'Save'}
        </button>
      </div>
    </form>
  );
});

export default PositionsForm;
