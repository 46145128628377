import { makeAutoObservable } from 'mobx';

export class Reference {
  constructor(references) {
    this.id = references.id;
    this.fm_id = references.fm_id;
    this.application = references.application;
    this.address = references.address;
    this.matched_user = references.matched_user;
    this.date_created = references.date_created;
    this.date_last_modified = references.date_last_modified;
    this.document = references.document;
    this.date_reference_requested = references.date_reference_requested;
    this.date_file_submitted = references.date_file_submitted;
    this.reference_declined = references.reference_declined;
    this.declined_reason = references.declined_reason;
    this.matched_user_substituted = references.matched_user_substituted;
    this.active = references.active;
    makeAutoObservable(this, {}, { autoBind: true });
  }
}
