import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { colors, fontSizes, spacing } from '@styles';

const useStyles = makeStyles({
  mainCardContent: {
    padding: '14px',
    border: `1px solid ${colors.black}`,
  },
  processingButton: {
    fontWeight: 'bold',
    fontSize: fontSizes.font_size_ml,
    outline: 'none',
    margin: '0',
    textTransform: 'none',
  },
  processingContent: {
    paddingLeft: spacing.spacing_lg,
    marginTop: '8px',
  },
  overviewsContent: {
    padding: '18px 0px 0px 30px',
  },
});

export const MainCardContent = ({ currentCompetition }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleApplicationProcessingClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    history.push('/administration/application-list/');
  };

  const handleReferenceLetterProcessingClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    history.push('/administration/reference-list');
  };

  return (
    <Grid
      container
      className={classes.mainCardContent}
      justify="space-between"
      alignItems="flex-start">
      <Grid item xs={12} md={7}>
        <Typography variant="h5" display="inline">
          Competition Processing &nbsp;
        </Typography>
        <Typography display="inline" variant="h6">
          {currentCompetition.name}
        </Typography>
        <Grid
          container
          className={classes.processingContent}
          spacing={2}
          justify="flex-end"
          direction="column"
          alignItems="flex-start">
          <Grid item xs={12}>
            <Button
              onClick={handleApplicationProcessingClick}
              size="small"
              className={classes.processingButton}>
              Application Processing
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              onClick={handleReferenceLetterProcessingClick}
              size="small"
              className={classes.processingButton}>
              Reference Letter Processing
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
