import { http } from '@utils';

export default class NotesService {
  static createNoteItem(payload) {
    return http.post(`/api/applications/${payload.application}/notes/`, {
      ...payload,
    });
  }

  static async fetchSectionTypes(id) {
    const response = await http.options(`/api/applications/${id}/notes/`);
    return response.data;
  }

  static fetchNoteItem(noteType, applicationId) {
    return http.get(
      `/api/applications/${applicationId}/notes/?section=${noteType}&sent=false`,
    );
  }

  static fetchApplicationNoteItem(type, applicationId) {
    return http.get(`/api/applications/${applicationId}/notes/?type=${type}`);
  }

  static patchNoteItem(noteId, noteValue, applicationId) {
    return http.patch(`/api/applications/${applicationId}/notes/${noteId}/`, {
      note: noteValue,
    });
  }

  static fetchRevisionRequestContent(applicationId) {
    return http.get(
      `/api/applications/${applicationId}/generate_revision_request/`,
    );
  }

  static sendRevisionRequestContent({ id, ...fields }) {
    return http.post(`/api/applications/${id}/send_revision_request/`, fields);
  }
}
