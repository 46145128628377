import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  ApplicationStore,
  DocumentsStore,
  SectionsStore,
  UtilsStore,
} from '@stores';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';

const ListDocumentCard = observer(() => {
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [patch, setPatch] = useState(false);
  const [savingData, setSavingData] = useState(false);

  useEffect(() => {
    async function fetchData() {
      await DocumentsStore.getApplicationListDocument();
      setLoading(false);
    }

    void fetchData();
  }, []);

  const handleOpen = () => {
    if (DocumentsStore.list) setPatch(true);
    setShow(true);
  };

  const handleClose = async () => {
    setShow(false);
    setLoading(true);

    await DocumentsStore.getApplicationListDocument().finally(() =>
      setLoading(false),
    );
  };

  const [showDelete, setShowDelete] = useState(false);

  const handleOpenDelete = () => {
    setShowDelete(true);
  };

  const handleCloseDelete = () => {
    setShowDelete(false);
  };

  const deleteDocument = async () => {
    handleCloseDelete();
    setLoading(true);
    await DocumentsStore.deleteApplicationDocument({
      applicationId: ApplicationStore.application.id,
      type: 'list',
      documentId: DocumentsStore.list.id,
    });
    await DocumentsStore.getApplicationListDocument();
    await DocumentsStore.unacknowledgeApplicationDocumentsSection();
    await SectionsStore.getApplicationSectionsStatuses();
    setLoading(false);
  };

  const renderDeleteDialog = () => (
    <Dialog open={showDelete}>
      <DialogTitle>Attention</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure want do delete?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={deleteDocument}
          variant="contained"
          color="secondary"
          startIcon={<DeleteIcon />}>
          Delete
        </Button>
        <Button onClick={handleCloseDelete}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );

  const renderDeleteButton = () =>
    Boolean(DocumentsStore.list) ? (
      <button className="text-md text-red-400 my-2" onClick={handleOpenDelete}>
        Delete
      </button>
    ) : null;

  const renderViewLink = () =>
    DocumentsStore.list ? (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={DocumentsStore.list.document.file}
        className="text-md text-blue-400 my-2">
        View Document
      </a>
    ) : null;

  const formik = useFormik({
    initialValues: {
      file: null,
      document_type: 1,
    },
    onSubmit: async (values) => {
      if (values.file.type !== 'application/pdf') {
        toast('Only PDF files allowed!', {
          type: 'error',
        });
        return;
      }

      if (values.file.size / 1024 / 1024 >= 3) {
        toast('Document exceeds file size limit 3MB!', {
          type: 'error',
        });
        return;
      }

      if (patch) {
        setSavingData(true);
        await DocumentsStore.patchApplicationDocument({
          applicationId: ApplicationStore.application.id,
          form: values,
          id: DocumentsStore.list.document.id,
        });
      } else {
        setSavingData(true);
        await DocumentsStore.createApplicationDocument({
          applicationId: ApplicationStore.application.id,
          type: 'list',
          form: values,
        });
      }

      await DocumentsStore.unacknowledgeApplicationDocumentsSection();
      await SectionsStore.getApplicationSectionsStatuses();
      setSavingData(false);
      await handleClose();
    },
  });

  return (
    <Card>
      <CardContent>
        {loading ? (
          <CircularProgress />
        ) : (
          <div className="p-4">
            <h3 className="text-3xl font-semibold mb-2 mt-3">
              List <span className="text-red-600">*</span>
              {DocumentsStore.list ? (
                <CheckCircleOutlineIcon
                  fontSize="small"
                  className="ml-2 mb-1 text-green-500"
                />
              ) : null}
            </h3>
            <p className="mb-4">
              {UtilsStore.metaTexts
                ? UtilsStore.metaTexts.filter(
                    (item) => item.name === 'supporting-list',
                  )[0].value
                : null}
            </p>
            {renderViewLink()}

            <br />

            <button onClick={handleOpen} className="text-md text-blue-400 my-2">
              Upload PDF document
            </button>

            <br />
            {renderDeleteButton()}
          </div>
        )}
      </CardContent>
      <Dialog
        open={show}
        onClose={() => setShow((v) => !v)}
        disableBackdropClick>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <h2 className="text-black text-center text-2xl px-2">
              Upload Document
              {savingData ? (
                <CircularProgress
                  color="primary"
                  size={24}
                  className="ml-3 absolute mt-1"
                />
              ) : null}
            </h2>
            <div className="p-6">
              <input
                required
                id="file"
                type="file"
                onChange={(event) => {
                  formik.setFieldValue('file', event.currentTarget.files[0]);
                }}
              />
            </div>

            <div className="flex flex-col md:flex-row justify-center mt-2">
              <button
                onClick={handleClose}
                type="button"
                className="
                  w-max
                  md:mx-2
                  px-8
                  py-3
                  text-xl
                  bg-gray-400
                  text-black
                  rounded-md
                  text-center
                ">
                Cancel
              </button>
              <button
                type="submit"
                className="
                  w-max
                  md:mx-2
                  px-8
                  py-3
                  text-xl
                  bg-primary
                  text-white
                  rounded-md
                  text-center
                ">
                Save
              </button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      {renderDeleteDialog()}
    </Card>
  );
});

export default ListDocumentCard;
