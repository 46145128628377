import { ApplicationService } from '@services';
import { ApplicationStore } from '@stores';
import { makeAutoObservable } from 'mobx';

class PositionsStore {
  constructor() {
    this.section = null;
    this.positions = [];
    this.options = null;
    makeAutoObservable(this);
  }

  async createApplicationPosition({
    positionTitle,
    institution,
    institutionOther,
    department,
    startAndEndDates,
    institutionType,
  }) {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    const body = {
      position_title: positionTitle,
      institution: institution,
      institution_other: institutionOther,
      department: department,
      start_and_end_dates: startAndEndDates,
      institution_type: institutionOther ? 'O' : 'I',
      application: ApplicationStore.application.id,
    };

    await ApplicationService.createApplicationSectionItem({
      body: body,
      applicationId: ApplicationStore.application.id,
      section: 'positions',
    });
    await this.getApplicationPositions();
  }

  async updateApplicationPosition(
    {
      positionTitle,
      institution,
      institutionOther,
      department,
      startAndEndDates,
      institutionType,
    },
    id,
  ) {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    const body = {
      position_title: positionTitle,
      institution: institution,
      institution_other: institutionOther,
      department: department,
      start_and_end_dates: startAndEndDates,
      institution_type: institutionOther ? 'O' : 'I',
      application: ApplicationStore.application.id,
    };

    await ApplicationService.updateApplicationSectionItem({
      body: body,
      applicationId: ApplicationStore.application.id,
      section: 'positions',
      id: id,
    });
    await this.getApplicationPositions();
  }

  async getApplicationPositions() {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    const result = await ApplicationService.getCurrentApplicationSectionItem({
      applicationId: ApplicationStore.application.id,
      section: 'positions',
    });
    this.positions = result.data;
  }

  async getApplicationPositionsSection() {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    const result = await ApplicationService.getCurrentApplicationSection({
      applicationId: ApplicationStore.application.id,
      section: 'positions',
    });
    this.section = result.data[0];
  }

  async acknowledgeApplicationPositionsSection() {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }
    if (!this.section) {
      await this.getApplicationPositionsSection();
    }

    const body = {
      acknowledged: true,
      application: this.section.application,
    };

    const result = await ApplicationService.updateApplicationSection({
      body: body,
      applicationId: ApplicationStore.application.id,
      section: 'positions',
      id: this.section.id,
    });
    this.section = result.data;
  }

  async unacknowledgeApplicationPositionsSection() {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }
    if (!this.section) {
      await this.getApplicationPositionsSection();
    }

    const body = {
      acknowledged: false,
      application: this.section.application,
    };

    const result = await ApplicationService.updateApplicationSection({
      body: body,
      applicationId: ApplicationStore.application.id,
      section: 'positions',
      id: this.section.id,
    });
    this.section = result.data;
  }

  async getApplicationPositionsOptions() {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    const result = await ApplicationService.getCurrentApplicationSectionOptions(
      {
        applicationId: ApplicationStore.application.id,
        section: 'positions',
      },
    );
    this.options = result.data;
  }

  async deleteApplicationPosition(id) {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    await ApplicationService.deleteApplicationSectionItem({
      id: id,
      section: 'positions',
      applicationId: ApplicationStore.application.id,
    });

    await this.getApplicationPositions();
  }
}

export default new PositionsStore();
