import { isValidElement } from 'react';
import { v4 } from 'uuid';

const Select = ({
  value,
  itemValue,
  itemName,
  items,
  id,
  onChange,
  onBlur,
  errors,
  label,
  disabled,
  defaultEmptyValue,
}) => {
  const classes = {
    defaultClasses:
      'block w-full px-4 py-2 mt-2 text-gray-700 bg-white border rounded-md focus:outline-none focus:ring border-gray-300 focus:border-blue-300',
    errorClasses:
      'block w-full px-4 py-2 mt-2 text-gray-700 bg-white border rounded-md focus:outline-none focus:ring focus:ring-red-300 border-red-300 focus:border-red-300',
  };

  const renderErrors = () => {
    if (errors && errors.length > 0) {
      return errors.map((error) => (
        <div key={v4()} className="text-red-500 py-1">
          {error}
        </div>
      ));
    }

    return null;
  };

  const renderLabel = () => {
    if (isValidElement(label)) {
      return label;
    }

    if (typeof label === 'string' || label instanceof String) {
      return (
        <label
          htmlFor={id ? id : ''}
          className="text-gray-700 dark:text-gray-200">
          {label}
        </label>
      );
    }

    return null;
  };

  const renderOptions = () =>
    items.map((option) => (
      <option key={v4()} value={option[itemValue]}>
        {option[itemName]}
      </option>
    ));

  return (
    <div className="w-full">
      {renderLabel()}
      <select
        className={
          errors && errors.length > 0
            ? classes.errorClasses
            : classes.defaultClasses
        }
        value={value || ''}
        id={id || null}
        onChange={onChange || null}
        onBlur={onBlur || null}
        disabled={disabled || false}>
        {defaultEmptyValue ? (
          <option value="" defaultValue>
            {null}
          </option>
        ) : null}
        {renderOptions()}
      </select>
      {renderErrors()}
    </div>
  );
};

export default Select;
