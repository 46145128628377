import { makeAutoObservable } from 'mobx';

class ProgressStore {
  constructor() {
    this.progress = false;
    makeAutoObservable(this);
  }

  setInProgress() {
    this.progress = true;
  }

  setNotInProgress() {
    this.progress = false;
  }
}

export default new ProgressStore();
