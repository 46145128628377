import { makeAutoObservable } from 'mobx';

export class Address {
  constructor(address) {
    this.id = address.id;
    this.fm_id = address.fm_id;
    this.application = address.application;
    this.address = address.address;
    this.date_created = address.date_created;
    this.date_last_modified = address.date_last_modified;
    makeAutoObservable(this, {}, { autoBind: true });
  }
}
