import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { HelpText } from '@components';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { ApplicationStore, UtilsStore } from '@stores';
import { observer } from 'mobx-react-lite';

const ApplicationNoCard = observer(() => {
  const [show, setShow] = useState(false);
  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      await UtilsStore.getMetaTexts();
    }

    fetchData();
  }, []);

  const createApplication = async () => {
    await ApplicationStore.createApplication();
    await ApplicationStore.getCurrentUserApplication();
    if (ApplicationStore.application) {
      history.push('/application/info');
    }
    setShow((v) => !v);
  };

  const handleClose = () => {
    setShow((v) => !v);
  };

  const handleOpen = async () => {
    await setShow((v) => !v);
  };

  return (
    <>
      <div className="text-center my-20">
        <button
          type="button"
          className="
            px-8
            py-3
            text-xl
            bg-primary
            text-white
            rounded-md
            text-center
            my-6
          "
          onClick={handleOpen}>
          Create Application
        </button>
      </div>

      <Dialog open={show} disableBackdropClick fullWidth maxWidth="lg">
        <DialogContent>
          <h1 className="text-5xl text-black font-calibri font-normal text-center my-2 md:my-6">
            Application Terms and Conditions
          </h1>
          <HelpText
            metaTextName="application-terms-and-conditions"
            className="my-6 font-calibri font-normal text-lg"
          />
        </DialogContent>
        <DialogActions>
          <button
            onClick={createApplication}
            type="button"
            className="px-8 py-3 text-xl bg-primary text-white rounded-md text-center mt-6">
            Create
          </button>
          <button
            onClick={handleClose}
            type="button"
            className="px-8 py-3 text-xl bg-gray-500 text-white rounded-md text-center mt-6">
            Cancel
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
});

export default ApplicationNoCard;
