import { useEffect } from 'react';
import { Loader } from '@components';
import { ApplicationStore } from '@stores';
import { observer } from 'mobx-react-lite';
import RevisionRequestDialogStore from '../../services/RevisionRequestDialogStore';
import { RevisionRequestContent } from './RevisionRequestContent';

export const RevisionRequestDialog = observer(() => {
  useEffect(() => {
    const applicationId = ApplicationStore.verifiableApplication.id;
    RevisionRequestDialogStore.getRevisionRequestContent(applicationId);

    return () => {
      RevisionRequestDialogStore.clear();
    };
  }, []);

  return (
    <>
      {RevisionRequestDialogStore.requestContent ? (
        <RevisionRequestContent
          content={RevisionRequestDialogStore.requestContent}
          applicationId={ApplicationStore.verifiableApplication.id}
        />
      ) : (
        <Loader message="Loading Revision Request Content..." />
      )}
    </>
  );
});
