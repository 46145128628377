import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Loader, DataTable, ApplicationsTableBody } from '@components';
import {
  AdminLayoutStore,
  ApplicationStore,
  CompetitionStore,
  UtilsStore,
} from '@stores';
import { observer } from 'mobx-react-lite';
import {
  applicationColumns,
  applicationFilterFields,
  DisplayStatus,
} from './services/constants';

export const AdminApplicationsScene = observer(() => {
  const history = useHistory();

  useEffect(() => {
    AdminLayoutStore.setPageTitle('APPLICATION PROCESSING');
    CompetitionStore.getCurrentCompetition();
    UtilsStore.getGroupsets();
    UtilsStore.getCountries();
  }, []);

  // TODO: rework this request when api will ready for it. "current: true" is required for download applications for current competition.
  const fetchApplicationsListCallback = (queryParams) => {
    ApplicationStore.getApplicationList({ current: true, ...queryParams });
  };

  const handleApplicationClick = (id) => {
    history.push(`/administration/application-list/${id}`);
  };

  const filters = [
    {
      filterName: 'status',
      label: 'Status',
      type: 'select',
      cases: DisplayStatus,
      caseValue: 'fieldName',
      caseName: 'fieldName',
    },
    {
      filterName: 'project_field',
      label: 'Project Field',
      type: 'select',
      cases: UtilsStore.groupsets,
      caseValue: 'name',
      caseName: 'name',
    },
    {
      filterName: 'country',
      label: 'Country',
      type: 'select',
      cases: UtilsStore.countries,
      caseValue: 'value',
      caseName: 'display_name',
    },
    {
      filterName: 'competition',
      label: 'Competition',
      type: 'select',
      cases: CompetitionStore.competitions,
      caseValue: 'id',
      caseName: 'name',
    },
    ...applicationFilterFields,
  ];

  return (
    <>
      {UtilsStore.countries.length > 0 && UtilsStore.groupsets.length > 0 ? (
        <DataTable
          competitionName={CompetitionStore.currentCompetition?.name}
          headCells={applicationColumns}
          rows={ApplicationStore.applications}
          filters={filters}
          fetchListCallback={fetchApplicationsListCallback}
          clearListCallback={ApplicationStore.clearApplications}
          totalCount={ApplicationStore.totalCount}
          TableBodyComponent={ApplicationsTableBody}
          tableTitle="Applications"
          onClickRow={handleApplicationClick}
        />
      ) : (
        <Loader message="Loading Utils..." />
      )}
    </>
  );
});
