import { useEffect, useRef, useState } from 'react';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { UtilsStore } from '@stores';
import { observer } from 'mobx-react-lite';

const HelpInfo = observer(({ metaTextName }) => {
  const [showHelp, setShowHelp] = useState(false);
  const helpRef = useRef();

  useEffect(() => {
    async function fetchData() {
      await UtilsStore.getMetaTexts();
    }

    fetchData();
  }, []);

  const handleOpen = async (event) => {
    event.stopPropagation();
    await setShowHelp((v) => !v);
    helpRef.current.innerHTML = await UtilsStore.getMetaText(metaTextName);
  };

  const handleClose = (event) => {
    setShowHelp((v) => !v);
  };

  return (
    <>
      <HelpOutlineIcon
        onClick={handleOpen}
        className="h-16 w-16 text-gray-500 cursor-pointer z-10"
      />
      <Dialog
        onClick={(event) => {
          event.stopPropagation();
        }}
        fullWidth
        maxWidth="lg"
        open={showHelp}
        onClose={handleClose}
        disableBackdropClick>
        <DialogContent>
          <h2 className="text-2xl text-center">Help information</h2>
          <p ref={helpRef} id="help" className="text-md p-4" />
        </DialogContent>
        <DialogActions>
          <button
            onClick={handleClose}
            type="button"
            className="px-8 py-3 text-xl text-white rounded-md text-center my-auto bg-gray-500">
            Close
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
});

export default HelpInfo;
