import { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { UtilsStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { InfoStore } from '../services';
import InfoForm from './InfoForm';

const InfoCard = observer(() => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [fields, setFields] = useState(null);

  useEffect(() => {
    async function fetchData() {
      await InfoStore.getApplicationInfo();
      await InfoStore.getApplicationInfoOptions();
      await UtilsStore.getGroupsets();
      await setLoading(false);
    }

    fetchData();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFields(null);
  };

  const updateInfo = (language) => {
    handleClickOpen();
    setFields(language);
  };

  const renderInfo = () => (
    <div className="flex flex-col md:m-20">
      <div className="flex flex-col justify-center md:flex-row md:justify-start mb-2 md:mb-0">
        <h3 className="text-black text-2xl w-1/3">Project Title:</h3>
        <p className="text-gray-500 text-2xl">{InfoStore.info.project_title}</p>
      </div>
      <div className="flex flex-col justify-center md:flex-row md:justify-start mb-2 md:mb-0">
        <h3 className="text-black text-2xl w-1/3">Project Fields:</h3>
        <div className="flex flex-col">
          <p className="text-gray-500 text-2xl">
            {InfoStore.info.project_field_1_name}
          </p>
          <p className="text-gray-500 text-2xl">
            {InfoStore.info.project_field_2_name}
          </p>
        </div>
      </div>
      <div className="flex flex-col justify-center md:flex-row md:justify-start mb-2 md:mb-0">
        <h3 className="text-black text-2xl w-1/3">Website(s):</h3>
        <div className="flex flex-col">
          <p className="text-gray-500 text-2xl break-words mb-1 md:mb-0">
            {InfoStore.info.website_1}
          </p>
          <p className="text-gray-500 text-2xl break-words mb-1 md:mb-0">
            {InfoStore.info.website_2}
          </p>
        </div>
      </div>
      <div className="flex flex-col justify-center md:flex-row md:justify-start mb-2 md:mb-0">
        <h3 className="text-black text-2xl w-1/3">Joint Applicant Name:</h3>
        <p className="text-gray-500 text-2xl">
          {InfoStore.info.joint_applicant_name}
        </p>
      </div>
      <button
        type="button"
        className="
          w-full
          md:w-max
          px-8
          py-1
          text-2xl
          md:text-md
          bg-gray-400
          text-black
          rounded-md
          text-center
          mt-2
          mx-auto
        "
        onClick={() => {
          updateInfo(InfoStore.info);
        }}>
        Edit information
      </button>
    </div>
  );

  const renderNoInfo = () => (
    <div className="my-16">
      <div
        className="
          flex flex-col
          md:flex-nowrap
          justify-center
          p-8
        ">
        <h2 className="text-center text-2xl text-blue-900">
          No application information.
        </h2>
        <button
          type="button"
          className="
          w-max
            px-8
            py-1
            mt-2
            text-md
            bg-primary
            text-white
            rounded-md
            text-center
            mx-auto
          "
          onClick={handleClickOpen}>
          Create Info
        </button>
      </div>
    </div>
  );

  const renderContent = () => {
    if (InfoStore.info) {
      return renderInfo();
    } else {
      return renderNoInfo();
    }
  };

  return (
    <>
      {loading ? null : renderContent()}
      <Dialog
        disableBackdropClick
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <InfoForm fields={fields} submitHandler={handleClose} />
        </DialogContent>
      </Dialog>
    </>
  );
});

export default InfoCard;
