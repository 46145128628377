import { makeAutoObservable } from 'mobx';
import DocumentsSectionService from '../services/documentsSection.service';
import DocumentParams from './DocumentParams';

export default class Document {
  constructor(document) {
    this.id = document.id;
    this.fm_id = document.fm_id;
    this.application = document.application;
    this.document = new DocumentParams(document.document);
    this.date_created = document.date_created;
    this.date_last_modified = document.date_last_modified;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  async updateDocument(payload) {
    await DocumentsSectionService.patchDocument(payload);
  }
}
