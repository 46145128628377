import { makeAutoObservable } from 'mobx';

class ErrorStore {
  constructor() {
    this.errors = null;
    makeAutoObservable(this);
  }

  setErrors(errors) {
    this.errors = errors;
  }

  getFieldErrors(field) {
    const errors = [];
    if (this.errors && this.errors[field]) {
      this.errors[field].forEach((item) => {
        errors.push(item);
      });
    }

    return errors;
  }

  dropErrors() {
    this.errors = null;
  }
}

export default new ErrorStore();
