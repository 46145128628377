// spacing
export const spacing = {
  spacing_xs: 4,
  spacing_sm: 8,
  spacing_md: 12,
  spacing_mdl: 16,
  spacing_mdxl: 20,
  spacing_lg: 30,
  spacing_xl: 40,
  spacing_xxl: 48,
};

// radius
export const radius = {
  radius_m: 8,
  radius_ml: 10,
};

// misc variables
export const miscVariables = {
  app_bar_height: 56,
  card_root_height: 280,
  card_root_width: 206,
  dialog_root_min_width: 850,
  table_min_width: 800,
  toolbar_desktop_min_width: 800,
};
