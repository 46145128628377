import { useEffect, useState } from 'react';
import { Select } from '@components';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { AuthStore, ErrorStore } from '@stores';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ReferencesStore from '../services/ReferencesStore';

const ReferenceDeclineDialog = ({ reference, open, onClose }) => {
  const [fetching, setFetching] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setFetching(true);
      await ReferencesStore.getReferenceOptions({ userId: AuthStore.id });
      setFetching(false);
    }

    fetchData();
  }, []);

  const formik = useFormik({
    initialValues: {
      declinedReason: '',
    },
    validationSchema: Yup.object({
      declinedReason: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      await ReferencesStore.declineReference({
        userId: AuthStore.id,
        declinedReason: values.declinedReason,
        referenceId: reference.id,
      });
      setLoading(false);
    },
  });

  return (
    <Dialog open={open} onClose={onClose} disableBackdropClick maxWidth="lg">
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Warning</DialogTitle>
        <DialogContent>
          {fetching ? (
            <CircularProgress />
          ) : (
            <>
              <p className="p-4 text-xl">Are you sure want to decline?</p>
              <Select
                id="declinedReason"
                label={
                  <label
                    htmlFor="declinedReason"
                    className="text-gray-700 dark:text-gray-200">
                    Declined <span className="text-red-600 mr-2">*</span>
                  </label>
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.declinedReason}
                defaultEmptyValue
                items={
                  ReferencesStore.options.actions.POST.declined_reason.choices
                }
                itemName="display_name"
                itemValue="value"
                errors={[]
                  .concat(ErrorStore.errors?.declined_reason || [])
                  .concat(
                    formik.touched.declinedReason &&
                      formik.errors.declinedReason
                      ? [formik.errors.declinedReason]
                      : [],
                  )}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={loading}
            variant="contained"
            color="secondary"
            type="submit">
            {loading ? <CircularProgress /> : 'Decline'}
          </Button>
          <Button disabled={loading} onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ReferenceDeclineDialog;
