import { ApplicationService, privateResolverWithPayload } from '@services';
import { makeAutoObservable } from 'mobx';
import ShortApplicationInfo from './ShortApplicationInfo';

export class Application {
  constructor(application) {
    this.id = application.id;
    this.fm_id = application.fm_id;
    this.fm_id_papform = application.fm_id_papform;
    this.fm_work_example_collection_id =
      application.fm_work_example_collection_id;
    this.applicant = application.applicant;
    this.is_submitted = application.is_submitted;
    this.competition = application.competition;
    this.work_requested = application.work_requested;
    this.date_created = application.date_created;
    this.date_last_modified = application.date_last_modified;
    this.is_active = application.is_active;
    this.packet = application.packet;
    this.joint_application = application.joint_application;
    this.final_submit_datetime = application.final_submit_datetime;
    this.appointed_name = application.appointed_name;
    this.reviewed_status = application.reviewed_status;
    this.approval_status = application.approval_status;
    this.approval_status_date = application.approval_status_date;
    this.name_previous_application = application.name_previous_application;
    this.accept_terms_and_conditions = application.accept_terms_and_conditions;
    this.withdrawn = application.withdrawn;
    this.approved = application.approved;
    this.approved_for_advisors = application.approved_for_advisors;
    this.approved_for_references = application.approved_for_references;
    this.approved_groups = application.approved_groups;
    this.complete_groups_entry = application.complete_groups_entry;
    this.application_years = application.application_years;
    this.rejected = application.rejected;
    this.on_hold = application.on_hold;
    this.info = new ShortApplicationInfo(application.info);
    this.is_winner_in_group = application.is_winner_in_group;
    this.is_winner_in_group_approved = application.is_winner_in_group_approved;
    this.display_status = application.display_status;
    this.is_editable = application.is_editable;
    this.pending_revision_request = application.pending_revision_request;
    this.joint_applicant_matched_user_name =
      application.joint_applicant_matched_user_name;
    this.joint_applicant_search_name = application.joint_applicant_search_name;
    this.revision_requested = application.revision_requested;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get fullNameApplicant() {
    return `${this.applicant.first_name} ${this.applicant.last_name}`;
  }

  update(application) {
    this.id = application.id;
    this.fm_id = application.fm_id;
    this.fm_id_papform = application.fm_id_papform;
    this.fm_work_example_collection_id =
      application.fm_work_example_collection_id;
    this.applicant = application.applicant;
    this.is_submitted = application.is_submitted;
    this.competition = application.competition;
    this.work_requested = application.work_requested;
    this.date_created = application.date_created;
    this.date_last_modified = application.date_last_modified;
    this.is_active = application.is_active;
    this.packet = application.packet;
    this.joint_application = application.joint_application;
    this.final_submit_datetime = application.final_submit_datetime;
    this.appointed_name = application.appointed_name;
    this.reviewed_status = application.reviewed_status;
    this.approval_status = application.approval_status;
    this.approval_status_date = application.approval_status_date;
    this.name_previous_application = application.name_previous_application;
    this.accept_terms_and_conditions = application.accept_terms_and_conditions;
    this.withdrawn = application.withdrawn;
    this.approved = application.approved;
    this.approved_for_advisors = application.approved_for_advisors;
    this.approved_for_references = application.approved_for_references;
    this.approved_groups = application.approved_groups;
    this.complete_groups_entry = application.complete_groups_entry;
    this.application_years = application.application_years;
    this.rejected = application.rejected;
    this.on_hold = application.on_hold;
    this.info = new ShortApplicationInfo(application.info);
    this.is_winner_in_group = application.is_winner_in_group;
    this.is_winner_in_group_approved = application.is_winner_in_group_approved;
    this.display_status = application.display_status;
    this.is_editable = application.is_editable;
    this.pending_revision_request = application.pending_revision_request;
    this.joint_applicant_matched_user_name =
      application.joint_applicant_matched_user_name;
    this.joint_applicant_search_name = application.joint_applicant_search_name;
    this.revision_requested = application.revision_requested;
  }

  patch(payload) {
    const patchPayload = { id: this.id, ...payload };
    privateResolverWithPayload({
      fetcher: ApplicationService.patchApplication,
      payload: patchPayload,
      setter: this.update,
      progress: true,
    });
  }
}
