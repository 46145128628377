import { useState } from 'react';
import { DialogSmartButtons, WarningDialog } from '@components';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { AdminApplicationLayoutStore, ApplicationStore } from '@stores';
import { miscVariables, mixins } from '@styles';
import { PencilBoxOutline } from 'mdi-material-ui';
import { observer } from 'mobx-react-lite';
import SectionNoteDialogStore from '../../services/SectionNoteDialogStore';

const useStyles = makeStyles({
  toolbarDesktop: {
    ...mixins.flexToolBar,
    minWidth: miscVariables.toolbar_desktop_min_width,
  },
  toolbar: {
    ...mixins.flexToolBar,
  },
  dialogContent: {
    margin: '8px 0',
  },
  dialogWrapper: {
    padding: '16px 0',
  },
});

export const SectionsWrapper = observer(
  ({
    sectionName,
    sectionFullName,
    sectionTitle,
    status,
    onCloseSection,
    children,
    open,
  }) => {
    const classes = useStyles();
    const [currentStatus, setCurrentStatus] = useState({ ...status });
    const [savingData, setSavingData] = useState(false);
    const { verifiableApplication } = ApplicationStore;
    const { setVerifiableSectionName, sectionNote, createSectionNote } =
      SectionNoteDialogStore;
    const { isMobile } = AdminApplicationLayoutStore;
    const [warningDialogParams, setWarningDialogParams] = useState({
      showWarningDialog: false,
      actionStatus: '',
    });
    const disabledSection =
      (!status.editable_pending && status.editable) ||
      !verifiableApplication.is_submitted;

    const handleSectionNoteDelete = () => {
      if (warningDialogParams.actionStatus === 'editable') {
        setCurrentStatus({
          ...currentStatus,
          editable: false,
          approved: false,
        });
      }
      if (warningDialogParams.actionStatus === 'approved') {
        setCurrentStatus({
          ...currentStatus,
          editable: false,
          approved: true,
        });
      }
      SectionNoteDialogStore.clear();
      setWarningDialogParams({ showWarningDialog: false, actionStatus: '' });
    };

    const handleWarningDialogClose = () => {
      setWarningDialogParams({
        ...warningDialogParams,
        showWarningDialog: false,
      });
    };

    const checkboxFieldHandler = (event) => {
      const { name, checked } = event.target;
      if (name === 'editable' && checked) {
        setVerifiableSectionName(sectionName, disabledSection);
        return setCurrentStatus({
          ...currentStatus,
          editable: true,
          approved: false,
        });
      }
      if (currentStatus.editable && sectionNote) {
        return setWarningDialogParams({
          showWarningDialog: true,
          actionStatus: name,
        });
      }
      if (name === 'approved' && checked) {
        return setCurrentStatus({
          ...currentStatus,
          editable: false,
          approved: true,
        });
      }
      setCurrentStatus({ ...currentStatus, [name]: checked });
    };

    const handleNoteOpen = () => {
      setVerifiableSectionName(sectionName, disabledSection);
    };

    const saveHandler = async (event) => {
      event.stopPropagation();
      event.preventDefault();
      setSavingData(true);

      if (sectionNote?.id) {
        await sectionNote.update();
      }

      if (sectionNote && !sectionNote.id) {
        await createSectionNote();
      }

      status.update({
        applicationId: verifiableApplication.id,
        status: currentStatus,
        sectionName: sectionFullName,
        onCloseDialog: onCloseSection,
      });
    };

    return (
      <>
        {warningDialogParams.showWarningDialog ? (
          <WarningDialog
            open={warningDialogParams.showWarningDialog}
            onClose={handleWarningDialogClose}
            onSave={handleSectionNoteDelete}
            title="The note will be deleted. Proceed?"
            submitButtonText="Delete Note"
          />
        ) : null}
        <Dialog maxWidth="md" fullScreen={isMobile} open={open}>
          <Toolbar
            className={isMobile ? classes.toolbar : classes.toolbarDesktop}>
            <Typography variant="h5">{sectionTitle}</Typography>
            <div>
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Checkbox
                    checked={currentStatus.editable}
                    onClick={checkboxFieldHandler}
                    color="default"
                    name="editable"
                    disabled={disabledSection}
                  />
                }
                label="Editable by Applicant"
              />
              {currentStatus.editable ? (
                <IconButton
                  onClick={handleNoteOpen}
                  size="small"
                  color="default">
                  <PencilBoxOutline />
                </IconButton>
              ) : null}
            </div>
            <FormControlLabel
              labelPlacement="start"
              control={
                <Checkbox
                  checked={currentStatus.approved}
                  onClick={checkboxFieldHandler}
                  color="default"
                  name="approved"
                  disabled={disabledSection}
                />
              }
              label="Review Complete"
            />
          </Toolbar>
          <DialogContent className={classes.dialogContent}>
            {children}
          </DialogContent>
          <DialogActions className={classes.dialogWrapper}>
            <DialogSmartButtons
              disabled={disabledSection}
              loading={savingData}
              onClose={onCloseSection}
              onSave={saveHandler}
            />
          </DialogActions>
        </Dialog>
      </>
    );
  },
);
