import { Link, Redirect } from 'react-router-dom';
import { AuthStore } from '@stores';
import { observer } from 'mobx-react-lite';
import LoginForm from './components/LoginForm';

const LogIn = observer(() => {
  if (AuthStore.isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <div className="text-gray-500 md:mt-20">
      <img className="mx-auto my-12" src="/assets/img/logo.svg" alt="qwe" />
      <div className="md:px-8 py-4 md:bg-gray-100 rounded-md mx-auto max-w-xl">
        <LoginForm />

        <div className="text-center my-6">
          <Link to="/password-reset" className="text-md text-lightBlue-600">
            Forgot password?
          </Link>
        </div>

        <hr className="bg-gray-400 border-gray-400 my-2 md:my-10" />

        <div className="flex flex-row justify-between mb-4 p-4 md:p-0">
          <p className="text-black font-medium">Don’t have an account yet?</p>
          <Link
            className="px-3 py-1 bg-primary text-white rounded-md"
            to="register">
            Sign Up
          </Link>
        </div>
      </div>
    </div>
  );
});

export default LogIn;
