import { toast } from 'react-toastify';
import ErrorStore from '../stores/ErrorStore';
import ProgressStore from '../stores/ProgressStore';

export function httpErrorHandler(error, authStore) {
  if (error.response) {
    switch (error.response.status) {
      case 500:
        toast('Server error!', {
          type: 'error',
        });
        if (localStorage.getItem('refresh_token')) {
          authStore.refresh();
        }
        break;
      case 400:
        ErrorStore.setErrors(error.response.data);
        if (localStorage.getItem('refresh_token')) {
          authStore.refresh();
        }
        break;
      default:
        toast('Something went wrong', {
          type: 'error',
        });
        if (localStorage.getItem('refresh_token')) {
          authStore.refresh();
        }
        break;
    }
  }

  ProgressStore.setNotInProgress();

  throw new Error(error);
}
