import { Checkbox } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { observer } from 'mobx-react-lite';
import { useDataTableStyles } from '../styles';

const StyledTableCell = withStyles(() => ({
  body: {
    padding: '4px 9px',
    width: '13%',
  },
}))(TableCell);

const StyledTableCellView = withStyles(() => ({
  body: {
    padding: 0,
    width: '5%',
  },
}))(TableCell);

export const ReferencesTableBody = observer(({ rows, onClickRow }) => {
  const classes = useDataTableStyles();

  return (
    <TableBody>
      {rows?.map((row) => (
        <TableRow
          onClick={() => (row.received ? onClickRow(row) : null)}
          hover
          className={classes.tableRow}
          key={row.id}>
          <StyledTableCellView
            className={classes.viewTableCell}
            align="center"
            component="th"
            scope="row">
            {row.received ? 'View' : ''}
          </StyledTableCellView>
          <StyledTableCell align="left">{row.fullName}</StyledTableCell>
          <StyledTableCell align="left">{row.applicant_name}</StyledTableCell>
          <StyledTableCell align="left">
            <Checkbox color="primary" size="small" checked={row.requested} />
          </StyledTableCell>
          <StyledTableCell align="left">
            <Checkbox color="primary" size="small" checked={row.received} />
          </StyledTableCell>
          <StyledTableCell align="left">
            <Checkbox
              color="primary"
              size="small"
              checked={Boolean(row.reviewed_status)}
            />
          </StyledTableCell>
          <StyledTableCell align="left">
            <Checkbox
              color="primary"
              size="small"
              checked={row.revision_requested}
            />
          </StyledTableCell>
          <StyledTableCell align="left">
            <Checkbox
              color="primary"
              size="small"
              checked={row.revision_received}
            />
          </StyledTableCell>
          <StyledTableCell align="left">
            <Checkbox color="primary" size="small" checked={row.approved} />
          </StyledTableCell>
          <StyledTableCell align="left">{row.display_status}</StyledTableCell>
        </TableRow>
      ))}
    </TableBody>
  );
});
