import { makeAutoObservable } from 'mobx';

class AdminLayoutStore {
  constructor() {
    this.pageTitle = 'ADMINISTRATION';
    this.isMobile = false;
    this.showUserMenu = true;
    this.showFiltersDialog = false;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setOpenFiltersDialog() {
    this.showFiltersDialog = true;
  }

  setCloseFiltersDialog() {
    this.showFiltersDialog = false;
  }

  setPageTitle(pageTitle) {
    this.pageTitle = pageTitle;
  }

  setMobile(flag) {
    this.isMobile = flag;
  }
}

export default new AdminLayoutStore();
