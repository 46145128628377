import AppFooter from './AppFooter';
import AppHeader from './AppHeader';

const UserLayout = ({ children }) => (
  <div className="flex flex-col min-h-screen">
    <AppHeader />
    <main className="flex-grow">
      <div className="container mx-auto">{children}</div>
    </main>
    <AppFooter />
  </div>
);

export default UserLayout;
