import { http } from '@utils';

export default class EducationSectionService {
  static fetchEducation(id) {
    return http.get(`/api/applications/${id}/education/`);
  }

  static async fetchEducationTypes(id) {
    const response = await http.options(`/api/applications/${id}/education/`);
    return response.data;
  }
}
