const generateErrorMessages = (
  formikFieldName,
  formik,
  ErrorStore,
  errorStoreFieldName,
) =>
  []
    .concat((ErrorStore.errors && ErrorStore.errors[errorStoreFieldName]) || [])
    .concat(
      formik.touched[formikFieldName] && formik.errors[formikFieldName]
        ? [formik.errors[formikFieldName]]
        : [],
    );

export default generateErrorMessages;
