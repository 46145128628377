import { useHistory } from 'react-router-dom';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { ApplicationStore, CompetitionStore, UserStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { HelpInfo } from '../HelpInfo';

const ApplicationLink = observer(() => {
  const history = useHistory();

  const navigateToApplication = () => {
    if (ApplicationStore.application?.is_submitted) {
      history.push('/application');
    }

    if (ApplicationStore.application?.revision_requested) {
      history.push('/application');
    }

    if (
      !CompetitionStore.currentCompetition.allow_application_edit &&
      !ApplicationStore.application?.is_submitted &&
      ApplicationStore.application?.is_editable &&
      !ApplicationStore.application?.revision_requested
    ) {
      history.push('/application');
    }

    if (!CompetitionStore.currentCompetition.allow_applicaton_create) {
      history.push('/application');
    }

    if (ApplicationStore.application) {
      history.push('/application/info');
    }

    history.push('/application');
  };

  if (UserStore.user.user_summary.juror) {
    return null;
  }

  return (
    <li>
      <div className="user-navbar__item" onClick={navigateToApplication}>
        <div>
          Application&nbsp;
          <HelpInfo metaTextName="application-home-page" />
        </div>
        {ApplicationStore.application?.is_submitted ? (
          <CheckCircleOutlineIcon className="user-navbar__item-icon user-navbar__item-icon_checked" />
        ) : (
          <RadioButtonUncheckedIcon className="user-navbar__item-icon user-navbar__item-icon_unchecked" />
        )}
      </div>
    </li>
  );
});

export default ApplicationLink;
