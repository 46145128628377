import { useEffect, useRef, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { UtilsStore } from '@stores';
import { observer } from 'mobx-react-lite';

const HelpText = observer(({ metaTextName, className }) => {
  const [loading, setLoading] = useState(true);
  const helpRef = useRef();

  useEffect(() => {
    async function fetchData() {
      await setLoading(true);
      await UtilsStore.getMetaTexts();
      const text = await UtilsStore.getMetaText(metaTextName);
      await setLoading(false);
      helpRef.current.innerHTML = text;
    }

    fetchData();
  }, [metaTextName]);

  return loading ? (
    <div className="text-center my-6">
      <CircularProgress />
    </div>
  ) : (
    <div className={className} ref={helpRef} />
  );
});

export default HelpText;
