import { useState } from 'react';
import { DialogSmartButtons, WarningDialog, NoDataEntered } from '@components';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { AdminApplicationLayoutStore, ApplicationStore } from '@stores';
import { PencilBoxOutline } from 'mdi-material-ui';
import { observer } from 'mobx-react-lite';
import { sectionsNames } from '../../../services/constants';
import SectionNoteDialogStore from '../../../services/SectionNoteDialogStore';
import DocumentsSectionStore from '../services/DocumentsSectionStore';
import { useDocumentsSectionStyles } from '../services/styles';
import { DocumentItemCard } from './DocumentItemCard';

export const DocumentsSectionContent = observer(
  ({ documents, status, documentReviewedStatusTypes }) => {
    const classes = useDocumentsSectionStyles();
    const { verifiableApplication } = ApplicationStore;
    const { isMobile, showDocumentsDialog, setCloseDocumentsDialog } =
      AdminApplicationLayoutStore;
    const { setVerifiableSectionName, sectionNote, createSectionNote } =
      SectionNoteDialogStore;
    const [showWarningDialog, setShowWarningDialog] = useState(false);
    const [currentStatus] = useState({ ...status });
    const [savingData, setSavingData] = useState(false);
    const disabledSection =
      (!status.editable_pending && status.editable) ||
      !verifiableApplication.is_submitted;
    const enteredDocuments = documents.length > 0;

    const getDocumentStatus = (docType, status) =>
      documents.find(
        (documentItem) => documentItem.document[docType] === status,
      );

    const getNotApprovedDocumentStatus = getDocumentStatus('approved', false);

    const getEditableDocumentStatus = getDocumentStatus('editable', true);

    const handleChangesSave = () => {
      DocumentsSectionStore.documents.map((document) =>
        document.updateDocument(document.document),
      );

      status.update({
        applicationId: verifiableApplication.id,
        status: {
          ...currentStatus,
          approved: !getNotApprovedDocumentStatus,
          editable: !!getEditableDocumentStatus,
        },
        sectionName: sectionsNames.documents,
        onCloseDialog: AdminApplicationLayoutStore.setCloseDocumentsDialog,
      });
    };

    const handleWarningDialogSave = () => {
      SectionNoteDialogStore.clear();
      handleChangesSave();
    };

    const handleSectionSave = async () => {
      setSavingData(true);

      if (sectionNote && !getEditableDocumentStatus) {
        setSavingData(false);
        return setShowWarningDialog(true);
      }

      if (sectionNote?.id) {
        sectionNote.update();
      }

      if (sectionNote) {
        await createSectionNote();
      }

      handleChangesSave();
    };

    const handleNoteOpen = () => {
      setVerifiableSectionName('document', disabledSection);
    };

    const handleWarningDialogClose = () => {
      setShowWarningDialog(false);
    };

    const renderDocumentCards = () =>
      documents.map((documentItem) => (
        <Grid key={documentItem.id} item xs={12} md={4}>
          <DocumentItemCard
            document={documentItem}
            disabledSection={disabledSection}
            documentReviewedStatusTypes={documentReviewedStatusTypes}
          />
        </Grid>
      ));

    return (
      <>
        {showWarningDialog ? (
          <WarningDialog
            open={showWarningDialog}
            onClose={handleWarningDialogClose}
            onSave={handleWarningDialogSave}
            title="The note will be deleted. Proceed?"
            submitButtonText="Delete Note"
          />
        ) : null}
        <Dialog maxWidth="md" fullScreen={isMobile} open={showDocumentsDialog}>
          <Toolbar
            className={isMobile ? classes.toolbar : classes.toolbarDesktop}>
            <Typography variant="h5">Documents</Typography>
            <div>
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Checkbox
                    checked={!!getEditableDocumentStatus}
                    disabled
                    color="default"
                    name="editable"
                  />
                }
                label="Editable by Applicant"
              />
              {!!getEditableDocumentStatus ? (
                <IconButton
                  onClick={handleNoteOpen}
                  size="small"
                  color="default">
                  <PencilBoxOutline />
                </IconButton>
              ) : null}
            </div>
            <FormControlLabel
              labelPlacement="start"
              control={
                <Checkbox
                  checked={!getNotApprovedDocumentStatus}
                  disabled
                  color="default"
                  name="approved"
                />
              }
              label="Review Complete"
            />
          </Toolbar>
          <DialogContent className={classes.dialogContent}>
            <Grid
              className={classes.cardsWrapper}
              container
              justify="center"
              alignItems="flex-start">
              {enteredDocuments ? renderDocumentCards() : <NoDataEntered />}
            </Grid>
          </DialogContent>
          <DialogActions className={classes.dialogWrapper}>
            <DialogSmartButtons
              onSave={handleSectionSave}
              disabled={disabledSection}
              loading={savingData}
              onClose={setCloseDocumentsDialog}
            />
          </DialogActions>
        </Dialog>
      </>
    );
  },
);
