import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { fontSizes } from '@styles';
import { miscVariables, spacing } from '@styles';
import { observer } from 'mobx-react-lite';
import EducationSectionStore from '../services/EducationSectionStore';

const useStyles = makeStyles({
  table: {
    minWidth: miscVariables.table_min_width,
  },
  tableHead: {
    fontWeight: 'bold',
    paddingBottom: spacing.spacing_md,
  },
  buttonView: {
    fontSize: fontSizes.font_size_s,
    textTransform: 'none',
  },
});

const StyledTableCell = withStyles(() => ({
  head: {
    border: 'none',
  },
  body: {
    fontSize: fontSizes.font_size_s,
    border: 'none',
  },
}))(TableCell);

export const EducationTable = observer(({ onClick, education }) => {
  const classes = useStyles();

  return (
    <TableContainer>
      <Table className={classes.table} aria-label="caption table">
        <TableHead>
          <TableRow>
            <StyledTableCell
              padding="none"
              size="small"
              className={classes.tableHead}>
              Institution
            </StyledTableCell>
            <StyledTableCell
              padding="none"
              size="small"
              className={classes.tableHead}>
              Degree
            </StyledTableCell>
            <StyledTableCell
              padding="none"
              size="small"
              className={classes.tableHead}>
              Level
            </StyledTableCell>
            <StyledTableCell
              padding="none"
              size="small"
              className={classes.tableHead}>
              {' '}
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {education.map((educationItem) => (
            <TableRow key={educationItem.id}>
              <StyledTableCell
                padding="none"
                size="small"
                component="th"
                scope="row">
                {educationItem.institution?.name ||
                  educationItem.institution_other}
              </StyledTableCell>
              <StyledTableCell padding="none" size="small">
                {educationItem.degree}
              </StyledTableCell>
              <StyledTableCell padding="none" size="small">
                {EducationSectionStore.getEducationLevelName(
                  educationItem.level,
                )}
              </StyledTableCell>
              <StyledTableCell align="right" padding="none" size="small">
                <Button
                  size="small"
                  variant="text"
                  color="primary"
                  onClick={() => onClick(educationItem.id)}
                  className={classes.buttonView}>
                  view
                </Button>
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
});
