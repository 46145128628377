import { Link } from 'react-router-dom';
import { UserStore, CompetitionStore } from '@stores';
import { observer } from 'mobx-react-lite';

const ApplicationRevisionRequestCard = observer(() => (
  <div
    className="
        flex flex-col
        md:flex-nowrap
        justify-center
        md:justify-around
        p-2
       ">
    <div
      className="
          border border-gray-300
          rounded-md
          bg-gray-100
          mx-auto
          my-16
          py-4
          px-10
          flex
          flex-col
        ">
      <div>
        <h2 className="text-2xl text-black font-calibri font-normal">
          {UserStore.user.first_name}&nbsp;{UserStore.user.last_name}
        </h2>
        <p>{CompetitionStore.currentCompetition.name}</p>
      </div>

      <p className="text-center text-3xl text-red-500 mt-2 mb-6">
        URGENT: YOUR APPLICATION REQUIRES IMMEDIATE ATTENTION
      </p>

      <div className="text-black text-center text-md">
        <p>You have been sent a Revision Request.</p>
        <p>
          One or more sections of your application have been unlocked for
          further editing.
        </p>
      </div>

      <p className="text-center text-3xl text-red-500 mt-6 mb-2">
        YOU MUST COMPLETE THE REQUESTED ADJUSTMENTS AND RESUBMIT YOUR
        APPLICATION TO BE CONSIDERED IN THE COMPETITION.
      </p>

      <Link
        to="/application/info"
        className="px-8
            py-3
            text-xl
            bg-primary
            text-white
            rounded-md
            text-center
            my-10
            mx-auto">
        Return to Application
      </Link>
    </div>
  </div>
));

export default ApplicationRevisionRequestCard;
