import { makeAutoObservable } from 'mobx';

class AdminApplicationLayoutStore {
  constructor() {
    this.pageTitle = 'APPLICATION PROCESSING';
    this.isMobile = false;
    this.showUserDialog = false;
    this.showAddressesDialog = false;
    this.showPositionsDialog = false;
    this.showLanguagesDialog = false;
    this.showOrganizationsDialog = false;
    this.showEducationDialog = false;
    this.showReferencesDialog = false;
    this.showApplicationInfoDialog = false;
    this.showDocumentsDialog = false;
    this.showRevisionRequestDialog = false;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setOpenDocumentsDialog() {
    this.showDocumentsDialog = true;
  }

  setCloseDocumentsDialog() {
    this.showDocumentsDialog = false;
  }

  setOpenRevisionRequestDialog() {
    this.showRevisionRequestDialog = true;
  }

  setCloseRevisionRequestDialog() {
    this.showRevisionRequestDialog = false;
  }

  setOpenApplicationInfoDialog() {
    this.showApplicationInfoDialog = true;
  }

  setCloseApplicationInfoDialog() {
    this.showApplicationInfoDialog = false;
  }

  setOpenReferencesDialog() {
    this.showReferencesDialog = true;
  }

  setCloseReferencesDialog() {
    this.showReferencesDialog = false;
  }

  setOpenEducationDialog() {
    this.showEducationDialog = true;
  }

  setCloseEducationDialog() {
    this.showEducationDialog = false;
  }

  setOpenOrganizationsDialog() {
    this.showOrganizationsDialog = true;
  }

  setCloseOrganizationsDialog() {
    this.showOrganizationsDialog = false;
  }

  setOpenLanguagesDialog() {
    this.showLanguagesDialog = true;
  }

  setCloseLanguagesDialog() {
    this.showLanguagesDialog = false;
  }

  setOpenPositionsDialog() {
    this.showPositionsDialog = true;
  }

  setClosePositionsDialog() {
    this.showPositionsDialog = false;
  }

  setOpenAddressesDialog() {
    this.showAddressesDialog = true;
  }

  setCloseAddressesDialog() {
    this.showAddressesDialog = false;
  }

  setOpenUserDialog() {
    this.showUserDialog = true;
  }

  setCloseUserDialog() {
    this.showUserDialog = false;
  }

  setPageTitle(pageTitle) {
    this.pageTitle = pageTitle;
  }

  setMobile(flag) {
    this.isMobile = flag;
  }
}

export default new AdminApplicationLayoutStore();
