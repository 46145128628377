import { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { UtilsService } from '@services';
import { v4 } from 'uuid';
import InstitutionsList from './InstitutionsList';

const InstitutionDialog = ({ onSave }) => {
  const [show, setShow] = useState(false);
  const [showOther, setShowOther] = useState(false);
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(true);
  const [institutions, setInstitutions] = useState([]);
  const [other, setOther] = useState('');
  const [items, setItems] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [region, setRegion] = useState('');
  const [city, setCity] = useState('');

  useEffect(() => {
    async function fetchData() {
      const { data } = await UtilsService.getInstitutions();
      const statesResponse = await UtilsService.getInstitutionStates();
      await setStates(statesResponse.data);
      await setInstitutions(data);
      await setItems(data);
      setLoading(false);
    }

    fetchData();
  }, []);

  const handleShow = () => {
    setShow((v) => !v);
  };

  const handleSave = () => {
    if (value) {
      onSave({
        institution: value,
      });
      handleShow();
      return;
    }

    onSave({
      institutionOther: other,
    });
    handleShow();
  };

  const handleSelect = (institution) => {
    setOther('');
    setValue(institution);
  };

  const filterItems = () => {
    const filtered = institutions.filter((item) => {
      if (region && city) {
        return item.state === region && item.city === city;
      }
      if (region) {
        return item.state === region;
      }
      if (city) {
        return item.city === city;
      }
      return true;
    });
    setItems(filtered);
  };

  useEffect(() => {
    filterItems();
    setCity('');
    // eslint-disable-next-line
  }, [region]);

  useEffect(() => {
    filterItems();
    // eslint-disable-next-line
  }, [city]);

  const handleChangeState = (event) => {
    setRegion(event.target.value);
    const arr = states.filter((item) => item.state === event.target.value);
    if (arr.length) {
      setCities(arr[0].cities);
    }
  };

  const handleChangeCity = (event) => {
    setCity(event.target.value);
  };

  const renderOtherField = () =>
    showOther ? (
      <div className="px-2 py-2">
        <label
          htmlFor="institutionOther"
          className="text-gray-700 dark:text-gray-200">
          Institution Other
        </label>

        <input
          value={other}
          onChange={(event) => {
            setValue('');
            setOther(event.target.value);
          }}
          id="institutionOther"
          type="text"
          className="
            block
            w-full
            px-4
            py-2
            mt-2
            text-gray-700
            bg-white
            border
            rounded-md
            focus:outline-none
            focus:ring
            border-gray-300
            focus:border-blue-300
          "
        />
      </div>
    ) : null;

  return (
    <>
      <Button
        onClick={handleShow}
        variant="contained"
        color="primary"
        size="small">
        Select
      </Button>
      <Dialog
        open={show}
        onClose={handleShow}
        disableBackdropClick
        fullWidth
        maxWidth="lg">
        <DialogTitle>Select Institution</DialogTitle>
        <DialogContent>
          {loading ? (
            <div className="text-center mt-6">
              <CircularProgress />
            </div>
          ) : (
            <div className="p-2">
              <Card>
                <CardContent>
                  <div className="flex flex-row">
                    <div className="w-1/2 p-2 border-r">
                      <h2 className="text-xl">Selected institution:</h2>
                      <p className="text-lg">{value.name || 'Not Selected'}</p>
                      {value ? (
                        <div className="mt-4">
                          <Button
                            variant="outlined"
                            onClick={() => setValue('')}>
                            Clear
                          </Button>
                        </div>
                      ) : null}
                    </div>
                    <div className="w-1/2 p-2">
                      <h2 className="ml-3">Filters:</h2>
                      <div className="flex flex-row justify-between">
                        <div className="w-1/2 m-1">
                          <div className="px-2 py-2">
                            <label
                              htmlFor="state"
                              className="text-gray-700 dark:text-gray-200">
                              State
                            </label>

                            <select
                              id="region"
                              value={region}
                              onChange={handleChangeState}
                              className="
                              block
                              w-full
                              px-4
                              py-2
                              mt-2
                              text-gray-700
                              bg-white
                              border
                              rounded-md
                              focus:outline-none
                              focus:ring
                              border-gray-300
                              focus:border-blue-300
                            ">
                              <option value="" defaultValue></option>
                              {states.map((item) => (
                                <option key={v4()} value={item.state}>
                                  {item.state}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="w-1/2 m-1">
                          <div className="px-2 py-2">
                            <label
                              htmlFor="city"
                              className="text-gray-700 dark:text-gray-200">
                              City/Town
                            </label>

                            <select
                              id="city"
                              value={city}
                              onChange={handleChangeCity}
                              disabled={!region}
                              className="
                              block
                              w-full
                              px-4
                              py-2
                              mt-2
                              text-gray-700
                              bg-white
                              border
                              rounded-md
                              focus:outline-none
                              focus:ring
                              border-gray-300
                              focus:border-blue-300
                            ">
                              <option value="" defaultValue></option>
                              {cities.map((item) => (
                                <option key={v4()} value={item}>
                                  {item}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>

              <div
                style={{
                  height: showOther ? '300px' : '350px',
                }}>
                <InstitutionsList
                  onInstitutionSelect={handleSelect}
                  items={items}
                />
              </div>
              <div className="mt-4">
                <div className="text-center">
                  <button
                    className="text-md text-blue-400 my-6"
                    onClick={() => setShowOther(true)}>
                    I cannot find my institution in the list.
                  </button>
                </div>
                {renderOtherField()}
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSave}
            size="large"
            color="primary"
            variant="contained">
            Save
          </Button>
          <Button size="large" onClick={handleShow}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InstitutionDialog;
