import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { colors, fontSizes } from '@styles';

const useStyles = makeStyles({
  secondCardContent: {
    padding: '14px',
  },
  peopleContent: {
    padding: '18px 0px 0px 30px',
  },
  settingsButton: {
    padding: '11px 96px',
    fontWeight: 'bold',
    color: colors.white,
    fontSize: fontSizes.font_size_ml,
    outline: 'none',
    textTransform: 'none',
    background: colors.blue_dark,
  },
});

export const SecondCardContent = ({ currentCompetition }) => {
  const classes = useStyles();

  const settingsClickHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Grid
      container
      className={classes.secondCardContent}
      justify="space-between"
      alignItems="flex-end">
      <Grid item xs={12}>
        <Grid container justify="flex-end">
          <Button
            onClick={settingsClickHandler}
            disableElevation
            variant="contained"
            className={classes.settingsButton}>
            Settings
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
