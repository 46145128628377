import { useState, isValidElement } from 'react';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import { v4 } from 'uuid';

const TextField = ({
  value,
  id,
  onChange,
  onBlur,
  errors,
  label,
  disabled,
  type,
}) => {
  const [show, setShow] = useState(false);
  const classes = {
    defaultClasses:
      'block w-full px-4 py-2 mt-2 text-gray-700 bg-white border rounded-md focus:outline-none focus:ring border-gray-300 focus:border-blue-300',
    errorClasses:
      'block w-full px-4 py-2 mt-2 text-gray-700 bg-white border rounded-md focus:outline-none focus:ring focus:ring-red-300 border-red-300 focus:border-red-300',
  };

  const renderErrors = () => {
    if (errors && errors.length > 0) {
      return errors.map((error) => (
        <div key={v4()} className="text-red-500 py-1">
          {error}
        </div>
      ));
    }

    return null;
  };

  const renderLabel = () => {
    if (isValidElement(label)) {
      return label;
    }

    if (typeof label === 'string' || label instanceof String) {
      return (
        <label
          htmlFor={id ? id : ''}
          className="text-gray-700 dark:text-gray-200">
          {label}
        </label>
      );
    }

    return null;
  };

  const renderIcon = () => {
    if (type === 'password') {
      return (
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 mt-2">
          <EyeIcon
            color="grey"
            onClick={() => setShow((v) => !v)}
            className={(show ? 'hidden' : 'block') + ' h-6 cursor-pointer'}
          />
          <EyeOffIcon
            color="grey"
            onClick={() => setShow((v) => !v)}
            className={(show ? 'block' : 'hidden') + ' h-6 cursor-pointer'}
          />
        </div>
      );
    }

    return null;
  };

  return (
    <div className="w-full">
      {renderLabel()}
      <div className="flex flex-row relative">
        <input
          className={
            errors && errors.length > 0
              ? classes.errorClasses
              : classes.defaultClasses
          }
          type={type === 'password' && !show ? 'password' : 'text'}
          value={value || ''}
          id={id || null}
          onChange={onChange || null}
          onBlur={onBlur || null}
          disabled={disabled || false}
        />
        {renderIcon()}
      </div>
      {renderErrors()}
    </div>
  );
};

export default TextField;
