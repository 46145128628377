import MenuItem from '@material-ui/core/MenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { colors, fontSizes, spacing } from '@styles';

const StyledTextField = withStyles({
  root: {
    '& .MuiSelect-selectMenu': {
      height: '16px',
      fontSize: fontSizes.font_size_s,
      minHeight: 'auto',
      padding: '7px 10px 9px',
      minWidth: '135px',
    },
    '& .MuiSelect-nativeInput': {
      height: '100%',
      minWidth: '100%',
    },
  },
})(TextField);

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    marginRight: spacing.spacing_sm,
    fontSize: `${fontSizes.font_size_m}px !important`,
  },
  input: {
    background: colors.white,
    borderRadius: '5px',
  },
}));

export const InputSelectField = ({
  flex = false,
  disabled,
  name,
  label,
  value,
  onClick,
  options,
}) => {
  const classes = useStyles();

  return (
    <div className={flex ? classes.container : null}>
      <Typography className={classes.label}>{label}</Typography>
      <StyledTextField
        className={classes.input}
        disabled={disabled}
        select
        autoFocus
        size="small"
        name={name}
        value={value || ''}
        variant="outlined">
        <MenuItem value=" " onClick={() => onClick(name, '')}>
          ---
        </MenuItem>
        {options.map((optionItem) => (
          <MenuItem
            key={optionItem.value}
            value={optionItem.value}
            onClick={() => onClick(name, optionItem.value)}>
            {optionItem.display_name}
          </MenuItem>
        ))}
      </StyledTextField>
    </div>
  );
};
