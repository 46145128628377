import { useEffect, useState } from 'react';
import { HelpInfo } from '@components';
import { ProgressStore, SectionsStore } from '@stores';
import { observer } from 'mobx-react-lite';
import {
  AcknowledgeButton,
  SectionContentLayout,
  SectionHeader,
  SectionHeaderHint,
  SectionLayout,
} from '../../components';
import { LanguagesList } from './components';
import { LanguagesStore } from './services';

const LanguagesScene = observer(() => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      await LanguagesStore.getApplicationLanguagesSection();
      await setLoading(false);
    }

    fetchData();
  }, []);

  const acknowledgeSection = async () => {
    ProgressStore.setInProgress();

    await LanguagesStore.acknowledgeApplicationLanguagesSection()
      .then(() => SectionsStore.getApplicationSectionsStatuses())
      .finally(() => ProgressStore.setNotInProgress());
  };

  const unacknowledgeSection = async () => {
    ProgressStore.setInProgress();

    await LanguagesStore.unacknowledgeApplicationLanguagesSection()
      .then(() => SectionsStore.getApplicationSectionsStatuses())
      .finally(() => ProgressStore.setNotInProgress());
  };

  const renderAcknowledge = () => {
    if (
      LanguagesStore.section.editable &&
      !LanguagesStore.section.acknowledged
    ) {
      return (
        <div className="flex flex-row">
          <SectionHeaderHint hintName="unacknowledged" />
          <AcknowledgeButton onClick={acknowledgeSection}>
            Acknowledge section and continue
          </AcknowledgeButton>
        </div>
      );
    }

    if (
      LanguagesStore.section.editable &&
      LanguagesStore.section.acknowledged
    ) {
      return (
        <div className="flex flex-row">
          <SectionHeaderHint hintName="acknowledged" />
          <AcknowledgeButton onClick={unacknowledgeSection}>
            Unacknowledge
          </AcknowledgeButton>
        </div>
      );
    }

    if (!LanguagesStore.section.editable) {
      return (
        <div className="flex flex-row">
          <SectionHeaderHint hintName="submitted" />
          <AcknowledgeButton disabled>Submitted</AcknowledgeButton>
        </div>
      );
    }

    return null;
  };

  const renderContent = () => {
    if (LanguagesStore.section.editable) {
      return <LanguagesList />;
    } else {
      return 'This section has been final submitted and has not been unlocked for a Revision Request.';
    }
  };

  const renderSection = () => (
    <SectionLayout>
      <SectionHeader>
        <h1 className="text-4xl text-black font-calibri font-normal md:mt-2 text-center md:text-left mb-2 md:mb-0">
          Edit Languages&nbsp;
          <HelpInfo metaTextName="application-languages" />
        </h1>
        {renderAcknowledge()}
      </SectionHeader>
      <SectionContentLayout>{renderContent()}</SectionContentLayout>
    </SectionLayout>
  );

  return loading ? null : renderSection();
});
export default LanguagesScene;
