import { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { AdminApplicationLayoutStore } from '@stores';
import { mixins } from '@styles';
import AddressesSectionStore from '../services/AddressesSectionStore';
import { addressTypes } from '../services/constants';
import { AlternateAddress } from './AlternateAddress';
import { HomeAddress } from './HomeAddress';
import { OfficeAddress } from './OfficeAddress';

const useStyles = makeStyles({
  toolbar: {
    ...mixins.flexToolBar,
  },
});

export const AddressItemDialog = () => {
  const { isMobile } = AdminApplicationLayoutStore;
  const { selectedAddress, getAddressName, showAddressDetailsDialog } =
    AddressesSectionStore;
  const classes = useStyles();

  useEffect(
    () => () => {
      AddressesSectionStore.clearSelectedAddress();
    },
    [],
  );

  const renderDialogContent = () => {
    switch (selectedAddress.address.address_type) {
      case addressTypes.home:
        return <HomeAddress address={selectedAddress} />;
      case addressTypes.office:
        return <OfficeAddress address={selectedAddress} />;
      case addressTypes.alternate:
        return <AlternateAddress address={selectedAddress} />;
      default:
        return null;
    }
  };

  return (
    <Dialog maxWidth="md" fullScreen={isMobile} open={showAddressDetailsDialog}>
      <Toolbar className={classes.toolbar}>
        <Typography variant="h5">
          {getAddressName(selectedAddress.address.address_type)}
        </Typography>
      </Toolbar>
      {selectedAddress ? renderDialogContent() : null}
    </Dialog>
  );
};
