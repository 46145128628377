const SectionContentListItem = ({ children, highlight }) => {
  const classes = [
    'flex',
    'justify-between',
    'w-full',
    'border',
    'px-4',
    'py-1',
  ];

  if (highlight) {
    classes.push('bg-gray-200');
  }

  return <li className={classes.join(' ')}>{children}</li>;
};

export default SectionContentListItem;
