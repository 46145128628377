import { HelpText } from '@components';

const ApplicationCreationSubmitCard = () => (
  <div className="text-center my-20">
    <HelpText
      className="text-center text-2xl text-blue-900"
      metaTextName="competition-closed-submit"
    />
  </div>
);

export default ApplicationCreationSubmitCard;
