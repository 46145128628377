import { privateResolverWithPayload } from '@services';
import { makeAutoObservable } from 'mobx';
import { Position } from '../models/Position';
import { PositionsSectionService } from './positionsSection.service';

class PositionsSectionStore {
  constructor() {
    this.positions = null;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  clear() {
    this.positions = null;
  }

  setPositions(positionsArray) {
    this.positions = positionsArray.map((position) => new Position(position));
  }

  getPositions(applicationId) {
    privateResolverWithPayload({
      fetcher: PositionsSectionService.fetchPositions,
      payload: applicationId,
      setter: this.setPositions,
    });
  }
}

export default new PositionsSectionStore();
