import { ApplicationService } from '@services';
import { ApplicationStore } from '@stores';
import { makeAutoObservable } from 'mobx';

class ReferencesStore {
  constructor() {
    this.section = null;
    this.references = null;
    this.options = null;
    this.countries = null;
    makeAutoObservable(this);
  }

  async createApplicationReference({
    addressLine1,
    addressLine2,
    city,
    state,
    zip,
    country,
    phoneNumber,
    phoneType,
    title,
    institution,
    department,
    hall,
    addressType,
    email,
    namePrefix,
    nameFirst,
    nameMiddle,
    nameLast,
    nameSuffix,
    institutionOther,
  }) {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    const body = {
      address: {
        address_line_1: addressLine1,
        address_line_2: addressLine2,
        city: city,
        state: state,
        zip: zip,
        country: country,
        phone_number: phoneNumber,
        phone_type: phoneType,
        title: title,
        institution: institution,
        institution_other: institutionOther,
        institution_type: institutionOther ? 'O' : 'I',
        department: department,
        hall: hall,
        address_type: addressType,
        email: email,
        name_prefix: namePrefix,
        name_first: nameFirst,
        name_middle: nameMiddle,
        name_last: nameLast,
        name_suffix: nameSuffix,
      },
      application: ApplicationStore.application.id,
    };

    await ApplicationService.createApplicationSectionItem({
      body: body,
      applicationId: ApplicationStore.application.id,
      section: 'references',
    });
    await this.getApplicationReferences();
  }

  async updateApplicationReference(
    {
      addressLine1,
      addressLine2,
      city,
      state,
      zip,
      country,
      phoneNumber,
      phoneType,
      title,
      institution,
      department,
      hall,
      addressType,
      email,
      namePrefix,
      nameFirst,
      nameMiddle,
      nameLast,
      nameSuffix,
      institutionOther,
    },
    id,
  ) {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    const body = {
      address: {
        address_line_1: addressLine1,
        address_line_2: addressLine2,
        city: city,
        state: state,
        zip: zip,
        country: country,
        phone_number: phoneNumber,
        phone_type: phoneType,
        title: title,
        institution: institution,
        department: department,
        hall: hall,
        address_type: addressType,
        email: email,
        name_prefix: namePrefix,
        name_first: nameFirst,
        name_middle: nameMiddle,
        name_last: nameLast,
        name_suffix: nameSuffix,
        institution_other: institutionOther,
        institution_type: institutionOther ? 'O' : 'I',
      },
      application: ApplicationStore.application.id,
    };

    await ApplicationService.updateApplicationSectionItem({
      body: body,
      applicationId: ApplicationStore.application.id,
      section: 'references',
      id: id,
    });
    await this.getApplicationReferences();
  }

  async getApplicationReferences() {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    const result = await ApplicationService.getCurrentApplicationSectionItem({
      applicationId: ApplicationStore.application.id,
      section: 'references',
    });
    this.references = result.data;
  }

  async getApplicationReferencesSection() {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    const result = await ApplicationService.getCurrentApplicationSection({
      applicationId: ApplicationStore.application.id,
      section: 'references',
    });
    this.section = result.data[0];
  }

  async acknowledgeApplicationReferencesSection() {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }
    if (!this.section) {
      await this.getApplicationReferencesSection();
    }

    const body = {
      acknowledged: true,
      application: this.section.application,
    };

    const result = await ApplicationService.updateApplicationSection({
      body: body,
      applicationId: ApplicationStore.application.id,
      section: 'references',
      id: this.section.id,
    });
    this.section = result.data;
  }

  async unacknowledgeApplicationReferencesSection() {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }
    if (!this.section) {
      await this.getApplicationReferencesSection();
    }

    const body = {
      acknowledged: false,
      application: this.section.application,
    };

    const result = await ApplicationService.updateApplicationSection({
      body: body,
      applicationId: ApplicationStore.application.id,
      section: 'references',
      id: this.section.id,
    });
    this.section = result.data;
  }

  async getApplicationReferencesOptions() {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    const result = await ApplicationService.getCurrentApplicationSectionOptions(
      {
        applicationId: ApplicationStore.application.id,
        section: 'references',
      },
    );
    this.setUSAndCanadaCountriesTop(
      result.data.actions.POST.address.children.country.choices,
    );
    this.options = result.data;
  }

  setUSAndCanadaCountriesTop(countryList) {
    const countriesToTop = countryList.filter(
      (item) => item.value === 'US' || item.value === 'CA',
    );
    const countriesWithoutTop = countryList.filter(
      (item) => item.value !== 'US' && item.value !== 'CA',
    );

    this.countries = [
      countriesToTop[1],
      countriesToTop[0],
      ...countriesWithoutTop,
    ];
  }

  async deleteApplicationReference(id) {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    await ApplicationService.deleteApplicationSectionItem({
      id: id,
      section: 'references',
      applicationId: ApplicationStore.application.id,
    });

    await this.getApplicationReferences();
  }
}

export default new ReferencesStore();
