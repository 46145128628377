import Button from '@material-ui/core/Button';
import { DataGrid } from '@mui/x-data-grid';

const InstitutionsList = ({ onInstitutionSelect, items }) => {
  const handleSelect = (institution) => {
    onInstitutionSelect(institution);
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 500,
    },
    {
      field: 'state',
      headerName: 'State',
      width: 150,
    },
    {
      field: 'city',
      headerName: 'City',
      width: 250,
    },
    {
      field: '',
      headerName: 'Action',
      width: 150,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <Button
          size="large"
          variant="contained"
          color="primary"
          onClick={() => handleSelect(params.row)}>
          Select
        </Button>
      ),
    },
  ];

  return <DataGrid columns={columns} rows={items} />;
};

export default InstitutionsList;
