import { useEffect } from 'react';
import { Loader, NoDataEntered } from '@components';
import { AdminApplicationLayoutStore, ApplicationStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { SectionsWrapper } from '../../components';
import { sectionsNames, sectionsTypes } from '../../services/constants';
import SectionNoteDialogStore from '../../services/SectionNoteDialogStore';
import SectionStatusesStore from '../../services/SectionStatusesStore';
import { EducationDetailsDialog } from './components/EducationDetailsDialog';
import { EducationSectionContent } from './components/EducationSectionContent';
import EducationSectionStore from './services/EducationSectionStore';

export const EducationSectionScene = observer(() => {
  useEffect(() => {
    const applicationId = ApplicationStore.verifiableApplication.id;
    EducationSectionStore.getEducation(applicationId);
    SectionStatusesStore.getSectionStatuses(
      applicationId,
      sectionsNames.education,
    );
    EducationSectionStore.getEducationTypes(applicationId);
    SectionNoteDialogStore.getSectionNote(
      sectionsTypes.education,
      applicationId,
    );

    return () => {
      SectionStatusesStore.clear();
      EducationSectionStore.clear();
      SectionNoteDialogStore.clear();
    };
  }, []);

  const renderContent = () => {
    if (
      EducationSectionStore.showEducationDetailsDialog &&
      EducationSectionStore.selectedEducation
    ) {
      return (
        <EducationDetailsDialog
          selectedEducation={EducationSectionStore.selectedEducation}
        />
      );
    }

    return (
      <SectionsWrapper
        sectionTitle="Educational Summary"
        sectionName={sectionsNames.education}
        sectionFullName={sectionsNames.education}
        status={SectionStatusesStore.sectionStatuses}
        onCloseSection={AdminApplicationLayoutStore.setCloseEducationDialog}
        open={AdminApplicationLayoutStore.showEducationDialog}>
        {EducationSectionStore.education.length > 0 ? (
          <EducationSectionContent
            education={EducationSectionStore.education}
          />
        ) : (
          <NoDataEntered />
        )}
      </SectionsWrapper>
    );
  };

  return (
    <>
      {EducationSectionStore.education &&
      SectionStatusesStore.sectionStatuses &&
      EducationSectionStore.educationLevelTypes ? (
        renderContent()
      ) : (
        <Loader message="Loading Education Section..." />
      )}
    </>
  );
});
