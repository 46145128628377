import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { fontSizes } from '@styles';

const useStyles = makeStyles({
  fieldLabel: {
    flexGrow: 1,
    fontWeight: 300,
    fontSize: 13,
    marginBottom: '5px',
  },
  mark: {
    color: 'red',
  },
});

const CssTextField = withStyles({
  root: {
    '& .MuiInputBase-input': {
      height: '11px',
      fontSize: fontSizes.font_size_s,
    },
  },
})(TextField);

export const InputTextField = ({
  name,
  value,
  label,
  onChange,
  readOnly = true,
  required = false,
  visibleLabel = true,
}) => {
  const classes = useStyles();

  const renderRequiredMark = () =>
    required ? <span className={classes.mark}>*</span> : null;

  return (
    <>
      {visibleLabel ? (
        <Typography variant="subtitle1" className={classes.fieldLabel}>
          {label} {renderRequiredMark()}
        </Typography>
      ) : null}
      <CssTextField
        autoFocus
        size="small"
        variant="outlined"
        name={name}
        fullWidth
        onChange={onChange ?? null}
        value={value}
        InputProps={{
          readOnly: readOnly,
        }}
      />
    </>
  );
};
