import { useEffect, useState } from 'react';
import { HelpInfo } from '@components';
import { ProgressStore, SectionsStore } from '@stores';
import { observer } from 'mobx-react-lite';
import {
  AcknowledgeButton,
  SectionContentLayout,
  SectionHeader,
  SectionHeaderHint,
  SectionLayout,
} from '../../components';
import { InfoCard } from './components';
import { InfoStore } from './services';

const InfoScene = observer(() => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      await InfoStore.getApplicationInfoSection();
      await setLoading(false);
    }

    fetchData();
  }, []);

  const acknowledgeSection = async () => {
    ProgressStore.setInProgress();

    await InfoStore.acknowledgeApplicationInfoSection()
      .then(() => SectionsStore.getApplicationSectionsStatuses())
      .finally(() => ProgressStore.setNotInProgress());
  };

  const unacknowledgeSection = async () => {
    ProgressStore.setInProgress();

    await InfoStore.unacknowledgeApplicationInfoSection()
      .then(() => SectionsStore.getApplicationSectionsStatuses())
      .finally(() => ProgressStore.setNotInProgress());
  };

  const renderContent = () => {
    if (InfoStore.section.editable) {
      return <InfoCard />;
    } else {
      return 'This section has been final submitted and has not been unlocked for a Revision Request.';
    }
  };

  const renderAcknowledge = () => {
    if (InfoStore.section.editable && !InfoStore.section.acknowledged) {
      return (
        <div className="flex flex-row">
          <SectionHeaderHint hintName="unacknowledged" />
          <AcknowledgeButton
            onClick={acknowledgeSection}
            disabled={
              !InfoStore.info?.project_field_1 || !InfoStore.info?.project_title
            }>
            Acknowledge section and continue
          </AcknowledgeButton>
        </div>
      );
    }

    if (InfoStore.section.editable && InfoStore.section.acknowledged) {
      return (
        <div className="flex flex-row">
          <SectionHeaderHint hintName="acknowledged" />
          <AcknowledgeButton onClick={unacknowledgeSection}>
            Unacknowledge
          </AcknowledgeButton>
        </div>
      );
    }

    if (!InfoStore.section.editable) {
      return (
        <div className="flex flex-row">
          <SectionHeaderHint hintName="submitted" />
          <AcknowledgeButton disabled>Submitted</AcknowledgeButton>
        </div>
      );
    }

    return null;
  };

  const renderSection = () => (
    <SectionLayout>
      <SectionHeader>
        <h1 className="text-4xl text-black font-calibri font-normal md:mt-2 text-center md:text-left mb-2 md:mb-0">
          Edit Application Information&nbsp;
          <HelpInfo metaTextName="application-information" />
        </h1>
        {renderAcknowledge()}
      </SectionHeader>
      <SectionContentLayout>{renderContent()}</SectionContentLayout>
    </SectionLayout>
  );

  return loading ? null : renderSection();
});
export default InfoScene;
