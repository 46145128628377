import { useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AddressesStore, ErrorStore, UtilsStore } from '@stores';
import { generateErrorMessages } from '@utils';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import * as Yup from 'yup';
import { NonFieldErrors } from '../../../NonFieldErrors';
import { Select } from '../../../Select';
import { TextField } from '../../../TextField';

const HomeAddressForm = observer(({ fields, submitHandler }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      await AddressesStore.getApplicationHomeAddressOptions();
      await UtilsStore.getCountries();
    }

    fetchData();
    ErrorStore.dropErrors();
  }, []);

  const values = {
    addressLine1: fields?.address_line_1 ?? '',
    addressLine2: fields?.address_line_2 ?? '',
    city: fields?.city ?? '',
    state: fields?.state ?? '',
    zip: fields?.zip ?? '',
    country: fields?.country ?? '',
    phoneNumber: fields?.phone_number ?? '',
    phoneType: fields?.phone_type ?? '',
    addressType: 'h',
    email: fields?.email ?? '',
  };

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: values,
    validationSchema: Yup.object({
      email: Yup.string().required('Required').email('Incorrect email'),
      phoneType: Yup.string().required('Required'),
      phoneNumber: Yup.string().required('Required'),
      country: Yup.string().required('Required'),
      addressLine1: Yup.string().required('Required'),
      city: Yup.string().required('Required'),
      state: Yup.string().required('Required'),
      zip: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      setLoading(true);

      try {
        if (fields) {
          await AddressesStore.updateApplicationHomeAddress(values);
        } else {
          await AddressesStore.createApplicationHomeAddress(values);
        }
      } finally {
        setLoading(false);
        submitHandler();
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className="w-full">
      <h2 className="text-black text-2xl px-2">
        {fields ? 'Edit Home Address' : 'Add Home Address'}
      </h2>
      <div className="flex flex-row justify-between">
        <div className="w-1/3 m-4">
          <div className="px-2 py-2">
            <Select
              label={
                <label
                  htmlFor="country"
                  className="text-gray-700 dark:text-gray-200">
                  Country <span className="text-red-600">*</span>
                </label>
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.country}
              id="country"
              defaultEmptyValue
              itemName="display_name"
              itemValue="value"
              items={UtilsStore.countries}
              errors={generateErrorMessages(
                'country',
                formik,
                ErrorStore,
                'country',
              )}
            />
          </div>
        </div>
        <div className="w-1/3 m-4">
          <div className="px-2 py-2">
            <TextField
              label={
                <label
                  htmlFor="addressLine1"
                  className="text-gray-700 dark:text-gray-200">
                  Address Line 1 <span className="text-red-600">*</span>
                </label>
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.addressLine1}
              id="addressLine1"
              errors={generateErrorMessages(
                'addressLine1',
                formik,
                ErrorStore,
                'address_line_1',
              )}
            />
          </div>
        </div>
        <div className="w-1/3 m-4">
          <div className="px-2 py-2">
            <TextField
              label="Address Line 2"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.addressLine2}
              id="addressLine2"
              errors={generateErrorMessages(
                'addressLine2',
                formik,
                ErrorStore,
                'address_line_2',
              )}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-between">
        <div className="w-1/3 m-4">
          <div className="px-2 py-2">
            <TextField
              label={
                <label
                  htmlFor="city"
                  className="text-gray-700 dark:text-gray-200">
                  City/Town <span className="text-red-600">*</span>
                </label>
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.city}
              id="city"
              errors={generateErrorMessages('city', formik, ErrorStore, 'city')}
            />
          </div>
        </div>
        <div className="w-1/3 m-4">
          <div className="px-2 py-2">
            <TextField
              label={
                <label
                  htmlFor="state"
                  className="text-gray-700 dark:text-gray-200">
                  State/Province <span className="text-red-600">*</span>
                </label>
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.state}
              id="state"
              errors={generateErrorMessages(
                'state',
                formik,
                ErrorStore,
                'state',
              )}
            />
          </div>
        </div>
        <div className="w-1/3 m-4">
          <div className="px-2 py-2">
            <TextField
              label={
                <label
                  htmlFor="zip"
                  className="text-gray-700 dark:text-gray-200">
                  Zip/Postal Code <span className="text-red-600">*</span>
                </label>
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.zip}
              id="zip"
              errors={generateErrorMessages('zip', formik, ErrorStore, 'zip')}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-between">
        <div className="w-1/3 m-4">
          <div className="px-2 py-2">
            <TextField
              label={
                <label
                  htmlFor="email"
                  className="text-gray-700 dark:text-gray-200">
                  Email <span className="text-red-600">*</span>
                </label>
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              id="email"
              errors={generateErrorMessages(
                'email',
                formik,
                ErrorStore,
                'email',
              )}
            />
          </div>
        </div>
        <div className="w-1/3 m-4">
          <div className="px-2 py-2">
            <Select
              label={
                <label
                  htmlFor="phoneType"
                  className="text-gray-700 dark:text-gray-200">
                  Phone Type <span className="text-red-600">*</span>
                </label>
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phoneType}
              id="phoneType"
              defaultEmptyValue
              itemName="label"
              itemValue="value"
              items={[
                {
                  value: 'h',
                  label: 'Home',
                },
                {
                  value: 'm',
                  label: 'Mobile',
                },
                {
                  value: 'o',
                  label: 'Office',
                },
              ]}
              errors={generateErrorMessages(
                'phoneType',
                formik,
                ErrorStore,
                'phone_type',
              )}
            />
          </div>
        </div>
        <div className="w-1/3 m-4">
          <div className="px-2 py-2">
            <TextField
              label={
                <label
                  htmlFor="phoneNumber"
                  className="text-gray-700 dark:text-gray-200">
                  Phone Number <span className="text-red-600">*</span>
                </label>
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phoneNumber}
              id="phoneNumber"
              errors={generateErrorMessages(
                'phoneNumber',
                formik,
                ErrorStore,
                'phone_number',
              )}
            />
          </div>
        </div>
      </div>

      <NonFieldErrors />
      <div className="flex flex-col md:flex-row justify-center mt-2">
        <button
          disabled={loading}
          onClick={submitHandler}
          type="button"
          className="
                          w-max
                          md:mx-2
                          px-8
                          py-3
                          text-xl
                          bg-gray-400
                          text-black
                          rounded-md
                          text-center
                        ">
          Cancel
        </button>
        <button
          disabled={loading}
          type="submit"
          className="
                          w-max
                          md:mx-2
                          px-8
                          py-3
                          text-xl
                          bg-primary
                          text-white
                          rounded-md
                          text-center
                        ">
          {loading ? <CircularProgress color="white" /> : 'Save'}
        </button>
      </div>
    </form>
  );
});

export default HomeAddressForm;
