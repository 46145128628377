import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PersonalInformationForm } from '@components';
import { Card, CardContent, CircularProgress } from '@material-ui/core';
import { UserStore } from '@stores';
import { observer } from 'mobx-react-lite';

const ProfileScene = observer(() => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function fetchData() {
      await UserStore.getCurrentUser();
      setLoading(false);
    }

    fetchData();
  }, []);

  const submitHandler = () => {
    history.push('/home');
  };

  return (
    <>
      <h1 className="mt-6 mb-1 text-3xl text-center">
        Enter the required personal information to finish activating your
        account.
      </h1>
      <p className="mb-3 text-center text-red-500">
        If you are applying in the United States and Canada competition, please{' '}
        note that the competition is open only to citizens and permanent
        residents of the U.S. and Canada.
      </p>
      <Card>
        <CardContent>
          {loading ? (
            <CircularProgress />
          ) : (
            <PersonalInformationForm submitHandler={submitHandler} />
          )}
        </CardContent>
      </Card>
    </>
  );
});

export default ProfileScene;
