import { useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ApplicationNavbar, HelpInfo, HelpText } from '@components';
import { DownloadApplicationPdf } from '@components';
import { CircularProgress } from '@material-ui/core';
import {
  ApplicationStore,
  ApplicationUserSectionStore,
  SectionsStore,
  UserStore,
  UtilsStore,
} from '@stores';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import * as Yup from 'yup';

const SubmitScene = observer(() => {
  const history = useHistory();

  if (UserStore.shouldUserNavigateToStart()) {
    return <Redirect to="/user/profile" />;
  }

  useEffect(() => {
    async function fetchData() {
      await UtilsStore.getInstitutions();
      await SectionsStore.getApplicationSectionsStatuses();
      await UtilsStore.getMetaTexts();
      await UserStore.getUserPersonalInfoOptions();
      if (!ApplicationStore.application) {
        await ApplicationStore.getCurrentUserApplication();
      }

      if (ApplicationStore.application.is_submitted) {
        history.push('/home');
      }
    }

    fetchData();
  }, [history]);

  const formik = useFormik({
    initialValues: {
      checkbox: false,
    },
    validationSchema: Yup.object({
      checkbox: Yup.boolean().oneOf([true], 'Required'),
    }),
    onSubmit: async (values) => {
      if (!SectionsStore.sections) {
        await SectionsStore.getApplicationSectionsStatuses();
      }

      if (SectionsStore.isSectionsAcknowledged()) {
        await ApplicationUserSectionStore.acknowledgeApplicationUserSection();
        await ApplicationStore.submitApplication({
          applicationId: ApplicationStore.application.id,
        });
        await ApplicationStore.getCurrentUserApplication();
        if (ApplicationStore.application.is_submitted) {
          toast('Application has been submitted!', {
            type: 'success',
          });
          history.push('/application');
        }
      } else {
        toast('Check other sections before submit the application!', {
          type: 'error',
        });
      }
    },
  });

  const renderInstitutionName = () => {
    UtilsStore.getInstitutionName(UserStore.user.institution);
    return (
      <>
        {UtilsStore.foundInstitution ? (
          UtilsStore.foundInstitution.name
        ) : (
          <CircularProgress size={10} />
        )}
      </>
    );
  };

  return (
    <>
      <div className="border rounded-md border-gray-300 md:mt-10 shadow-lg">
        <div className="relative flex flex-col md:flex-row">
          <div className="absolute top-2 right-2">
            <HelpInfo metaTextName="final-submit-screen" />
          </div>
          <ApplicationNavbar />
          <form
            onSubmit={formik.handleSubmit}
            className="p-8 mx-auto w-full flex flex-col">
            <HelpText
              className="text-lg p-2"
              metaTextName="final-submit-screen"
            />
            <h3 className="text-2xl text-center mt-6 mb-2">
              Personal Information
            </h3>
            <div className="flex justify-center">
              <div className="w-max">
                <p className="text-lg font-calibri font-normal">
                  Name:&nbsp;{UserStore.user.first_name}&nbsp;
                  {UserStore.user.last_name}
                </p>
                <p className="text-lg font-calibri font-normal">
                  Date of Birth:{' '}
                  {moment(UserStore.user.date_of_birth).format('MM-DD-YYYY')}
                </p>
                <p className="text-lg font-calibri font-normal">
                  Place of Birth: {UserStore.user.place_of_birth}
                </p>
                <p className="text-lg font-calibri font-normal">
                  Citizenship: {UserStore.user.citizenship}
                </p>
                <p className="text-lg font-calibri font-normal">
                  Gender:&nbsp;
                  {UserStore.user.gender === 'o'
                    ? UserStore.user.gender_other
                    : UserStore.options?.actions.PUT.gender.choices.filter(
                        (item) => item.value === UserStore.user.gender,
                      )[0]?.display_name || UserStore.user.gender_other}
                </p>
                <p className="text-lg font-calibri font-normal">
                  Title: {UserStore.user.title}
                </p>
                <p className="text-lg font-calibri font-normal">
                  Company:{' '}
                  {UserStore.user.institution
                    ? renderInstitutionName()
                    : UserStore.user.institution_other}
                </p>
              </div>
            </div>
            <div className="px-10 my-6">
              <label className="text-black" htmlFor="checkbox">
                <input
                  disabled={!SectionsStore.isSectionsAcknowledged()}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.checkbox}
                  id="checkbox"
                  type="checkbox"
                  className="border-gray-300"
                />
                <span className="pl-1 text-black font-medium">
                  I have reviewed my application for correctness and I
                  acknowledge I will not be able to edit anything once it is
                  submitted.
                </span>
              </label>
              {formik.touched.checkbox && formik.errors.checkbox ? (
                <div className="text-red-500 py-1">
                  {formik.errors.checkbox}
                </div>
              ) : null}
            </div>
            {SectionsStore.sections ? (
              <button
                disabled={!SectionsStore.isSectionsAcknowledged()}
                type="submit"
                className={
                  'w-max mt-2 px-8 py-1 text-md text-white rounded-md text-center mx-2 mx-auto' +
                  (SectionsStore.isSectionsAcknowledged()
                    ? ' bg-primary'
                    : ' bg-gray-500')
                }>
                Final Submit Application
              </button>
            ) : null}
            <DownloadApplicationPdf
              innerText="Download Application (PDF)"
              showHelperText
            />
          </form>
        </div>
      </div>
    </>
  );
});

export default SubmitScene;
