import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { colors } from '@styles';

const useStyles = makeStyles({
  dialogAction: {
    background: colors.gray,
    textTransform: 'none',
    color: colors.black,
    margin: '0 8px',
  },
  loadedAction: {
    position: 'relative',
  },
  fieldLabel: {
    flexGrow: 1,
    fontWeight: 400,
    fontSize: '1rem',
  },
  buttonProgress: {
    color: 'green',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

export const DialogSmartButtons = ({ disabled, loading, onClose, onSave }) => {
  const classes = useStyles();

  return (
    <>
      {disabled ? (
        <Grid container justify="center" alignItems="center">
          <Button
            className={classes.dialogAction}
            onClick={onClose}
            color="primary">
            Done
          </Button>
        </Grid>
      ) : (
        <Grid container justify="center" alignItems="center">
          <Button
            className={classes.dialogAction}
            onClick={onClose}
            color="primary">
            Cancel
          </Button>
          <div className={classes.loadedAction}>
            <Button
              className={classes.dialogAction}
              onClick={onSave}
              disabled={loading}
              color="primary">
              Save
            </Button>
            {loading ? (
              <CircularProgress size={24} className={classes.buttonProgress} />
            ) : null}
          </div>
        </Grid>
      )}
    </>
  );
};
