import { privateResolverWithPayload } from '@services';
import { makeAutoObservable } from 'mobx';
import { Language } from '../models/Language';
import { LanguagesSectionService } from './languagesSection.service';

class LanguagesSectionStore {
  constructor() {
    this.languages = null;
    this.languageRatingList = null;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  clear() {
    this.languages = null;
    this.languageRatingList = null;
  }

  getDisplayNameRating(valueRating) {
    return this.languageRatingList.find(
      (ratingItem) => ratingItem.value === valueRating,
    )?.display_name;
  }

  setLanguageRatings(options) {
    this.languageRatingList = options.actions.POST.rating_speak.choices;
  }

  setLanguages(languagesArray) {
    this.languages = languagesArray.map((language) => new Language(language));
  }

  getLanguageRatings(applicationId) {
    privateResolverWithPayload({
      fetcher: LanguagesSectionService.fetchLanguageRatings,
      payload: applicationId,
      setter: this.setLanguageRatings,
    });
  }

  getLanguages(applicationId) {
    privateResolverWithPayload({
      fetcher: LanguagesSectionService.fetchLanguages,
      payload: applicationId,
      setter: this.setLanguages,
    });
  }
}

export default new LanguagesSectionStore();
