import { toast } from 'react-toastify';
import axios from 'axios';
import ErrorStore from '../stores/ErrorStore';

const http = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

const handleUnauthorizedError = async (error) => {
  const originalRequest = error.config;
  const refreshToken = localStorage.getItem('refresh_token');
  if (
    refreshToken &&
    error.response.status === 401 &&
    !originalRequest._retry &&
    error.config.url !== '/api/token/refresh/'
  ) {
    originalRequest._retry = true;
    try {
      const res = await http.post('/api/token/refresh/', {
        refresh: refreshToken,
      });

      if (res.status === 200) {
        originalRequest.headers.Authorization = `Bearer ${res.data.access}`;
        localStorage.setItem('access_token', res.data.access);
        return http(originalRequest);
      } else {
        localStorage.clear();
      }
    } catch (e) {
      localStorage.clear();
    }
  } else {
    toast('Auth error. please sign in again', {
      type: 'error',
    });
    localStorage.clear();
    window.location.href = '/login';
  }
};

const errorInterceptor = async (error) => {
  switch (error.response.status) {
    case 401:
      return handleUnauthorizedError(error);
    case 400:
      if (error.response.data.message) {
        toast(error.response.data.message, {
          type: 'error',
        });
      }
      ErrorStore.setErrors(error.response.data);
      await Promise.reject(error);
      break;
    case 500:
      toast('Server error!', {
        type: 'error',
      });
      break;
    default:
      toast('Something went wrong', {
        type: 'error',
      });
      break;
  }

  throw error;
};

http.interceptors.request.use((config) => {
  const token = localStorage.getItem('access_token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

http.interceptors.response.use((response) => response, errorInterceptor);

export { http };
