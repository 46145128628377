export const mixins = {
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  flexToolBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  resettingMargins: {
    padding: 0,
    margin: 0,
  },
};
