import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { AdminLayoutStore } from '@stores';
import { Filter } from 'mdi-material-ui';
import { useDataTableStyles } from '../styles';

export const TableToolbar = ({ tableTitle, competitionName }) => {
  const classes = useDataTableStyles();

  return (
    <Toolbar className={classes.rootToolbar}>
      <Typography variant="h6" id="tableTitle" component="div">
        {tableTitle}
        <Button
          onClick={AdminLayoutStore.setOpenFiltersDialog}
          className={classes.buttonToolbar}
          color="default">
          <Filter fontSize="small" /> Filter list
        </Button>
      </Typography>
      <Typography variant="h6" id="tableTitle" component="div">
        {competitionName}
      </Typography>
    </Toolbar>
  );
};
