import { http } from '@utils';

export default class AdminSectionsService {
  static fetchSectionsStatuses(id) {
    return http.get(`/api/applications/${id}/sections/status`);
  }

  static fetchSectionStatusItem({ id, section }) {
    return http.get(`/api/applications/${id}/sections/${section}`);
  }

  static patchSectionStatusItem({ id, section, status }) {
    return http.patch(
      `/api/applications/${id}/sections/${section}/${status.id}/`,
      status,
    );
  }
}
