import { observer } from 'mobx-react-lite';
import ReferencesSectionStore from '../services/ReferencesSectionStore';
import { ReferencesTable } from './ReferencesTable';

export const ReferencesSectionContent = observer(({ references }) => {
  const handlerReferencesItemClick = (referenceId) => {
    ReferencesSectionStore.setSelectedReferences(referenceId);
  };

  return (
    <ReferencesTable
      onClick={handlerReferencesItemClick}
      references={references}
    />
  );
});
