import { privateResolverWithPayload, UserService } from '@services';
import { makeAutoObservable } from 'mobx';

class UserSectionStore {
  constructor() {
    this.user = null;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setUser(downloadedUser) {
    this.user = downloadedUser;
  }

  clear() {
    this.user = null;
  }

  getUserInformation(id) {
    privateResolverWithPayload({
      fetcher: UserService.getUser,
      payload: { id },
      setter: this.setUser,
    });
  }
}

export default new UserSectionStore();
