import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import ReferenceViewDialog from './ReferenceViewDialog';

const ReferenceItem = ({ reference }) => (
  <Card>
    <CardContent>
      <ListItem>
        <ListItemAvatar>
          {reference.letter || reference.declined_reason ? (
            <CheckCircleOutlineIcon className="h-7 w-7 my-auto text-green-500" />
          ) : (
            <RadioButtonUncheckedIcon className="h-7 w-7 my-auto text-gray-500" />
          )}
        </ListItemAvatar>

        <ListItemText primary={reference.applicant_name} />

        <ListItemSecondaryAction>
          <ReferenceViewDialog reference={reference} />
        </ListItemSecondaryAction>
      </ListItem>
    </CardContent>
  </Card>
);

export default ReferenceItem;
