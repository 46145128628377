import { toast } from 'react-toastify';
import { privateResolverWithPayload } from '@services';
import { makeAutoObservable } from 'mobx';
import AdminApplicationLayoutStore from '../../../stores/AdminApplicationLayoutStore';
import ApplicationStore from '../../../stores/ApplicationStore';
import NotesService from './notes.service';

class RevisionRequestDialogStore {
  constructor() {
    this.requestContent = null;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  clear() {
    this.requestContent = null;
  }

  setContent(newRevisionRequestContent) {
    this.requestContent = newRevisionRequestContent;
  }

  async successfulExecution(message) {
    toast(message.message, { type: 'success' });
    const applicationId = ApplicationStore.verifiableApplication.id;
    await ApplicationStore.getVerifiableApplication({ id: applicationId });
    await ApplicationStore.getSectionStatus(applicationId);
  }

  getRevisionRequestContent(applicationId) {
    privateResolverWithPayload({
      fetcher: NotesService.fetchRevisionRequestContent,
      payload: applicationId,
      setter: this.setContent,
    });
  }

  sendRevisionRequestContent(applicationId, content) {
    const payload = {
      id: applicationId,
      note_ids: this.requestContent.note_ids,
      body: content,
    };

    privateResolverWithPayload({
      fetcher: NotesService.sendRevisionRequestContent,
      payload,
      setter: this.successfulExecution,
      closeDialog: AdminApplicationLayoutStore.setCloseRevisionRequestDialog,
    });
  }
}

export default new RevisionRequestDialogStore();
