import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { ApplicationStore, AuthStore, ProgressStore } from '@stores';
import { observer } from 'mobx-react-lite';

const WorkExamplesLink = observer(() => {
  const navigateToWorkExamples = async () => {
    try {
      ProgressStore.setInProgress();
      await AuthStore.refresh();
      window.location.replace(
        `${process.env.REACT_APP_API_BASE_URL}/api/workexample/session/?access=${AuthStore.accessToken}`,
      );
    } finally {
      ProgressStore.setNotInProgress();
    }
  };

  if (!ApplicationStore.application?.work_requested) {
    return null;
  }

  return (
    <li>
      <div className="user-navbar__item" onClick={navigateToWorkExamples}>
        <div>Work Examples</div>
        <RadioButtonUncheckedIcon className="user-navbar__item-icon user-navbar__item-icon_unchecked" />
      </div>
    </li>
  );
});

export default WorkExamplesLink;
