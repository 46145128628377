import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import {
  AuthRoute,
  Loader,
  UserLayout,
  UserContextLayout,
  CompetitionContextLayout,
  ApplicationContextLayout,
} from '@components';
import {
  Provider as RollbarProvider,
  ErrorBoundary as RollbarErrorBoundary,
} from '@rollbar/react';
import { ProgressStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { AdminApplicationLayout } from './components/layout/AdminApplicationLayout';
import { AdminLayout } from './components/layout/AdminLayout';
import { AdminApplicationItemScene } from './scenes/AdminApplicationItem/AdminApplicationItemScene';
import { AdminApplicationsScene } from './scenes/AdminApplications/AdminApplicationsScene';
import { AdminHomeScene } from './scenes/AdminHome/AdminHomeScene';
import { AdminReferencesScene } from './scenes/AdminReferences/AdminReferencesScene';
import {
  AddressesScene as ApplicationAddressesScene,
  ApplicationScene,
  DocumentsScene,
  EducationalSummaryScene,
  InfoScene,
  LanguagesScene,
  OrganisationsScene,
  PositionsScene,
  ReferenceScene as ApplicationReferencesScene,
  SubmitScene,
} from './scenes/Application';
import Error403 from './scenes/Errors/Error403';
import Home from './scenes/Home';
import Index from './scenes/Index';
import NoMatch from './scenes/NoMatch';
import 'react-toastify/dist/ReactToastify.css';
import { ReferencesScene } from './scenes/References';
import {
  AccountCreatedScene,
  ActivationRequestScene,
  ActivationSuccessScene,
  LoginScene,
  LogoutScene,
  PasswordResetConfirmScene,
  PasswordResetRequestScene,
  PasswordResetRequestSentScene,
  PasswordResetSuccessScene,
  RegisterScene,
} from './scenes/Sign';
import { AddressesScene, ProfileScene } from './scenes/User';

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.REACT_APP_LOGGING_MODE,
  },
};

const App = observer(() => (
  <RollbarProvider config={rollbarConfig}>
    <RollbarErrorBoundary>
      <Router>
        <Switch>
          <Route exact path="/">
            <UserLayout>
              <Index />
            </UserLayout>
          </Route>
          <Route exact path="/login">
            <UserLayout>
              <LoginScene />
            </UserLayout>
          </Route>
          <Route exact path="/register">
            <UserLayout>
              <RegisterScene />
            </UserLayout>
          </Route>
          <Route exact path="/logout">
            <UserLayout>
              <LogoutScene />
            </UserLayout>
          </Route>
          <Route exact path="/account-created">
            <UserLayout>
              <AccountCreatedScene />
            </UserLayout>
          </Route>
          <Route path="/password-reset">
            <UserLayout>
              <Switch>
                <Route exact path="/password-reset">
                  <PasswordResetRequestScene />
                </Route>
                <Route exact path="/password-reset/sent">
                  <PasswordResetRequestSentScene />
                </Route>
                <Route exact path="/password-reset/success">
                  <PasswordResetSuccessScene />
                </Route>
              </Switch>
            </UserLayout>
          </Route>
          {/* TODO: Ask Joe rename route on backend part for making nested structure */}
          <Route exact path="/reset/:uid/:token">
            <UserLayout>
              <PasswordResetConfirmScene />
            </UserLayout>
          </Route>
          {/* TODO: Ask Joe rename route on backend part for making nested structure */}
          <Route exact path="/activate/:uidb64/:token">
            <UserLayout>
              <ActivationRequestScene />
            </UserLayout>
          </Route>
          <Route exact path="/activation/success">
            <UserLayout>
              <ActivationSuccessScene />
            </UserLayout>
          </Route>
          <Route exact path="/403">
            <UserLayout>
              <Error403 />
            </UserLayout>
          </Route>
          <Route exact path="/404">
            <UserLayout>
              <NoMatch />
            </UserLayout>
          </Route>
          <AuthRoute exact path="/home">
            <UserLayout>
              <UserContextLayout>
                <CompetitionContextLayout>
                  <ApplicationContextLayout>
                    <Home />
                  </ApplicationContextLayout>
                </CompetitionContextLayout>
              </UserContextLayout>
            </UserLayout>
          </AuthRoute>
          <AuthRoute exact path="/user/profile">
            <UserLayout>
              <UserContextLayout>
                <ProfileScene />
              </UserContextLayout>
            </UserLayout>
          </AuthRoute>
          <AuthRoute exact path="/user/addresses">
            <UserLayout>
              <UserContextLayout>
                <AddressesScene />
              </UserContextLayout>
            </UserLayout>
          </AuthRoute>
          <AuthRoute exact path="/references">
            <UserLayout>
              <UserContextLayout>
                <CompetitionContextLayout>
                  <ApplicationContextLayout>
                    <ReferencesScene />
                  </ApplicationContextLayout>
                </CompetitionContextLayout>
              </UserContextLayout>
            </UserLayout>
          </AuthRoute>
          <AuthRoute path="/application">
            <UserLayout>
              <UserContextLayout>
                <CompetitionContextLayout>
                  <ApplicationContextLayout>
                    <Switch>
                      <AuthRoute exact path="/application">
                        <ApplicationScene />
                      </AuthRoute>
                      <AuthRoute exact path="/application/info">
                        <InfoScene />
                      </AuthRoute>
                      <AuthRoute exact path="/application/reference">
                        <ApplicationReferencesScene />
                      </AuthRoute>
                      <AuthRoute exact path="/application/position">
                        <PositionsScene />
                      </AuthRoute>
                      <AuthRoute exact path="/application/language">
                        <LanguagesScene />
                      </AuthRoute>
                      <AuthRoute exact path="/application/organization">
                        <OrganisationsScene />
                      </AuthRoute>
                      <AuthRoute exact path="/application/education">
                        <EducationalSummaryScene />
                      </AuthRoute>
                      <AuthRoute exact path="/application/address">
                        <ApplicationAddressesScene />
                      </AuthRoute>
                      <AuthRoute exact path="/application/document">
                        <DocumentsScene />
                      </AuthRoute>
                      <AuthRoute exact path="/application/submit">
                        <SubmitScene />
                      </AuthRoute>
                    </Switch>
                  </ApplicationContextLayout>
                </CompetitionContextLayout>
              </UserContextLayout>
            </UserLayout>
          </AuthRoute>

          <AuthRoute
            exact
            path="/administration/application-list/:applicationId"
            isAdmin>
            <AdminApplicationLayout>
              <AdminApplicationItemScene />
            </AdminApplicationLayout>
          </AuthRoute>
          <AuthRoute exact={false} path="/administration" isAdmin>
            <AdminLayout>
              <Switch>
                <AuthRoute exact path="/administration" isAdmin>
                  <AdminHomeScene />
                </AuthRoute>
                <AuthRoute
                  exact
                  path="/administration/application-list"
                  isAdmin>
                  <AdminApplicationsScene />
                </AuthRoute>
                <AuthRoute exact path="/administration/reference-list" isAdmin>
                  <AdminReferencesScene />
                </AuthRoute>
              </Switch>
            </AdminLayout>
          </AuthRoute>
          <Route path="*">
            <UserLayout>
              <NoMatch />
            </UserLayout>
          </Route>
        </Switch>
        <ToastContainer />
        {ProgressStore.progress ? <Loader message="Loading..." /> : null}
      </Router>
    </RollbarErrorBoundary>
  </RollbarProvider>
));

export default App;
