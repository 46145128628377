import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { NonFieldErrors, Select, TextField } from '@components';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { AuthStore, ErrorStore, ProgressStore, UtilsStore } from '@stores';
import { generateErrorMessages } from '@utils';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import * as Yup from 'yup';
import PasswordCriteriaDialog from './PasswordCriteriaDialog';
import TermsAndConditionsDialog from './TermsAndConditionsDialog';

const RegisterForm = observer(() => {
  const [showTermsAndConditionsDialog, setShowTermsAndConditionsDialog] =
    useState(false);
  const [showPasswordCriteriaDialog, setShowPasswordCriteriaDialog] =
    useState(false);

  useEffect(() => {
    async function fetchData() {
      ProgressStore.setInProgress();
      await UtilsStore.getCountries();
      await UtilsStore.getAccountTC();
      await UtilsStore.getMetaPasswordCriteria();
      ProgressStore.setNotInProgress();
    }

    fetchData();
    ErrorStore.dropErrors();
  }, []);

  const handlePasswordCriteriaDialogToggle = () => {
    setShowPasswordCriteriaDialog((state) => !state);
  };

  const handleTermsAndConditionsDialogOpen = () => {
    setShowTermsAndConditionsDialog(true);
  };

  const handleTermsAndConditionsDialogClose = () => {
    setShowTermsAndConditionsDialog(false);
  };

  const acceptTerms = async () => {
    handleTermsAndConditionsDialogClose();
    await AuthStore.register(formik.values);
    history.push('/account-created');
  };

  const history = useHistory();
  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      country: '',
      name: '',
      surname: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .max(15, 'Must be 15 characters or less')
        .required('Required'),
      surname: Yup.string()
        .max(50, 'Must be 50 characters or less')
        .required('Required'),
      email: Yup.string().email('Invalid email address').required('Required'),
      password: Yup.string()
        .min(8, 'Must be 8 characters and more')
        .required('Required'),
      country: Yup.string().required('Required'),
      confirmPassword: Yup.string()
        .required('Required')
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    }),
    onSubmit: async (credentials) => {
      handleTermsAndConditionsDialogOpen();
    },
  });

  return (
    <>
      {showPasswordCriteriaDialog ? (
        <PasswordCriteriaDialog
          handleClose={handlePasswordCriteriaDialogToggle}
          show={showPasswordCriteriaDialog}
        />
      ) : null}
      {showTermsAndConditionsDialog ? (
        <TermsAndConditionsDialog
          open={showTermsAndConditionsDialog}
          onClose={handleTermsAndConditionsDialogClose}
          onSave={acceptTerms}
        />
      ) : null}
      <form onSubmit={formik.handleSubmit}>
        <div className="block md:flex md:flex-row">
          <div className="px-2 my-3 w-full">
            <Select
              label={
                <label
                  htmlFor="country"
                  className="text-gray-700 dark:text-gray-200">
                  Country of Residence{' '}
                  <span className="text-red-600 mr-2">*</span>
                </label>
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.country}
              id="country"
              items={UtilsStore.countries}
              itemName="display_name"
              itemValue="value"
              defaultEmptyValue
              errors={generateErrorMessages(
                'country',
                formik,
                ErrorStore,
                'country',
              )}
            />
          </div>
        </div>
        <div className="block md:flex md:flex-row">
          <div className="w-full md:w-1/2 px-2 my-3">
            <div className="text-black">
              <TextField
                label={
                  <label
                    htmlFor="name"
                    className="text-gray-700 dark:text-gray-200">
                    First Name <span className="text-red-600 mr-2">*</span>
                  </label>
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                id="name"
                errors={generateErrorMessages(
                  'name',
                  formik,
                  ErrorStore,
                  'name',
                )}
              />
            </div>
          </div>
          <div className="w-full md:w-1/2 px-2 my-3">
            <div className="text-black">
              <TextField
                label={
                  <label
                    htmlFor="surname"
                    className="text-gray-700 dark:text-gray-200">
                    Last Name <span className="text-red-600 mr-2">*</span>
                  </label>
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.surname}
                id="surname"
                errors={generateErrorMessages(
                  'surname',
                  formik,
                  ErrorStore,
                  'surname',
                )}
              />
            </div>
          </div>
        </div>
        <div className="block md:flex md:flex-row">
          <div className="w-full md:w-1/2 px-2 my-3">
            <div className="text-black">
              <TextField
                label={
                  <label
                    htmlFor="email"
                    className="text-gray-700 dark:text-gray-200">
                    Email <span className="text-red-600 mr-2">*</span>
                  </label>
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                id="email"
                errors={generateErrorMessages(
                  'email',
                  formik,
                  ErrorStore,
                  'email',
                )}
              />
            </div>
          </div>
          <div className="w-full md:w-1/2 px-2 my-3">
            <div className="text-black">
              <TextField
                label={
                  <label
                    htmlFor="password"
                    className="text-gray-700 dark:text-gray-200">
                    Password <span className="text-red-600 mr-2">*</span>
                    <HelpOutlineIcon
                      onClick={handlePasswordCriteriaDialogToggle}
                      className="h-16 w-16 text-gray-500 cursor-pointer"
                    />
                  </label>
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                id="password"
                type="password"
                errors={generateErrorMessages(
                  'password',
                  formik,
                  ErrorStore,
                  'password',
                )}
              />
            </div>
          </div>
        </div>
        <div className="block md:flex md:flex-row justify-end">
          <div className="w-full md:w-1/2 px-2 my-3">
            <div className="text-black">
              <TextField
                label={
                  <label
                    htmlFor="confirmPassword"
                    className="text-gray-700 dark:text-gray-200">
                    Confirm Password{' '}
                    <span className="text-red-600 mr-2">*</span>
                  </label>
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
                id="confirmPassword"
                type="password"
                errors={generateErrorMessages(
                  'confirmPassword',
                  formik,
                  ErrorStore,
                  'confirmPassword',
                )}
              />
            </div>
          </div>
        </div>

        <NonFieldErrors />
        <div className="text-center my-8">
          <button
            type="submit"
            className="px-8 py-3 text-2xl bg-primary text-white rounded-md">
            Sign Up
          </button>
        </div>
      </form>
    </>
  );
});

export default RegisterForm;
