import { useState } from 'react';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { AdminLayoutStore } from '@stores';
import { colors, fontSizes } from '@styles';
import { observer } from 'mobx-react-lite';
import { InputTextField } from '../../InputFields';

const useStyles = makeStyles({
  dialogWrapper: {
    padding: '16px 0',
  },
  dialogActions: {
    background: colors.gray,
    textTransform: 'none',
    color: colors.black,
    margin: '0 8px',
  },
  fieldLabel: {
    flexGrow: 1,
    fontWeight: 300,
    fontSize: 13,
    marginBottom: '5px',
  },
});

const StyledTextField = withStyles({
  root: {
    '& .MuiInputBase-input': {
      height: '11px',
      fontSize: `${fontSizes.font_size_s}`,
    },
    '& .MuiSelect-selectMenu': {
      height: '16px',
      fontSize: `${fontSizes.font_size_s}`,
      minHeight: 'auto',
      padding: '7px 10px 9px',
    },
    '& .MuiSelect-nativeInput': {
      height: '100%',
    },
  },
})(TextField);

export const FiltersDialog = observer(({ urlParams, filters, setFilters }) => {
  const classes = useStyles();
  const initialState = Object.fromEntries(
    filters.map((filter) => [
      filter.filterName,
      urlParams.get(filter.filterName) || '',
    ]),
  );
  const [data, setData] = useState(initialState);

  const renderInputField = (filter) => {
    switch (filter.type) {
      case 'boolean':
        return (
          <Grid item xs={12} md={6} key={filter.filterName}>
            <FormControlLabel
              key={filter.filterName}
              control={
                <Checkbox
                  size="small"
                  checked={Boolean(data[filter.filterName])}
                  onChange={checkboxInputHandler}
                  color="default"
                  name={filter.filterName}
                />
              }
              label={filter.label}
            />
          </Grid>
        );
      case 'date':
        return (
          <Grid item xs={12} md={6} key={filter.filterName}>
            <Typography variant="subtitle1" className={classes.fieldLabel}>
              {filter.label}
            </Typography>
            <StyledTextField
              variant="outlined"
              id="date"
              size="small"
              type="date"
              name={filter.filterName}
              format="mm-dd-yyyy"
              value={data[filter.filterName]}
              onChange={inputFieldHandler}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        );
      case 'select':
        return (
          <Grid item xs={12} md={6} key={filter.filterName}>
            <Typography variant="subtitle1" className={classes.fieldLabel}>
              {filter.label}
            </Typography>
            <StyledTextField
              autoFocus
              select
              size="small"
              name={filter.filterName}
              variant="outlined"
              value={data[filter.filterName]}
              fullWidth>
              <MenuItem
                value=""
                defaultValue
                onClick={() => selectFieldHandler(filter.filterName, '')}>
                ---
              </MenuItem>
              {filter.cases.map((item) => (
                <MenuItem
                  key={item[filter.caseValue]}
                  value={item[filter.caseValue]}
                  dense
                  onClick={() =>
                    selectFieldHandler(
                      filter.filterName,
                      item[filter.caseValue],
                    )
                  }>
                  {item[filter.caseName]}
                </MenuItem>
              ))}
            </StyledTextField>
          </Grid>
        );
      default:
        return (
          <Grid item xs={12} md={6} key={filter.filterName}>
            <InputTextField
              label={filter.label}
              name={filter.filterName}
              value={data[filter.filterName]}
              onChange={inputFieldHandler}
              readOnly={false}
            />
          </Grid>
        );
    }
  };

  const inputFieldHandler = (event) => {
    const { name, value } = event.target;
    selectFieldHandler(name, value);
  };

  const checkboxInputHandler = (event) => {
    const { name, checked } = event.target;
    selectFieldHandler(name, checked);
  };

  const selectFieldHandler = (fieldName, selectedValue) => {
    setData({ ...data, [fieldName]: selectedValue });
  };

  const onFormSubmit = (event) => {
    event.preventDefault();
    Object.keys(data).forEach((selectedName) =>
      setFilters(selectedName, data[selectedName]),
    );
    setFilters('page', '1');
    AdminLayoutStore.setCloseFiltersDialog();
  };

  const clearFilters = () => {
    setData(initialState);
    Object.keys(data).forEach((selectedName) => setFilters(selectedName, ''));
    setFilters('page', '1');
    AdminLayoutStore.setCloseFiltersDialog();
  };

  return (
    <Dialog
      fullScreen={AdminLayoutStore.isMobile}
      open={AdminLayoutStore.showFiltersDialog}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Filters</DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={1}
          justify="space-around"
          alignItems="flex-end">
          {filters.map(renderInputField)}
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogWrapper}>
        <Grid container justify="center" alignItems="center">
          <Button
            className={classes.dialogActions}
            onClick={AdminLayoutStore.setCloseFiltersDialog}
            color="primary">
            Cancel
          </Button>
          <Button
            className={classes.dialogActions}
            onClick={clearFilters}
            color="primary">
            Clear Filters
          </Button>
          <Button
            className={classes.dialogActions}
            onClick={onFormSubmit}
            color="primary">
            Filter
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
});
