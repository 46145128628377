import { useEffect } from 'react';
import { Loader } from '@components';
import { ApplicationStore } from '@stores';
import { observer } from 'mobx-react-lite';
import ApplicationNoteDialogStore from '../../services/ApplicationNoteDialogStore';
import { ApplicationNoteContent } from './ApplicationNoteContent';

export const ApplicationNoteDialog = observer(() => {
  useEffect(() => {
    const applicationId = ApplicationStore.verifiableApplication.id;
    ApplicationNoteDialogStore.getApplicationNote(applicationId);

    return () => {
      ApplicationNoteDialogStore.clear();
    };
  }, []);

  return (
    <>
      {ApplicationNoteDialogStore.statusType &&
      ApplicationNoteDialogStore.applicationStatusNote ? (
        <ApplicationNoteContent
          applicationId={ApplicationStore.verifiableApplication.id}
        />
      ) : (
        <Loader message="Loading..." />
      )}
    </>
  );
});
