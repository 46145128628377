import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { AdminApplicationLayoutStore, ApplicationStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { tabsCard } from '../../services/constants';
import { useApplicationCardStyles } from './ApplicationCardStyles';

export const ApplicationCard = observer(() => {
  const classes = useApplicationCardStyles();
  const { sectionStatus, verifiableApplication } = ApplicationStore;

  const sectionClickHandler = (tabHandler) => {
    AdminApplicationLayoutStore[tabHandler]();
  };

  const renderTabs = (tab) => (
    <div
      key={`tab_${tab.fieldLabel}_${sectionStatus[tab.fieldName].id}`}
      className={classes.tabItem}
      onClick={() => sectionClickHandler(tab.handler)}>
      {tab.fieldLabel}
      {sectionStatus[tab.fieldName].editable ? (
        <img
          className={classes.lockIcon}
          alt="Unlock"
          src="/assets/icons/Unlock.svg"
        />
      ) : (
        <img
          className={classes.lockIcon}
          alt="Lock"
          src="/assets/icons/lock-closed.svg"
        />
      )}
      {sectionStatus[tab.fieldName].editable_pending ? (
        <div className={classes.secondaryText}>Pending request</div>
      ) : null}
    </div>
  );

  const renderTabsCheckboxes = (tab) => (
    <div key={`chbx_${tab.fieldName}`} className={classes.tabItemCheckbox}>
      <Checkbox disabled checked={sectionStatus[tab.fieldName]?.approved} />
    </div>
  );

  return (
    <Grid container justify="center" className={classes.contentWrapper}>
      <Grid
        item
        xs={10}
        md={3}
        className={
          AdminApplicationLayoutStore.isMobile ? null : classes.tabsMenu
        }>
        <Typography className={classes.columnTitleEditable}>
          Access Locked
        </Typography>
        {tabsCard.map(renderTabs)}
      </Grid>
      <Grid item xs={2} md={1} className={classes.menuWrapper}>
        <Typography className={classes.columnTitleReview}>
          Review Complete
        </Typography>
        {tabsCard.map(renderTabsCheckboxes)}
      </Grid>
      <Grid item xs={12} md={8}>
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.fullSize}>
          <Grid item xs={6}>
            <Grid container justify="flex-start" alignItems="flex-start">
              <Grid item xs={4}>
                Project title:
              </Grid>
              <Grid item xs={8}>
                {verifiableApplication.info.project_title}
              </Grid>
              <Grid item xs={4}>
                Project fields:
              </Grid>
              <Grid item xs={8}>
                {verifiableApplication.info.project_field_1_name}
                <br />
                {verifiableApplication.info.project_field_2_name}
              </Grid>
              <Grid item xs={4}>
                Websites(s):
              </Grid>
              <Grid item xs={8}>
                {verifiableApplication.info.website_1}
                <br />
                {verifiableApplication.info.website_2}
              </Grid>
              <Grid item xs={4}>
                Joint Applicant Name:
              </Grid>
              <Grid item xs={8}>
                {verifiableApplication.info.joint_applicant_name}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});
