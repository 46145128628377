import { ApplicationStore } from '@stores';

export const DownloadApplicationPdf = ({
  innerText,
  showHelperText = false,
}) => {
  const handleApplicationDownload = async () => {
    await ApplicationStore.getCompletedApplicationPDF();
  };

  return (
    <div className="text-center">
      {showHelperText ? (
        <div className="mb-1 mt-5 text-s">
          You may download a copy of your application for your review at any
          time.
        </div>
      ) : null}
      <button
        type="button"
        onClick={handleApplicationDownload}
        className="w-max mt-2 px-8 py-1 text-md text-white rounded-md text-center mx-2 mx-auto bg-primary">
        {innerText}
      </button>
    </div>
  );
};
