import { http } from '@utils';

export default class UserService {
  static getUser({ id }) {
    return http.get(`/api/users/${id}/`);
  }

  static getCurrentUser() {
    return http.get('/api/users/current/');
  }

  static patchUser(updatableUser) {
    return http.patch(`api/users/${updatableUser.id}/`, updatableUser);
  }

  static putUser(user) {
    return http.put(`api/users/${user.id}/`, user);
  }

  static getUserPersonalInfoOptions({ id }) {
    return http.options(`/api/users/${id}/`);
  }

  static getUserAddresses({ id }) {
    return http.get(`/api/users/${id}/addresses/`);
  }

  static getUserHomeAddress({ id }) {
    return http.get(`/api/users/${id}/addresses/home/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    });
  }

  static getUserHomeAddressOptions({ id }) {
    return http.options(`/api/users/${id}/addresses/home/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    });
  }

  static getUserOfficeAddress({ id }) {
    return http.get(`/api/users/${id}/addresses/office/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    });
  }

  static getUserReferences({ userId }) {
    return http.get(`/api/users/${userId}/references/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    });
  }

  static deleteUserOfficeAddress({ id }) {
    return http.delete(`/api/users/${id}/addresses/office/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    });
  }

  static deleteUserHomeAddress({ id }) {
    return http.delete(`/api/users/${id}/addresses/home/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    });
  }

  static getUserOfficeAddressOptions({ id }) {
    return http.options(`/api/users/${id}/addresses/office/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    });
  }

  static createUserHomeAddress({ id, body }) {
    return http.post(`/api/users/${id}/addresses/home/`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    });
  }

  static createUserOfficeAddress({ id, body }) {
    return http.post(`/api/users/${id}/addresses/office/`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    });
  }

  static patchUserHomeAddress({ id, body }) {
    return http.patch(`/api/users/${id}/addresses/home/`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    });
  }

  static patchUserOfficeAddress({ id, body }) {
    return http.patch(`/api/users/${id}/addresses/office/`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    });
  }

  static preferOfficeAddress({ userId }) {
    return http.patch(
      `/api/users/${userId}/addresses/office/`,
      {
        preferred: true,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      },
    );
  }

  static unpreferOfficeAddress({ userId }) {
    return http.patch(
      `/api/users/${userId}/addresses/office/`,
      {
        preferred: false,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      },
    );
  }

  static preferHomeAddress({ userId }) {
    return http.patch(
      `/api/users/${userId}/addresses/home/`,
      {
        preferred: true,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      },
    );
  }

  static unpreferHomeAddress({ userId }) {
    return http.patch(
      `/api/users/${userId}/addresses/home/`,
      {
        preferred: false,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      },
    );
  }

  static createUser({ name, surname, email, password, country }) {
    return http.post('/api/users/', {
      first_name: name,
      email,
      last_name: surname,
      password,
      country,
    });
  }
}
