import { privateResolverWithPayload } from '@services';
import { makeAutoObservable } from 'mobx';
import { Reference } from '../models/Reference';
import ReferencesSectionService from './referencesSection.service';

class ReferencesSectionStore {
  constructor() {
    this.references = null;
    this.selectedReferences = null;
    this.showReferencesDetailsDialog = false;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  clear() {
    this.references = null;
    this.selectedReferences = null;
    this.institutionTypes = null;
  }

  clearSelectedReferences() {
    this.selectedReferences = null;
  }

  setOpenReferencesDetailsDialog() {
    this.showReferencesDetailsDialog = true;
  }

  setCloseReferencesDetailsDialog() {
    this.showReferencesDetailsDialog = false;
  }

  setSelectedReferences(id) {
    this.selectedReferences = this.references.find(
      (referencesItem) => referencesItem.id === id,
    );
    this.setOpenReferencesDetailsDialog();
  }

  setReferences(references) {
    this.references = references.results.map(
      (reference) => new Reference(reference),
    );
  }

  getReferences(applicationId) {
    privateResolverWithPayload({
      fetcher: ReferencesSectionService.fetchReferences,
      payload: applicationId,
      setter: this.setReferences,
    });
  }
}

export default new ReferencesSectionStore();
