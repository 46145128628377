import { http } from '@utils';

export default class DocumentsSectionService {
  static fetchDocuments(id) {
    return http.get(`/api/applications/${id}/documents/all/`);
  }

  static patchDocument(payload) {
    return http.patch(`/api/documents/${payload.id}/`, {
      editable: payload.editable,
      reviewed_status: payload.reviewed_status,
      approved: payload.approved,
    });
  }

  static async fetchDocumentReviewedStatus(id) {
    const response = await http.options(
      `/api/applications/${id}/documents/all/`,
    );
    return response.data;
  }
}
