import { CompetitionService, plainRequestPrivateResolver } from '@services';
import { makeAutoObservable } from 'mobx';
import Competition from './Competition';

class CompetitionStore {
  constructor() {
    this.currentCompetition = null;
    this.competitions = [];
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setCurrentCompetition(competition) {
    this.currentCompetition = new Competition(competition[0]);
  }

  setCompetitions(downloadedCompetitions) {
    this.competitions = downloadedCompetitions.map(
      (competition) => new Competition(competition),
    );
  }

  getCompetitions() {
    plainRequestPrivateResolver({
      fetcher: CompetitionService.getCompetitions,
      setter: this.setCompetitions,
    });
  }

  getCurrentCompetition() {
    plainRequestPrivateResolver({
      fetcher: CompetitionService.getCurrentCompetition,
      setter: this.setCurrentCompetition,
    });
  }
}

export default new CompetitionStore();
