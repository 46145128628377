import { useEffect, useState } from 'react';
import { AuthStore, UserStore } from '@stores';

const LogoutScene = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AuthStore.logout();
    UserStore.clearCurrentUser();
    setLoading(false);
  }, []);

  return loading ? null : (
    <div className="text-center text-black font-calibri font-normal mt-64">
      <h1 className="text-5xl">You have been successfully logged out.</h1>
    </div>
  );
};

export default LogoutScene;
