import { useEffect } from 'react';
import { Loader, DataTable, ReferencesTableBody } from '@components';
import { AdminLayoutStore, CompetitionStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { ReferenceDetailsDialog } from './components/ReferenceDetailsDialog';
import {
  referenceColumns,
  referenceDisplayStatuses,
  referenceFilterFields,
} from './services/constants';
import ReferencesStore from './services/ReferencesStore';

export const AdminReferencesScene = observer(() => {
  useEffect(() => {
    AdminLayoutStore.setPageTitle('REFERENCE PROCESSING');
    CompetitionStore.getCurrentCompetition();
    ReferencesStore.getReferenceOptions();
  }, []);

  const fetchReferencesListCallback = (queryParams) => {
    ReferencesStore.getReferencesList({ current: true, ...queryParams });
  };

  const filters = [
    {
      filterName: 'status',
      label: 'Status',
      type: 'select',
      cases: referenceDisplayStatuses,
      caseValue: 'name',
      caseName: 'name',
    },
    ...referenceFilterFields,
  ];

  return (
    <>
      {ReferencesStore.showReferenceDetailsDialog ? (
        <ReferenceDetailsDialog />
      ) : null}
      {ReferencesStore.reviewedStatusOptions ? (
        <DataTable
          competitionName={CompetitionStore.currentCompetition?.name}
          headCells={referenceColumns}
          rows={ReferencesStore.referenceList}
          filters={filters}
          fetchListCallback={fetchReferencesListCallback}
          clearListCallback={ReferencesStore.clear}
          totalCount={ReferencesStore.totalCount}
          TableBodyComponent={ReferencesTableBody}
          tableTitle="References"
          onClickRow={ReferencesStore.setOpenReferenceDetailsDialog}
        />
      ) : (
        <Loader message="Loading Options..." />
      )}
    </>
  );
});
