import { Redirect } from 'react-router-dom';
import { AuthStore } from '@stores';
import { observer } from 'mobx-react-lite';
import RegisterForm from './components/RegisterForm';

const RegisterScene = observer(() => {
  if (AuthStore.isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <div className="bg-gray-100 rounded-md mx-auto max-w-2xl px-8 py-4 md:mt-20">
      <h1 className="text-5xl text-black font-calibri font-normal text-center my-6">
        Create Account
      </h1>
      <RegisterForm />
    </div>
  );
});

export default RegisterScene;
