function PasswordResetSentScene() {
  return (
    <div className="text-center text-black font-calibri font-normal mt-64">
      <h1 className="text-5xl">Password reset email sent.</h1>
      <p className="mt-4 text-3xl">
        Please check your email to continue resetting your password
      </p>
    </div>
  );
}

export default PasswordResetSentScene;
