import { makeAutoObservable } from 'mobx';

export class Position {
  constructor(positionItem) {
    this.id = positionItem.id;
    this.fm_id = positionItem.fm_id;
    this.application = positionItem.application;
    this.position_title = positionItem.position_title;
    this.institution = positionItem.institution;
    this.institution_other = positionItem.institution_other;
    this.department = positionItem.department;
    this.start_and_end_dates = positionItem.start_and_end_dates;
    this.institution_type = positionItem.institution_type;
    this.date_created = positionItem.date_created;
    this.date_last_modified = positionItem.date_last_modified;
    makeAutoObservable(this, {}, { autoBind: true });
  }
}
