import { makeAutoObservable, runInAction } from 'mobx';
import AuthStore from '../../../stores/AuthStore';
import Note from '../models/Note';
import NotesService from './notes.service';

class ApplicationNoteDialogStore {
  constructor() {
    this.showApplicationNoteDialog = false;
    this.statusType = '';
    this.statusName = '';
    this.disabledNote = false;
    this.applicationStatusNote = null;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setOpenNoteDialog(name, type) {
    this.statusName = name;
    this.statusType = type;
    this.showApplicationNoteDialog = true;
  }

  setCloseNoteDialog() {
    this.showApplicationNoteDialog = false;
  }

  clear() {
    this.statusName = '';
    this.statusType = '';
    this.disabledNote = false;
    this.applicationStatusNote = null;
  }

  setNote(newNote) {
    if (newNote) {
      return (this.applicationStatusNote = new Note(newNote));
    }
    return (this.applicationStatusNote = {});
  }

  async getApplicationNote(applicationId) {
    const result = await NotesService.fetchApplicationNoteItem(
      this.statusType,
      applicationId,
    );
    runInAction(() => {
      this.setNote(result.data[0]);
    });
  }

  async createApplicationNote(note, applicationId) {
    await NotesService.createNoteItem({
      user: AuthStore.id,
      note: note,
      application: applicationId,
      type: this.statusType,
    });
  }
}

export default new ApplicationNoteDialogStore();
