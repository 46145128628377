import withStyles from '@material-ui/core/styles/withStyles';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { observer } from 'mobx-react-lite';
import { useDataTableStyles } from '../styles';
import { StatusCell } from './StatusCell';

const StyledTableCell = withStyles(() => ({
  body: {
    padding: '4px 9px',
    width: '21%',
  },
}))(TableCell);

export const ApplicationsTableBody = observer(({ rows, onClickRow }) => {
  const classes = useDataTableStyles();

  return (
    <TableBody>
      {rows?.map((row) => (
        <TableRow
          onClick={() => onClickRow(row.id)}
          hover
          className={classes.tableRow}
          key={row.id}>
          <StyledTableCell align="left">
            {row.applicant.first_name} {row.applicant.last_name}
          </StyledTableCell>
          <StyledTableCell align="left">
            {row.info.project_title}
          </StyledTableCell>
          <StyledTableCell align="left">
            {row.info.project_field_1_name}
          </StyledTableCell>
          <StyledTableCell align="left">
            {row.info.project_field_2_name}
          </StyledTableCell>
          <StatusCell status={row.display_status} />
        </TableRow>
      ))}
    </TableBody>
  );
});
