import { privateResolverWithPayload } from '@services';
import { makeAutoObservable } from 'mobx';
import { SectionStatus } from '../models/SectionStatus';
import AdminSectionsService from './adminSections.service';

class SectionStatusesStore {
  constructor() {
    this.sectionStatuses = null;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  clear() {
    this.sectionStatuses = null;
  }

  setSectionStatuses(statuses) {
    this.sectionStatuses = new SectionStatus(statuses[0]);
  }

  getSectionStatuses(applicationId, sectionName) {
    const payload = { id: applicationId, section: sectionName };
    privateResolverWithPayload({
      fetcher: AdminSectionsService.fetchSectionStatusItem,
      payload,
      setter: this.setSectionStatuses,
    });
  }
}

export default new SectionStatusesStore();
