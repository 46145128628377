import { makeAutoObservable } from 'mobx';

export class Education {
  constructor(education) {
    this.id = education.id;
    this.fm_id = education.fm_id;
    this.application = education.application;
    this.institution = education.institution;
    this.institution_other = education.institution_other;
    this.level = education.level;
    this.degree = education.degree;
    this.period_of_study = education.period_of_study;
    this.note = education.note;
    this.date_created = education.date_created;
    this.date_last_modified = education.date_last_modified;
    makeAutoObservable(this, {}, { autoBind: true });
  }
}
