import { privateResolverWithPayload } from '@services';
import { makeAutoObservable } from 'mobx';
import ErrorStore from '../../../../../stores/ErrorStore';
import { Address } from '../models/Address';
import AddressesSectionService from './addressesSection.service';

class AddressesSectionStore {
  constructor() {
    this.addresses = null;
    this.addressesTypes = null;
    this.selectedAddress = null;
    this.showAddressDetailsDialog = false;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setOpenAddressItemDialog() {
    this.showAddressDetailsDialog = true;
  }

  setCloseAddressItemDialog() {
    this.showAddressDetailsDialog = false;
  }

  getAddressName(type) {
    return this.addressesTypes.find((addressType) => addressType.value === type)
      ?.display_name;
  }

  clear() {
    this.addresses = null;
  }

  clearSelectedAddress() {
    this.selectedAddress = null;
  }

  setAddresses(downloadedAddresses) {
    downloadedAddresses.push(
      ...downloadedAddresses.splice(
        downloadedAddresses.findIndex(
          (item) => item.address.address_type === 'of',
        ),
        1,
      ),
    );
    this.addresses = downloadedAddresses.map((address) => new Address(address));
  }

  setSelectedAddress(address) {
    this.selectedAddress = address;
  }

  setAddressesTypes(types) {
    this.addressesTypes = types;
  }

  getAddresses(applicationId) {
    privateResolverWithPayload({
      fetcher: AddressesSectionService.fetchAddresses,
      payload: applicationId,
      setter: this.setAddresses,
    });
  }

  getAddressesTypes(applicationId) {
    ErrorStore.dropErrors();
    AddressesSectionService.fetchAddressesTypes(applicationId).then((data) => {
      const types = data.actions.POST.address.children.address_type.choices;
      this.setAddressesTypes(types);
    });
  }
}

export default new AddressesSectionStore();
