import { http } from '@utils';

export default class ApplicationService {
  static getApplications({ userId }) {
    return http.get(`/api/users/${userId}/applications/`);
  }

  static getCurrentApplication({ userId }) {
    return http.get(`/api/users/${userId}/applications/current/`);
  }

  static downloadCompletedApplicationPDF({ id }) {
    return http.get(`/api/applications/${id}/export/`, {
      responseType: 'blob',
    });
  }

  static getCurrentApplicationSectionsStatuses({ applicationId }) {
    return http.get(`/api/applications/${applicationId}/sections/status/`);
  }

  static getCurrentApplicationSection({ applicationId, section }) {
    return http.get(`/api/applications/${applicationId}/sections/${section}/`);
  }

  static updateApplicationSection({ body, section, applicationId, id }) {
    return http.put(
      `/api/applications/${applicationId}/sections/${section}/${id}/`,
      body,
    );
  }

  static getCurrentApplicationSectionItem({ applicationId, section }) {
    return http.get(`/api/applications/${applicationId}/${section}/`);
  }

  static getCurrentApplicationSectionOptions({ applicationId, section }) {
    return http.options(`/api/applications/${applicationId}/${section}/`);
  }

  static createApplicationSectionItem({ body, section, applicationId }) {
    return http.post(`/api/applications/${applicationId}/${section}/`, body);
  }

  static updateApplicationSectionItem({ body, section, applicationId, id }) {
    return http.put(
      `/api/applications/${applicationId}/${section}/${id}/`,
      body,
    );
  }

  static deleteApplicationSectionItem({ id, section, applicationId }) {
    return http.delete(`/api/applications/${applicationId}/${section}/${id}/`);
  }

  static createApplication({ body }) {
    return http.post('/api/applications/', body);
  }

  static patchApplicationStatus(name, value, applicationId) {
    return http.patch(`api/applications/${applicationId}/`, { [name]: value });
  }

  static patchApplication({ id, ...fields }) {
    return http.patch(`api/applications/${id}/`, fields);
  }

  static getApplicationList(payload) {
    return http.get('/api/applications/', {
      params: payload,
    });
  }

  static getApplicationItem(id) {
    return http.get(`/api/applications/${id}/`);
  }

  static getApplicationItemInfo({ id }) {
    return http.get(`/api/applications/${id}/info/`);
  }

  static getApplicationDocument({ applicationId, type }) {
    return http.get(`/api/applications/${applicationId}/documents/${type}/`);
  }

  static createApplicationDocument({ applicationId, type, body }) {
    return http.post(
      `/api/applications/${applicationId}/documents/${type}/`,
      body,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  }

  static patchApplicationDocument({ body, id }) {
    return http.patch(`/api/documents/${id}/`, body);
  }

  static deleteApplicationDocument({ applicationId, type, id }) {
    return http.delete(
      `/api/applications/${applicationId}/documents/${type}/${id}/`,
    );
  }

  static submitApplication({ applicationId }) {
    return http.post(`/api/applications/${applicationId}/submit/`, null);
  }
}
