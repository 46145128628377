import { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { AdminApplicationLayoutStore, ApplicationStore } from '@stores';
import { useIsMobile } from '@styles';
import { observer } from 'mobx-react-lite';
import { ApplicationCard } from './components';
import { ApplicationAppBar } from './components/ApplicationAppBar/ApplicationAppBar';
import { AdminApplicationItemStyles } from './services/styles';

export const AdminApplicationItemScene = observer(() => {
  const classes = AdminApplicationItemStyles();
  const {
    verifiableApplication,
    disabledApprovedStatus,
    updateVerifiableApplication,
  } = ApplicationStore;
  const isMobile = useIsMobile();
  const { setOpenRevisionRequestDialog } = AdminApplicationLayoutStore;
  const approvedStatus = verifiableApplication.display_status === 'Approved';

  const disabledApplicationStatuses =
    verifiableApplication.on_hold ||
    verifiableApplication.withdrawn ||
    verifiableApplication.approved ||
    verifiableApplication.rejected;

  const showRevisionRequestButton =
    verifiableApplication.pending_revision_request &&
    !disabledApplicationStatuses;

  useEffect(() => {
    if (disabledApprovedStatus) {
      updateVerifiableApplication({
        id: verifiableApplication.id,
        approved: false,
      });
    }
  }, [
    disabledApprovedStatus,
    updateVerifiableApplication,
    verifiableApplication.id,
  ]);

  return (
    <>
      <ApplicationAppBar
        approvedStatus={approvedStatus}
        disabledApplicationStatuses={disabledApplicationStatuses}
      />
      <Grid container className={classes.contentWrapper}>
        <div className={classes.applicationPageWrapper}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item xs={12} md={7}>
              <Typography variant="h5">
                {verifiableApplication.fullNameApplicant}
              </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              {showRevisionRequestButton ? (
                <Button
                  onClick={setOpenRevisionRequestDialog}
                  className={classes.revisionRequestButton}>
                  Send Revision Request
                </Button>
              ) : null}
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              className={isMobile ? null : classes.textPosition}>
              <Typography
                variant="h5"
                className={
                  approvedStatus
                    ? classes.displayStatusApproved
                    : classes.displayStatus
                }>
                {verifiableApplication.display_status}
              </Typography>
            </Grid>
          </Grid>
          <ApplicationCard />
        </div>
      </Grid>
    </>
  );
});
