import { useEffect, useRef, useState } from 'react';
import { DownloadApplicationPdf } from '@components';
import { Card, CardContent, CircularProgress } from '@material-ui/core';
import { ApplicationStore, CompetitionStore, UtilsStore } from '@stores';
import { observer } from 'mobx-react-lite';

const ApplicationSubmittedCard = observer(() => {
  const [loading, setLoading] = useState(true);
  const textRef = useRef();

  useEffect(() => {
    async function fetchData() {
      await CompetitionStore.getCurrentCompetition();
      await ApplicationStore.getCurrentUserApplication();
      await UtilsStore.getMetaTexts();

      setLoading(false);
      textRef.current.innerText =
        UtilsStore.metaTexts?.filter(
          (item) => item.name === 'application-final-submit',
        )[0].value ?? '';
    }

    fetchData();
  }, []);

  const renderCardContent = () => (
    <div>
      <h1 className="text-center text-green-600 my-2 text-3xl">
        Application Submitted!
      </h1>
      <h2 className="my1-4">{CompetitionStore.currentCompetition.name}</h2>
      <h2 className="my-1">
        {ApplicationStore.application.applicant.first_name}&nbsp;
        {ApplicationStore.application.applicant.last_name}
      </h2>
      <p className="text-md p-4" ref={textRef} />
      <DownloadApplicationPdf innerText="Download Completed Application (PDF)" />
    </div>
  );

  return (
    <>
      <Card>
        <CardContent>
          {loading ? <CircularProgress /> : renderCardContent()}
        </CardContent>
      </Card>
    </>
  );
});

export default ApplicationSubmittedCard;
