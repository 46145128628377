import { ApplicationService } from '@services';
import { makeAutoObservable } from 'mobx';
import ApplicationStore from '../ApplicationStore';

class SectionsStore {
  constructor() {
    this.sections = null;
    makeAutoObservable(this);
  }

  async getApplicationSectionsStatuses() {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    await ApplicationService.getCurrentApplicationSectionsStatuses({
      applicationId: ApplicationStore.application.id,
    }).then((resp) => (this.sections = resp.data.section_status));
  }

  isSectionsAcknowledged() {
    if (!this.sections) {
      return false;
    }
    return !Object.keys(this.sections).some(
      (key) => key !== 'user' && !this.sections[key].acknowledged,
    );
  }
}

export default new SectionsStore();
