import { useState } from 'react';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { AuthStore } from '@stores';
import { useFormik } from 'formik';
import ReferencesStore from '../services/ReferencesStore';

const ReferenceAcceptDialog = ({ reference, open, onClose }) => {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      document: null,
    },
    onSubmit: async (values) => {
      await setLoading(true);

      try {
        if (values.document.type !== 'application/pdf') {
          toast('Only PDF files allowed!', {
            type: 'error',
          });
          return;
        }

        if (values.document.size / 1024 / 1024 >= 3) {
          toast('Document exceeds file size limit 3MB!', {
            type: 'error',
          });
          return;
        }
        await ReferencesStore.acceptReference({
          userId: AuthStore.id,
          document: values.document,
          referenceId: reference.id,
        });
      } finally {
        await setLoading(false);
      }
    },
  });

  return (
    <Dialog open={open} onClose={onClose} disableBackdropClick maxWidth="lg">
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Reference Upload</DialogTitle>
        <DialogContent>
          <div className="p-4">
            <input
              required
              id="document"
              type="file"
              onChange={(event) => {
                formik.setFieldValue('document', event.currentTarget.files[0]);
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={loading}
            type="submit"
            color="primary"
            variant="contained">
            {loading ? <CircularProgress /> : 'Save'}
          </Button>
          <Button disabled={loading} type="button" onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ReferenceAcceptDialog;
