import { ApplicationService, privateResolverWithPayload } from '@services';
import { makeAutoObservable } from 'mobx';
import { ApplicationInfo } from '../models/ApplicationInfo';

class ApplicationSectionStore {
  constructor() {
    this.applicationInfo = null;
    this.groupsets = null;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  clear() {
    this.applicationInfo = null;
  }

  setApplicationInfo(info) {
    this.applicationInfo = new ApplicationInfo(info[0]);
  }

  getApplicationInfo(applicationId) {
    const payload = { id: applicationId };
    privateResolverWithPayload({
      fetcher: ApplicationService.getApplicationItemInfo,
      payload,
      setter: this.setApplicationInfo,
    });
  }
}

export default new ApplicationSectionStore();
