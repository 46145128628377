import { useEffect, useState } from 'react';
import {
  NonFieldErrors,
  HelpInfo,
  InstitutionDialog,
  Select,
  TextField,
} from '@components';
import { CircularProgress } from '@material-ui/core';
import { ErrorStore, SectionsStore, UtilsStore } from '@stores';
import { generateErrorMessages } from '@utils';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import { v4 } from 'uuid';
import * as Yup from 'yup';
import { ReferencesStore } from '../services';

const ReferencesForm = observer(({ fields, submitHandler }) => {
  const [showInstitution, setShowInstitution] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      await ReferencesStore.getApplicationReferencesOptions();
      await UtilsStore.getInstitutions();
    }

    fetchData();
    ErrorStore.dropErrors();
  }, []);

  const values = {
    addressLine1: fields?.address.address_line_1 ?? '',
    addressLine2: fields?.address.address_line_2 ?? '',
    city: fields?.address.city ?? '',
    state: fields?.address.state ?? '',
    zip: fields?.address.zip ?? '',
    country: fields?.address.country ?? '',
    phoneNumber: fields?.address.phone_number ?? '',
    phoneType: fields?.address.phone_type ?? '',
    title: fields?.address.title ?? '',
    institution: fields?.address.institution?.id ?? '',
    institutionOther: fields?.address?.institution_other ?? '',
    institutionType: 'I',
    department: fields?.address.department ?? '',
    hall: fields?.address.hall ?? '',
    addressType: 'of',
    email: fields?.address.email ?? '',
    namePrefix: fields?.address.name_prefix ?? '',
    nameFirst: fields?.address.name_first ?? '',
    nameMiddle: fields?.address.name_middle ?? '',
    nameLast: fields?.address.name_last ?? '',
    nameSuffix: fields?.address.name_suffix ?? '',
    showOther: false,
  };

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: values,
    validationSchema: Yup.object({
      email: Yup.string().email('Incorrect email').required('Required'),
      phoneType: Yup.string().required('Required'),
      phoneNumber: Yup.string().required('Required'),
      title: Yup.string().required('Required'),
      nameLast: Yup.string().required('Required'),
      country: Yup.string().required('Required'),
      addressLine1: Yup.string().required('Required'),
      city: Yup.string().required('Required'),
      department: Yup.string().required('Required'),
      state: Yup.string().required('Required'),
      zip: Yup.string().required('Required'),
      institutionOther: Yup.string().when('showOther', {
        is: true,
        then: Yup.string().required('Required'),
      }),
      institution: Yup.string().when('institutionOther', {
        is: (val) => Boolean(val) !== false,
        then: Yup.string(),
        otherwise: Yup.string().required('Required'),
      }),
    }),
    onSubmit: async (values) => {
      setLoading(true);

      try {
        if (fields) {
          await ReferencesStore.updateApplicationReference(values, fields.id);
        } else {
          await ReferencesStore.createApplicationReference(values);
        }
        await ReferencesStore.unacknowledgeApplicationReferencesSection();
        await SectionsStore.getApplicationSectionsStatuses();
      } finally {
        setLoading(false);
        submitHandler();
      }
    },
  });

  const renderInstitutionValue = () => {
    const currentValue = UtilsStore.institutions.filter(
      (item) => item.id === formik.values.institution,
    );

    return currentValue?.map((item) => (
      <option key={v4()} value={item.id}>
        {item.name}
      </option>
    ));
  };

  const renderInstitutionOther = () => {
    if (!formik.values.showOther && !formik.values.institutionOther)
      return null;

    return (
      <div className="px-2 py-2">
        <TextField
          label="Institution Other"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.institutionOther}
          id="institutionOther"
          errors={generateErrorMessages(
            'institutionOther',
            formik,
            ErrorStore,
            'institution_other',
          )}
        />
      </div>
    );
  };

  const onSave = (value) => {
    if (value.institution) {
      formik.setFieldValue('institution', value.institution.id);
      formik.setFieldValue('institutionOther', '');
      setShowInstitution(true);
      formik.setFieldValue('showOther', false);
    } else {
      formik.setFieldValue('institutionOther', value.institutionOther);
      formik.setFieldValue('institution', '');
      setShowInstitution(false);
      formik.setFieldValue('showOther', true);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit} className="w-full">
      <h2 className="text-black text-2xl px-2">
        {fields ? 'Edit Reference' : 'Add Reference'}&nbsp;
        <HelpInfo metaTextName="application-references" />
      </h2>
      <div className="flex flex-row justify-between">
        <div className="w-1/4 m-4">
          <div className="px-2 py-2">
            <TextField
              label="Name Prefix"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.namePrefix}
              id="namePrefix"
              errors={generateErrorMessages(
                'namePrefix',
                formik,
                ErrorStore,
                'name_prefix',
              )}
            />
          </div>
        </div>
        <div className="w-1/4 m-4">
          <div className="px-2 py-2">
            <TextField
              label={
                <label
                  htmlFor="email"
                  className="text-gray-700 dark:text-gray-200">
                  Email <span className="text-red-600">*</span>
                </label>
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              id="email"
              errors={generateErrorMessages(
                'email',
                formik,
                ErrorStore,
                'email',
              )}
            />
          </div>
        </div>
        <div className="w-1/4 m-4">
          <div className="px-2 py-2">
            <Select
              label={
                <label
                  htmlFor="phoneType"
                  className="text-gray-700 dark:text-gray-200">
                  Phone Type <span className="text-red-600">*</span>
                </label>
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phoneType}
              id="phoneType"
              defaultEmptyValue
              itemName="display_name"
              itemValue="value"
              items={
                ReferencesStore.options.actions.POST.address.children.phone_type
                  .choices
              }
              errors={generateErrorMessages(
                'phoneType',
                formik,
                ErrorStore,
                'phone_type',
              )}
            />
          </div>
        </div>
        <div className="w-1/4 m-4">
          <div className="px-2 py-2">
            <TextField
              label={
                <label
                  htmlFor="phoneNumber"
                  className="text-gray-700 dark:text-gray-200">
                  Phone Number <span className="text-red-600">*</span>
                </label>
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phoneNumber}
              id="phoneNumber"
              errors={generateErrorMessages(
                'phoneNumber',
                formik,
                ErrorStore,
                'phone_number',
              )}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-row justify-between">
        <div className="w-1/4 m-4">
          <div className="px-2 py-2">
            <TextField
              label={
                <label
                  htmlFor="title"
                  className="text-gray-700 dark:text-gray-200">
                  Title <span className="text-red-600">*</span>
                </label>
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.title}
              id="title"
              errors={generateErrorMessages(
                'title',
                formik,
                ErrorStore,
                'title',
              )}
            />
          </div>
        </div>
        <div className="w-1/4 m-4">
          <div className="px-2 py-2">
            <TextField
              label="First Name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.nameFirst}
              id="nameFirst"
              errors={generateErrorMessages(
                'nameFirst',
                formik,
                ErrorStore,
                'name_first',
              )}
            />
          </div>
        </div>
        <div className="w-1/4 m-4">
          <div className="px-2 py-2">
            <TextField
              label="Middle Name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.nameMiddle}
              id="nameMiddle"
              errors={generateErrorMessages(
                'nameMiddle',
                formik,
                ErrorStore,
                'name_middle',
              )}
            />
          </div>
        </div>
        <div className="w-1/4 m-4">
          <div className="px-2 py-2">
            <TextField
              label={
                <label
                  htmlFor="nameLast"
                  className="text-gray-700 dark:text-gray-200">
                  Last Name <span className="text-red-600">*</span>
                </label>
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.nameLast}
              id="nameLast"
              errors={generateErrorMessages(
                'nameLast',
                formik,
                ErrorStore,
                'name_last',
              )}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-between">
        <div className="w-1/4 m-4">
          <div className="px-2 py-2">
            <TextField
              label="Name Suffix"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.nameSuffix}
              id="nameSuffix"
              errors={generateErrorMessages(
                'nameSuffix',
                formik,
                ErrorStore,
                'name_suffix',
              )}
            />
          </div>
        </div>
        <div className="w-1/4 m-4">
          <div className="px-2 py-2">
            <label
              htmlFor="institution"
              className="text-gray-700 dark:text-gray-200">
              Institution / Company <span className="text-red-600">*</span>
              &nbsp;
            </label>

            {showInstitution && !formik.values.institutionOther ? (
              <select
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.institution}
                id="institution"
                disabled
                className="
                  appearance-none
                  block
                  w-full
                  px-4
                  py-2
                  mt-2
                  text-gray-700
                  bg-white
                  border
                  rounded-md
                  focus:outline-none
                  focus:ring
                  border-gray-300
                  focus:border-blue-300
                ">
                <option value="" disabled defaultValue></option>
                {formik.values.institution ? renderInstitutionValue() : null}
                ))}
              </select>
            ) : null}
            <div className="flex py-2">
              <InstitutionDialog onSave={onSave} />
              <p className="text-red-400 text-xs ml-1 px-2">
                No institutional affiliation? Click (?) Help above for further
                instructions.
              </p>
            </div>
            {formik.touched.institution && formik.errors.institution ? (
              <div className="text-red-500 py-1">
                {formik.errors.institution}
              </div>
            ) : null}
            {ErrorStore.errors?.institution
              ? ErrorStore.errors.institution.map((item) => (
                  <div key={v4()} className="text-red-500 py-1">
                    {item}
                  </div>
                ))
              : null}
          </div>
          {renderInstitutionOther()}
        </div>
        <div className="w-1/4 m-4">
          <div className="px-2 py-2">
            <TextField
              label={
                <label
                  htmlFor="department"
                  className="text-gray-700 dark:text-gray-200">
                  Department <span className="text-red-600">*</span>
                </label>
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.department}
              id="department"
              errors={generateErrorMessages(
                'department',
                formik,
                ErrorStore,
                'department',
              )}
            />
          </div>
        </div>
        <div className="w-1/4 m-4">
          <div className="px-2 py-2">
            <TextField
              label="Hall"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.hall}
              id="hall"
              errors={generateErrorMessages('hall', formik, ErrorStore, 'hall')}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-between">
        <div className="w-1/3 m-4">
          <div className="px-2 py-2">
            <Select
              label={
                <label
                  htmlFor="country"
                  className="text-gray-700 dark:text-gray-200">
                  Country <span className="text-red-600">*</span>
                </label>
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.country}
              id="country"
              defaultEmptyValue
              itemName="display_name"
              itemValue="value"
              items={ReferencesStore.countries}
              errors={generateErrorMessages(
                'country',
                formik,
                ErrorStore,
                'country',
              )}
            />
          </div>
        </div>
        <div className="w-1/3 m-4">
          <div className="px-2 py-2">
            <TextField
              label={
                <label
                  htmlFor="addressLine1"
                  className="text-gray-700 dark:text-gray-200">
                  Address Line 1 <span className="text-red-600">*</span>
                </label>
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.addressLine1}
              id="addressLine1"
              errors={generateErrorMessages(
                'addressLine1',
                formik,
                ErrorStore,
                'address_line_1',
              )}
            />
          </div>
        </div>
        <div className="w-1/3 m-4">
          <div className="px-2 py-2">
            <TextField
              label="Address Line 2"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.addressLine2}
              id="addressLine2"
              errors={generateErrorMessages(
                'addressLine2',
                formik,
                ErrorStore,
                'address_line_2',
              )}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-between">
        <div className="w-1/3 m-4">
          <div className="px-2 py-2">
            <TextField
              label={
                <label
                  htmlFor="city"
                  className="text-gray-700 dark:text-gray-200">
                  City/Town <span className="text-red-600">*</span>
                </label>
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.city}
              id="city"
              errors={generateErrorMessages('city', formik, ErrorStore, 'city')}
            />
          </div>
        </div>
        <div className="w-1/3 m-4">
          <div className="px-2 py-2">
            <TextField
              label={
                <label
                  htmlFor="state"
                  className="text-gray-700 dark:text-gray-200">
                  State / Province <span className="text-red-600">*</span>
                </label>
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.state}
              id="state"
              errors={generateErrorMessages(
                'state',
                formik,
                ErrorStore,
                'state',
              )}
            />
          </div>
        </div>
        <div className="w-1/3 m-4">
          <div className="px-2 py-2">
            <TextField
              label={
                <label
                  htmlFor="zip"
                  className="text-gray-700 dark:text-gray-200">
                  Zip/Postal Code <span className="text-red-600">*</span>
                </label>
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.zip}
              id="zip"
              errors={generateErrorMessages('zip', formik, ErrorStore, 'zip')}
            />
          </div>
        </div>
      </div>
      <NonFieldErrors />
      <div className="flex flex-col md:flex-row justify-center mt-2">
        <button
          disabled={loading}
          onClick={submitHandler}
          type="button"
          className="
                          w-max
                          md:mx-2
                          px-8
                          py-3
                          text-xl
                          bg-gray-400
                          text-black
                          rounded-md
                          text-center
                        ">
          Cancel
        </button>
        <button
          type="submit"
          disabled={loading}
          className="
                          w-max
                          md:mx-2
                          px-8
                          py-3
                          text-xl
                          bg-primary
                          text-white
                          rounded-md
                          text-center
                        ">
          {loading ? <CircularProgress color="white" /> : 'Save'}
        </button>
      </div>
    </form>
  );
});

export default ReferencesForm;
