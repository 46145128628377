import { makeAutoObservable } from 'mobx';

export class Language {
  constructor(language) {
    this.id = language.id;
    this.fm_id = language.fm_id;
    this.application = language.application;
    this.name = language.name;
    this.rating_read = language.rating_read;
    this.rating_speak = language.rating_speak;
    this.rating_write = language.rating_write;
    this.date_created = language.date_created;
    this.date_last_modified = language.date_last_modified;
    makeAutoObservable(this, {}, { autoBind: true });
  }
}
