import { ApplicationNavbar } from '../../../components';

const SectionContentLayout = ({ children }) => (
  <div className="md:border md:rounded-md md:border-gray-300 md:mt-10 shadow-lg">
    <div className="relative flex flex-col md:flex-row">
      <ApplicationNavbar />
      <div className="p-8 mx-auto w-full">{children}</div>
    </div>
  </div>
);

export default SectionContentLayout;
