import { useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import { AuthStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { v4 } from 'uuid';
import ReferencesStore from '../services/ReferencesStore';
import ReferenceItem from './ReferenceItem';
import ReferencesNoCard from './ReferencesNoCard';

const ReferencesList = observer(() => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      await ReferencesStore.getReferences({
        userId: AuthStore.id,
      });
      await ReferencesStore.getReferenceOptions({ userId: AuthStore.id });
      setLoading(false);
    }

    fetchData();
  }, []);

  const renderReferencesList = () =>
    ReferencesStore.references?.results?.length ? (
      <List key={v4()}>
        {ReferencesStore.references.results.map((item) => (
          <ReferenceItem reference={item} />
        ))}
      </List>
    ) : (
      <ReferencesNoCard />
    );
  return loading ? <CircularProgress /> : renderReferencesList();
});

export default ReferencesList;
