const SectionHeaderHint = ({ hintName }) => {
  switch (hintName) {
    case 'acknowledged':
      return (
        <span className="italic mr-2 font-semibold">
          This application section has been Acknowledged. <br /> Click to
          unacknowledge this section.
        </span>
      );
    case 'unacknowledged':
      return (
        <span className="italic mr-2 font-semibold">
          Please acknowledge that the following <br /> information is correct
          before continuing.
        </span>
      );
    case 'submitted':
      return (
        <span className="italic mr-2 font-semibold">
          This application section has been submitted <br /> and is not
          available for further editing.
        </span>
      );
    default:
      return null;
  }
};

export default SectionHeaderHint;
