import { useEffect } from 'react';
import { Loader, NoDataEntered } from '@components';
import { AdminApplicationLayoutStore, ApplicationStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { SectionsWrapper } from '../../components';
import { sectionsNames, sectionsTypes } from '../../services/constants';
import SectionNoteDialogStore from '../../services/SectionNoteDialogStore';
import SectionStatusesStore from '../../services/SectionStatusesStore';
import { OrganizationsSectionContent } from './components';
import OrganizationsSectionStore from './services/OrganizationsSectionStore';

export const OrganizationsSectionScene = observer(() => {
  useEffect(() => {
    const applicationId = ApplicationStore.verifiableApplication.id;
    SectionStatusesStore.getSectionStatuses(
      applicationId,
      sectionsNames.organizations,
    );
    OrganizationsSectionStore.getOrganizations(applicationId);
    SectionNoteDialogStore.getSectionNote(
      sectionsTypes.organizations,
      applicationId,
    );

    return () => {
      SectionStatusesStore.clear();
      OrganizationsSectionStore.clear();
      SectionNoteDialogStore.clear();
    };
  }, []);

  return (
    <>
      {SectionStatusesStore.sectionStatuses &&
      OrganizationsSectionStore.organizations ? (
        <SectionsWrapper
          sectionTitle="Organizations"
          sectionName="organization"
          sectionFullName={sectionsNames.organizations}
          status={SectionStatusesStore.sectionStatuses}
          onCloseSection={
            AdminApplicationLayoutStore.setCloseOrganizationsDialog
          }
          open={AdminApplicationLayoutStore.showOrganizationsDialog}>
          {OrganizationsSectionStore.organizations.length > 0 ? (
            <OrganizationsSectionContent
              organizations={OrganizationsSectionStore.organizations}
            />
          ) : (
            <NoDataEntered />
          )}
        </SectionsWrapper>
      ) : (
        <Loader message="Loading Organizations Section..." />
      )}
    </>
  );
});
