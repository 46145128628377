import { makeAutoObservable } from 'mobx';

export default class Competition {
  constructor(competition) {
    this.id = competition.id;
    this.fm_id = competition.fm_id;
    this.name = competition.name;
    this.is_active = competition.is_active;
    this.year = competition.year;
    this.date_created = competition.date_created;
    this.date_last_modified = competition.date_last_modified;
    this.region = competition.region;
    this.allow_advisor_view_groups = competition.allow_advisor_view_groups;
    this.allow_juror_view_groups = competition.allow_juror_view_groups;
    this.allow_applicaton_create = competition.allow_applicaton_create;
    this.allow_application_edit = competition.allow_application_edit;
    this.allow_work_example_submit = competition.allow_work_example_submit;
    this.allow_trustee_motions = competition.allow_trustee_motions;
    makeAutoObservable(this, {}, { autoBind: true });
  }
}
