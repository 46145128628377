import { http } from '@utils';

export default class AddressesSectionService {
  static fetchAddresses(id) {
    return http.get(`/api/applications/${id}/addresses/`);
  }

  static async fetchAddressesTypes(id) {
    const response = await http.options(`/api/applications/${id}/addresses/`);
    return response.data;
  }
}
