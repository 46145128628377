import { ProgressStore } from '@stores';

const AcknowledgeButton = ({ children, onClick, disabled }) => {
  const classes = [
    'px-8',
    'py-3',
    'text-xl',
    'text-white',
    'rounded-md',
    'text-center',
    'my-auto',
  ];

  if (disabled) {
    classes.push('bg-gray-200');
  } else {
    classes.push('bg-green-500');
  }

  const handleClick = async () => {
    ProgressStore.setInProgress();
    await onClick();
    ProgressStore.setNotInProgress();
  };

  return (
    <button
      disabled={disabled}
      onClick={onClick ? handleClick : null}
      type="button"
      className={classes.join(' ')}>
      {children}
    </button>
  );
};

export default AcknowledgeButton;
