import { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { UserCircleIcon } from '@heroicons/react/solid';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { AuthStore, UserStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { Dropdown } from '../../Dropdown';
import { PersonalInformationForm } from '../../PersonalInformationForm';

const AppHeader = observer(() => {
  const history = useHistory();
  const location = useLocation();
  const [showDialog, setShowDialog] = useState(false);

  const handleLogout = () => {
    AuthStore.logout();
    UserStore.clearCurrentUser();
    history.push('/logout');
  };

  return (
    <>
      <header className="bg-primary text-white h-14">
        <div className="container mx-auto h-full mx-0">
          <div className="text-2xl mx-auto h-full text-center">
            <h2 className="text-2xl leading-12 font-normal font-cormorant">
              <span className="hidden md:block">
                John Simon Guggenheim Memorial Foundation
              </span>
              <span className="block md:hidden">JSGMF</span>
            </h2>

            {AuthStore.isAuthenticated ? (
              <div className="absolute top-4 right-2">
                <div className="flex flex-row w-max">
                  <h3 className="text-lg mr-2">
                    {UserStore.user
                      ? `${UserStore.user.first_name} ${UserStore.user.last_name}`
                      : ''}
                  </h3>
                  <div>
                    <Dropdown
                      activator={
                        <UserCircleIcon
                          id="menu-button"
                          aria-expanded="false"
                          aria-haspopup="true"
                          className="h-7 w-7 text-white cursor-pointer outline-none"
                        />
                      }
                      items={[
                        {
                          label: 'Account',
                          type: 'link',
                          href: '/home',
                        },
                        {
                          label: 'Personal Info',
                          type: 'button',
                          onClick: () => setShowDialog((s) => !s),
                        },
                        {
                          label: 'Addresses',
                          type: 'link',
                          href: '/user/addresses',
                        },
                        {
                          label: 'Log Out',
                          type: 'button',
                          onClick: handleLogout,
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="absolute top-2 right-2">
                <Link
                  to={location.pathname === '/login' ? '/register' : '/login'}
                  className="border rounded-md text-sm py-1 px-3">
                  {location.pathname === '/login' ? 'Sign Up' : 'Log In'}
                </Link>
              </div>
            )}
          </div>
        </div>
      </header>

      <Dialog open={showDialog} fullWidth maxWidth="lg">
        <DialogContent>
          <PersonalInformationForm
            submitHandler={() => {
              setShowDialog(false);
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
});

export default AppHeader;
