import { UserService } from '@services';
import { makeAutoObservable, when } from 'mobx';
import AuthStore from './AuthStore';

class UserStore {
  constructor() {
    this.user = null;
    this.inProgress = false;
    this.id = localStorage.getItem('id') || null;
    this.options = null;
    this.references = null;
    when(
      () => !AuthStore.isAuthenticated,
      () => this.clearCurrentUser(),
    );
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setUser(data) {
    this.user = data;
  }

  setReferences(data) {
    this.references = data;
  }

  async getCurrentUser() {
    const response = await UserService.getCurrentUser();
    this.setUser(response.data);
  }

  async getCurrentUserReferences({ userId }) {
    const response = await UserService.getUserReferences({ userId: userId });
    this.setReferences(response.data);
  }

  async updateUserPersonalInfo(user) {
    const body = {
      accepted_toc: user.acceptedToc,
      citizenship: user.citizenship,
      citizenship_note: user.citizenshipNote,
      company: user.company,
      country: user.country,
      date_of_birth: user.dateOfBirth,
      first_name: user.firstName,
      gender: user.gender,
      gender_other: user.genderOther,
      last_name: user.lastName,
      middle_name: user.middleName,
      name_prefix: user.namePrefix,
      name_suffix: user.nameSuffix,
      note: user.note,
      place_of_birth: user.placeOfBirth,
      title: user.title,
      id: user.id,
      institution: user.institution,
      institution_other: user.institutionOther,
      institution_type: user.institutionOther ? 'O' : 'I',
    };

    await UserService.patchUser(body);
    await this.getCurrentUser();
  }

  async getUserPersonalInfoOptions() {
    const response = await UserService.getUserPersonalInfoOptions({
      id: AuthStore.id,
    });
    this.options = response.data;
  }

  shouldUserNavigateToStart() {
    if (this.user && this.user.user_summary.juror) {
      return false;
    }

    return (
      this.user &&
      (!this.user.first_name ||
        !this.user.date_of_birth ||
        !this.user.place_of_birth ||
        !this.user.citizenship ||
        !this.user.gender ||
        !this.user.title)
    );
  }

  clearCurrentUser() {
    this.user = null;
    this.inProgress = false;
    this.id = null;
    this.permissions = {};
  }
}

export default new UserStore();
