import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery, getQueryParams } from '@hooks';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import { AdminLayoutStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { FiltersDialog } from './components/FiltersDialog';
import { TableHeader } from './components/TableHeader';
import { TableToolbar } from './components/TableToolbar';
import { useDataTableStyles } from './styles';

const DataTable = observer(
  ({
    competitionName,
    headCells,
    rows,
    fetchListCallback,
    totalCount,
    clearListCallback,
    filters,
    TableBodyComponent,
    tableTitle,
    onClickRow,
  }) => {
    const classes = useDataTableStyles();
    const history = useHistory();
    const urlParams = new URLSearchParams(history.location.search);
    const parsedQueryParams = useQuery();

    const dependencyList = filters
      .map((filter) => parsedQueryParams[filter.filterName])
      .concat([
        parsedQueryParams.page_size,
        parsedQueryParams.sorting_direction,
        parsedQueryParams.order_by,
        parsedQueryParams.page,
      ]);

    useEffect(() => {
      if (parsedQueryParams) {
        window.localStorage.setItem(
          'TableFilters',
          JSON.stringify(parsedQueryParams),
        );
      }
    }, [parsedQueryParams]);

    useEffect(() => {
      const pageSize = urlParams.get('page_size');
      const page = urlParams.get('page');
      const hasPageSize = !!pageSize;
      const hasPage = !!page;
      if (pageSize && page) {
        urlParams.set('page_size', pageSize);
        urlParams.set('page', page);
        history.push({
          search: urlParams.toString(),
        });
        fetchListCallback(getQueryParams(urlParams.toString()));
      } else if (!hasPageSize && !hasPage) {
        urlParams.set('page_size', '25');
        urlParams.set('page', '1');
        history.push({
          search: urlParams.toString(),
        });
        fetchListCallback(getQueryParams(urlParams.toString()));
      }

      return () => {
        clearListCallback();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...dependencyList]);

    const setFilterQueryParams = (name, value) => {
      if (!urlParams.has(name) && value !== '') {
        urlParams.append(name, value);
      } else if (urlParams.has(name) && value !== '') {
        urlParams.set(name, value);
      }
      if (!value) {
        urlParams.delete(name);
      }
      history.push({
        search: urlParams.toString(),
      });
    };

    const setSort = (fieldName, sortingDirection) => {
      setFilterQueryParams('order_by', fieldName);
      setFilterQueryParams('sorting_direction', sortingDirection);
    };

    const handleChangePage = (event, newPage) => {
      setFilterQueryParams('page', newPage + 1);
    };

    const handleChangeRowsPerPage = (event) => {
      setFilterQueryParams('page_size', event.target.value);
    };

    const removeSort = () => {
      urlParams.delete('sorting_direction');
      urlParams.delete('order_by');
      history.push({
        search: urlParams.toString(),
      });
    };

    return (
      <div className={classes.root}>
        {AdminLayoutStore.showFiltersDialog ? (
          <FiltersDialog
            urlParams={urlParams}
            filters={filters}
            setFilters={setFilterQueryParams}
          />
        ) : null}
        <TableToolbar
          tableTitle={tableTitle}
          competitionName={competitionName}
        />
        <Paper elevation={3} className={classes.paperWrapper}>
          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader>
              <TableHeader
                columns={headCells}
                urlParams={urlParams}
                setSort={setSort}
                removeSort={removeSort}
              />
              <TableBodyComponent rows={rows} onClickRow={onClickRow} />
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[25, 100]}
            component="div"
            count={totalCount}
            rowsPerPage={+parsedQueryParams.page_size || 25}
            page={+parsedQueryParams.page - 1}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  },
);

export default DataTable;
