import { useEffect, useState } from 'react';
import { HelpInfo } from '@components';
import { SectionsStore, DocumentsStore, ProgressStore } from '@stores';
import { observer } from 'mobx-react-lite';
import {
  AcknowledgeButton,
  SectionContentLayout,
  SectionHeader,
  SectionHeaderHint,
  SectionLayout,
} from '../../components';
import {
  ListDocumentCard,
  NarrativeDocumentCard,
  PlanDocumentCard,
} from './components';

const DocumentsScene = observer(() => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      await DocumentsStore.getApplicationDocumentsSection();
      await setLoading(false);
    }

    void fetchData();
  }, []);

  const acknowledgeSection = async () => {
    ProgressStore.setInProgress();

    await DocumentsStore.acknowledgeApplicationDocumentsSection()
      .then(() => SectionsStore.getApplicationSectionsStatuses())
      .finally(() => ProgressStore.setNotInProgress());
  };

  const unacknowledgeSection = async () => {
    ProgressStore.setInProgress();

    await DocumentsStore.unacknowledgeApplicationDocumentsSection()
      .then(() => SectionsStore.getApplicationSectionsStatuses())
      .finally(() => ProgressStore.setNotInProgress());
  };

  const renderAcknowledge = () => {
    if (
      DocumentsStore.section.editable &&
      !DocumentsStore.section.acknowledged
    ) {
      return (
        <div className="flex flex-row">
          <SectionHeaderHint hintName="unacknowledged" />
          <AcknowledgeButton
            onClick={acknowledgeSection}
            disabled={
              !(
                DocumentsStore.narrative &&
                DocumentsStore.list &&
                DocumentsStore.plan
              )
            }>
            Acknowledge section and continue
          </AcknowledgeButton>
        </div>
      );
    }

    if (
      DocumentsStore.section.editable &&
      DocumentsStore.section.acknowledged
    ) {
      return (
        <div className="flex flex-row">
          <SectionHeaderHint hintName="acknowledged" />
          <AcknowledgeButton onClick={unacknowledgeSection}>
            Unacknowledge
          </AcknowledgeButton>
        </div>
      );
    }

    if (!DocumentsStore.section.editable) {
      return (
        <div className="flex flex-row">
          <SectionHeaderHint hintName="submitted" />
          <AcknowledgeButton disabled>Submitted</AcknowledgeButton>
        </div>
      );
    }

    return null;
  };

  const renderContent = () => {
    if (DocumentsStore.section.editable) {
      return (
        <div className="flex flex-row flex-wrap w-full">
          <div className="w-1/3 p-2">
            <NarrativeDocumentCard />
          </div>
          <div className="w-1/3 p-2">
            <ListDocumentCard />
          </div>
          <div className="w-1/3 p-2">
            <PlanDocumentCard />
          </div>
        </div>
      );
    } else {
      return 'This section has been final submitted and has not been unlocked for a Revision Request.';
    }
  };

  const renderSection = () => (
    <SectionLayout>
      <SectionHeader>
        <h1 className="text-4xl text-black font-calibri font-normal md:mt-2 text-center md:text-left mb-2 md:mb-0">
          Edit Supporting Documents&nbsp;
          <HelpInfo metaTextName="application-supporting-documents" />
        </h1>
        {renderAcknowledge()}
      </SectionHeader>
      <SectionContentLayout>{renderContent()}</SectionContentLayout>
    </SectionLayout>
  );

  return loading ? null : renderSection();
});

export default DocumentsScene;
