import { privateResolverWithPayload } from '@services';
import { makeAutoObservable } from 'mobx';
import Document from '../models/Document';
import DocumentsSectionService from './documentsSection.service';

/*
 * In the "setDocuments" method, the documents are sorted in the right order,
 * the administration wants to see the order like this (Narrative, List, Plan).
 * The maximum length of the array cannot be longer than 3.
 * There may not be any documents, and they can also come in random order.
 */

class DocumentsSectionStore {
  constructor() {
    this.documents = null;
    this.documentReviewedStatusTypes = null;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  clear() {
    this.documents = null;
  }

  setDocumentReviewedStatusTypes(types) {
    this.documentReviewedStatusTypes = types;
  }

  setDocuments(documentsArray) {
    const documents = documentsArray.reduce((docs, curr) => {
      if (curr.document.document_type.name === 'Application Narrative') {
        return { ...docs, narrativeDocument: new Document(curr) };
      }
      if (curr.document.document_type.name === 'Application List') {
        return { ...docs, listDocument: new Document(curr) };
      }
      if (curr.document.document_type.name === 'Application Plan') {
        return { ...docs, planDocument: new Document(curr) };
      }
      return docs;
    }, {});

    const rightOrderDocumentsArray = [];

    if (documents.narrativeDocument) {
      rightOrderDocumentsArray.push(documents.narrativeDocument);
    }
    if (documents.listDocument) {
      rightOrderDocumentsArray.push(documents.listDocument);
    }
    if (documents.planDocument) {
      rightOrderDocumentsArray.push(documents.planDocument);
    }

    this.documents = rightOrderDocumentsArray;
  }

  getDocuments(applicationId) {
    privateResolverWithPayload({
      fetcher: DocumentsSectionService.fetchDocuments,
      payload: applicationId,
      setter: this.setDocuments,
    });
  }

  async getDocumentReviewedStatus(applicationId) {
    DocumentsSectionService.fetchDocumentReviewedStatus(applicationId).then(
      (data) => {
        const types =
          data.actions.POST.document.children.reviewed_status.choices;
        this.setDocumentReviewedStatusTypes(types);
      },
    );
  }
}

export default new DocumentsSectionStore();
