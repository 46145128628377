import { useEffect, useRef, useState } from 'react';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import { UtilsStore } from '@stores';

const PasswordCriteriaDialog = ({ show, handleClose }) => {
  const [loading, setLoading] = useState(true);
  const ref = useRef();

  useEffect(() => {
    async function fetchData() {
      await UtilsStore.getMetaPasswordCriteria();
      await setLoading(false);
      ref.current.innerHTML = UtilsStore.passwordCriteria?.value || '';
    }

    fetchData();
  }, []);

  return (
    <Dialog open={show} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogContent>
        <h2 className="text-2xl text-center mb-4">Password Criteria</h2>
        {loading ? (
          <div className="text-center">
            <CircularProgress />
          </div>
        ) : (
          <p ref={ref} />
        )}
      </DialogContent>
      <DialogActions>
        <button
          onClick={handleClose}
          type="button"
          className="px-8 py-3 text-2xl bg-gray-400 text-white rounded-md">
          Cancel
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default PasswordCriteriaDialog;
