import { UserNavbar } from '@components';
import { observer } from 'mobx-react-lite';
import { ReferencesList } from './components';

const ReferencesScene = observer(() => (
  <>
    <div className="mt-10">
      <h1 className="text-4xl text-black font-calibri font-normal mt-2">
        Home
      </h1>
    </div>
    <div className="border rounded-md border-gray-300 md:mt-10 shadow-lg">
      <div className="flex flex-col md:flex-row">
        <UserNavbar />
        <div className="p-8 w-full md:w-3/4">
          <ReferencesList />
        </div>
      </div>
    </div>
  </>
));

export default ReferencesScene;
