import { ApplicationService } from '@services';
import { makeAutoObservable } from 'mobx';
import ApplicationStore from '../ApplicationStore';

class DocumentsStore {
  constructor() {
    this.section = null;
    this.narrative = null;
    this.list = null;
    this.plan = null;
    this.options = null;
    makeAutoObservable(this);
  }

  async getApplicationNarrativeDocument() {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    const result = await ApplicationService.getApplicationDocument({
      applicationId: ApplicationStore.application.id,
      type: 'narrative',
    });

    if (result.data.length) {
      this.narrative = result.data[0];
    } else {
      this.narrative = null;
    }
  }

  async getApplicationPlanDocument() {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    const result = await ApplicationService.getApplicationDocument({
      applicationId: ApplicationStore.application.id,
      type: 'plan',
    });

    if (result.data.length) {
      this.plan = result.data[0];
    } else {
      this.plan = null;
    }
  }

  async getApplicationListDocument() {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    const result = await ApplicationService.getApplicationDocument({
      applicationId: ApplicationStore.application.id,
      type: 'list',
    });

    if (result.data.length) {
      this.list = result.data[0];
    } else {
      this.list = null;
    }
  }

  async createApplicationDocument({ applicationId, type, form }) {
    const formData = new FormData();

    formData.append('application', applicationId);
    formData.append('document.document_type', form.document_type);
    formData.append('document.file', form.file);

    await ApplicationService.createApplicationDocument({
      applicationId: applicationId,
      type: type,
      body: formData,
    });
  }

  async patchApplicationDocument({ applicationId, form, id }) {
    const formData = new FormData();

    formData.append('application', applicationId);
    formData.append('document_type', form.document_type);
    formData.append('file', form.file);

    await ApplicationService.patchApplicationDocument({
      body: formData,
      id: id,
    });
  }

  async getApplicationDocumentsSection() {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    const result = await ApplicationService.getCurrentApplicationSection({
      applicationId: ApplicationStore.application.id,
      section: 'documents',
    });
    this.section = result.data[0];
  }

  async deleteApplicationDocument({ applicationId, type, documentId }) {
    await ApplicationService.deleteApplicationDocument({
      applicationId: applicationId,
      type: type,
      id: documentId,
    });
  }

  async acknowledgeApplicationDocumentsSection() {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }
    if (!this.section) {
      await this.getApplicationDocumentsSection();
    }

    const body = {
      acknowledged: true,
      application: this.section.application,
    };

    const result = await ApplicationService.updateApplicationSection({
      body: body,
      applicationId: ApplicationStore.application.id,
      section: 'documents',
      id: this.section.id,
    });
    this.section = result.data;
  }

  async unacknowledgeApplicationDocumentsSection() {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }
    if (!this.section) {
      await this.getApplicationDocumentsSection();
    }

    const body = {
      acknowledged: false,
      application: this.section.application,
    };

    const result = await ApplicationService.updateApplicationSection({
      body: body,
      applicationId: ApplicationStore.application.id,
      section: 'documents',
      id: this.section.id,
    });
    this.section = result.data;
  }
}

export default new DocumentsStore();
