import { http } from '@utils';

export class LanguagesSectionService {
  static fetchLanguages(id) {
    return http.get(`/api/applications/${id}/languages/`);
  }

  static fetchLanguageRatings(id) {
    return http.options(`/api/applications/${id}/languages/`);
  }
}
