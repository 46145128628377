import { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteIcon from '@material-ui/icons/Delete';
import { AddressesStore, AuthStore } from '@stores';
import { observer } from 'mobx-react-lite';
import HomeAddressForm from '../Forms/HomeAddressForm';

const HomeAddressCard = observer(({ unacknowledgeSection }) => {
  const [show, setShow] = useState(false);
  const [fields, setFields] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      await AddressesStore.getApplicationHomeAddress();
      setLoading(false);
    }

    fetchData();
  }, []);

  const handleOpen = () => {
    if (AddressesStore.homeAddress) setFields(AddressesStore.homeAddress);
    setShow(true);
  };
  const handleClose = () => {
    setFields(null);
    setShow(false);
  };

  const [showDelete, setShowDelete] = useState(false);

  const handleOpenDelete = () => {
    setShowDelete(true);
  };

  const handleCloseDelete = () => {
    setShowDelete(false);
  };

  const preferAddress = async () => {
    setLoading(true);

    await AddressesStore.preferHomeAddress({
      userId: AuthStore.id,
    }).finally(() => setLoading(false));
  };

  const deleteAddress = async () => {
    handleCloseDelete();
    setLoading(true);

    await AddressesStore.deleteUserHomeAddress({
      userId: AuthStore.id,
    }).finally(() => setLoading(false));
  };

  const renderDeleteDialog = () => (
    <Dialog open={showDelete}>
      <DialogTitle>Attention</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure want do delete?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={deleteAddress}
          variant="contained"
          color="secondary"
          startIcon={<DeleteIcon />}>
          Delete
        </Button>
        <Button onClick={handleCloseDelete}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );

  const renderDeleteButton = () =>
    AddressesStore.homeAddress ? (
      <button className="text-md text-red-400 my-2" onClick={handleOpenDelete}>
        Delete
      </button>
    ) : null;

  return (
    <>
      <Card>
        <CardContent>
          {loading ? (
            <CircularProgress />
          ) : (
            <div className="p-4">
              <h3 className="text-3xl font-semibold mb-2 mt-3">
                Home <span className="text-red-600">*</span>
              </h3>
              {AddressesStore.homeAddress ? (
                <div className="mb-4">
                  <label>
                    <input
                      type="radio"
                      onChange={preferAddress}
                      checked={AddressesStore.homeAddress.preferred}
                    />
                    &nbsp;Preferred Address
                  </label>
                </div>
              ) : null}
              {AddressesStore.homeAddress ? (
                <>
                  {AddressesStore.homeAddress.hall ? (
                    <p>{AddressesStore.homeAddress.hall}</p>
                  ) : null}
                  {AddressesStore.homeAddress.address_line_1 ? (
                    <p>{AddressesStore.homeAddress.address_line_1}</p>
                  ) : null}
                  {AddressesStore.homeAddress.address_line_2 ? (
                    <p>{AddressesStore.homeAddress.address_line_2}</p>
                  ) : null}
                  <p>
                    {AddressesStore.homeAddress.city},&nbsp;
                    {AddressesStore.homeAddress.state},&nbsp;
                    {AddressesStore.homeAddress.zip}
                  </p>
                </>
              ) : (
                <p>Enter an address for a Home</p>
              )}
              <button
                className="text-md text-blue-400 my-6"
                onClick={handleOpen}>
                Edit Address
              </button>
              <br />
              {renderDeleteButton()}
            </div>
          )}
        </CardContent>
      </Card>
      <Dialog open={show} fullWidth maxWidth="lg" disableBackdropClick>
        <DialogContent>
          <HomeAddressForm fields={fields} submitHandler={handleClose} />
        </DialogContent>
      </Dialog>
      {renderDeleteDialog()}
    </>
  );
});

export default HomeAddressCard;
