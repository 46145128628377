import { InputTextField } from '@components';
import Grid from '@material-ui/core/Grid';
import { UtilsStore } from '@stores';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { gender } from '../../../services/constants';

export const UserSectionContent = observer(({ userInfo }) => (
  <Grid container spacing={2} justify="space-around" alignItems="center">
    <Grid item xs={12} md={1}>
      <InputTextField
        name="name_prefix"
        value={userInfo.name_prefix}
        label="Prefix"
      />
    </Grid>
    <Grid item xs={12} md={3}>
      <InputTextField
        name="first_name"
        value={userInfo.first_name}
        label="First Name"
        required
      />
    </Grid>
    <Grid item xs={12} md={3}>
      <InputTextField
        name="middle_name"
        value={userInfo.middle_name}
        label="Middle Name"
      />
    </Grid>
    <Grid item xs={12} md={3}>
      <InputTextField
        name="last_name"
        value={userInfo.last_name}
        label="Last Name"
        required
      />
    </Grid>
    <Grid item xs={12} md={2}>
      <InputTextField
        name="name_suffix"
        value={userInfo.name_suffix}
        label="Name Suffix"
      />
    </Grid>
    <Grid item xs={12} md={3}>
      <InputTextField
        name="date_of_birth"
        value={moment(userInfo.date_of_birth).format('MM-DD-YYYY')}
        label="Date of Birth"
        required
      />
    </Grid>
    <Grid item xs={12} md={3}>
      <InputTextField
        name="place_of_birth"
        value={userInfo.place_of_birth}
        label="Place of Birth"
        required
      />
    </Grid>
    <Grid item xs={12} md={3}>
      <InputTextField
        name="citizenship"
        value={userInfo.citizenship}
        label="Citizenship"
        required
      />
    </Grid>
    <Grid item xs={12} md={3}>
      <InputTextField
        name="citizenship_note"
        value={userInfo.citizenship_note}
        label="Citizenship Note"
      />
    </Grid>
    <Grid item xs={12} md={2}>
      <InputTextField
        name="gender"
        value={
          gender.find((genderItem) => genderItem.value === userInfo.gender)
            ?.display_name
        }
        label="Gender"
        required
      />
    </Grid>
    <Grid item xs={12} md={3}>
      <InputTextField
        name="title"
        value={userInfo.title}
        label="Title/Position"
        required
      />
    </Grid>
    <Grid item xs={12} md={4}>
      <InputTextField
        name="institution"
        value={
          UtilsStore.getInstitution(userInfo.institution) ||
          userInfo.institution_other
        }
        label="Institution/Company"
        required
      />
    </Grid>
    <Grid item xs={12} md={3}>
      <InputTextField
        name="email"
        value={userInfo.email}
        label="Email"
        required
      />
    </Grid>
  </Grid>
));
