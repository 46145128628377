import { ApplicationService } from '@services';
import { ApplicationStore } from '@stores';
import { makeAutoObservable } from 'mobx';

class InfoStore {
  constructor() {
    this.section = null;
    this.info = null;
    this.options = null;
    makeAutoObservable(this);
  }

  async createApplicationInfo({
    projectTitle,
    projectField,
    jointApplicantName,
    projectFieldAdditional,
    jointApplicantEmail,
    website1,
    website2,
    previouslyApplied,
    previouslyAppliedName,
  }) {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    const body = {
      project_title: projectTitle,
      project_field_1: projectField,
      project_field_2: projectFieldAdditional,
      joint_applicant_name: jointApplicantName,
      joint_applicant_email: jointApplicantEmail,
      website_1: website1,
      website_2: website2,
      previously_applied: previouslyApplied,
      previous_applied_name: previouslyAppliedName,
      application: ApplicationStore.application.id,
    };

    await ApplicationService.createApplicationSectionItem({
      body: body,
      applicationId: ApplicationStore.application.id,
      section: 'info',
    });
    await this.getApplicationInfo();
  }

  async updateApplicationInfo(
    {
      projectTitle,
      projectField,
      jointApplicantName,
      projectFieldAdditional,
      jointApplicantEmail,
      website1,
      website2,
      previouslyApplied,
      previouslyAppliedName,
    },
    id,
  ) {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    const body = {
      project_title: projectTitle,
      project_field_1: projectField,
      project_field_2: projectFieldAdditional,
      joint_applicant_name: jointApplicantName,
      joint_applicant_email: jointApplicantEmail,
      website_1: website1,
      website_2: website2,
      previously_applied: previouslyApplied,
      previous_applied_name: previouslyAppliedName,
      application: ApplicationStore.application.id,
    };

    await ApplicationService.updateApplicationSectionItem({
      body: body,
      applicationId: ApplicationStore.application.id,
      section: 'info',
      id: id,
    });
    await this.getApplicationInfo();
  }

  async getApplicationInfo() {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    const result = await ApplicationService.getCurrentApplicationSectionItem({
      applicationId: ApplicationStore.application.id,
      section: 'info',
    });
    this.info = result.data[0];
  }

  async getApplicationInfoSection() {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    const result = await ApplicationService.getCurrentApplicationSection({
      applicationId: ApplicationStore.application.id,
      section: 'info',
    });
    this.section = result.data[0];
  }

  async acknowledgeApplicationInfoSection() {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }
    if (!this.section) {
      await this.getApplicationInfoSection();
    }

    const body = {
      acknowledged: true,
      application: this.section.application,
    };

    const result = await ApplicationService.updateApplicationSection({
      body: body,
      applicationId: ApplicationStore.application.id,
      section: 'info',
      id: this.section.id,
    });
    this.section = result.data;
  }

  async unacknowledgeApplicationInfoSection() {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }
    if (!this.section) {
      await this.getApplicationInfoSection();
    }

    const body = {
      acknowledged: false,
      application: this.section.application,
    };

    const result = await ApplicationService.updateApplicationSection({
      body: body,
      applicationId: ApplicationStore.application.id,
      section: 'info',
      id: this.section.id,
    });
    this.section = result.data;
  }

  async getApplicationInfoOptions() {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    const result = await ApplicationService.getCurrentApplicationSectionOptions(
      {
        applicationId: ApplicationStore.application.id,
        section: 'info',
      },
    );
    this.options = result.data;
  }
}

export default new InfoStore();
