import { httpErrorHandler } from '@utils';
import { runInAction } from 'mobx';
import ProgressStore from '../stores/ProgressStore';

// TODO: decide for us what we will do with the loader
export const plainRequestPrivateResolver = (options) => {
  options
    .fetcher()
    .then(({ data }) => {
      runInAction(() => {
        if (options.setter) {
          options.setter(data);
        }
      });
    })
    .catch((error) => {
      httpErrorHandler(error, this);
    });
};

export const privateResolverWithPayload = (options) => {
  if (options.progress) {
    ProgressStore.setInProgress();
  }
  options
    .fetcher(options.payload)
    .then(({ data }) => {
      runInAction(() => {
        if (options.setter) {
          options.setter(data);
        }
        if (options.deleteAction) {
          options.deleteAction(options.payload);
        }
        if (options.closeDialog) {
          options.closeDialog();
        }
      });
    })
    .catch((error) => {
      httpErrorHandler(error, this);
    })
    .finally(() => {
      ProgressStore.setNotInProgress();
    });
};
