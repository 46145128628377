import { useLocation } from 'react-router-dom';
import QueryString from 'qs';

export const useQuery = () => {
  const location = useLocation();
  return QueryString.parse(location.search, { ignoreQueryPrefix: true });
};

export const getQueryParams = (queryString) =>
  QueryString.parse(queryString, { ignoreQueryPrefix: true });
