import { ApplicationService, UserService } from '@services';
import { makeAutoObservable } from 'mobx';
import ApplicationStore from '../ApplicationStore';
import AuthStore from '../AuthStore';

class AddressesStore {
  constructor() {
    this.section = null;
    this.alternateAddress = null;
    this.homeAddress = null;
    this.officeAddress = null;
    this.homeOptions = null;
    this.officeOptions = null;
    this.options = null;
    makeAutoObservable(this);
  }

  async createApplicationAlternateAddress({
    namePrefix,
    nameFirst,
    nameLast,
    email,
    phoneNumber,
    phoneType,
    country,
    addressLine1,
    addressLine2,
    city,
    state,
    zip,
    addressType,
  }) {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    const body = {
      address: {
        address_line_1: addressLine1,
        address_line_2: addressLine2,
        city: city,
        state: state,
        zip: zip,
        country: country,
        phone_number: phoneNumber,
        phone_type: phoneType,
        address_type: addressType,
        email: email,
        name_prefix: namePrefix,
        name_first: nameFirst,
        name_last: nameLast,
      },
      application: ApplicationStore.application.id,
    };

    const result = await ApplicationService.createApplicationSectionItem({
      body: body,
      applicationId: ApplicationStore.application.id,
      section: 'addresses',
    });
    if (!Array.isArray(result.data)) {
      this.alternateAddress = result.data;
    } else {
      this.alternateAddress = null;
    }
  }

  async createApplicationHomeAddress({
    email,
    phoneNumber,
    phoneType,
    country,
    addressLine1,
    addressLine2,
    city,
    state,
    zip,
    addressType,
  }) {
    const body = {
      address_line_1: addressLine1,
      address_line_2: addressLine2,
      city: city,
      state: state,
      zip: zip,
      country: country,
      phone_number: phoneNumber,
      phone_type: phoneType,
      address_type: addressType,
      email: email,
    };

    const result = await UserService.createUserHomeAddress({
      id: AuthStore.id,
      body: body,
    });
    if (!Array.isArray(result.data)) {
      this.homeAddress = result.data;
    } else {
      this.homeAddress = null;
    }
  }

  async createApplicationOfficeAddress({
    title,
    institution,
    institutionOther,
    department,
    hall,
    email,
    phoneNumber,
    phoneType,
    country,
    addressLine1,
    addressLine2,
    city,
    state,
    zip,
    addressType,
  }) {
    const body = {
      title: title,
      institution: institution,
      institution_other: institutionOther,
      institution_type: institutionOther ? 'O' : 'I',
      department: department,
      hall: hall,
      address_line_1: addressLine1,
      address_line_2: addressLine2,
      city: city,
      state: state,
      zip: zip,
      country: country,
      phone_number: phoneNumber,
      phone_type: phoneType,
      address_type: addressType,
      email: email,
    };

    const result = await UserService.createUserOfficeAddress({
      id: AuthStore.id,
      body: body,
    });
    if (!Array.isArray(result.data)) {
      this.officeAddress = result.data;
    } else {
      this.officeAddress = null;
    }
  }

  async updateApplicationHomeAddress({
    email,
    phoneNumber,
    phoneType,
    country,
    addressLine1,
    addressLine2,
    city,
    state,
    zip,
    addressType,
  }) {
    const body = {
      address_line_1: addressLine1,
      address_line_2: addressLine2,
      city: city,
      state: state,
      zip: zip,
      country: country,
      phone_number: phoneNumber,
      phone_type: phoneType,
      address_type: addressType,
      email: email,
    };

    await UserService.patchUserHomeAddress({ id: AuthStore.id, body });
    await this.getApplicationHomeAddress();
  }

  async updateApplicationOfficeAddress({
    title,
    institution,
    institutionOther,
    department,
    hall,
    email,
    phoneNumber,
    phoneType,
    country,
    addressLine1,
    addressLine2,
    city,
    state,
    zip,
    addressType,
  }) {
    const body = {
      title: title,
      institution: institution,
      institution_other: institutionOther,
      institution_type: institutionOther ? 'O' : 'I',
      department: department,
      hall: hall,
      address_line_1: addressLine1,
      address_line_2: addressLine2,
      city: city,
      state: state,
      zip: zip,
      country: country,
      phone_number: phoneNumber,
      phone_type: phoneType,
      address_type: addressType,
      email: email,
    };

    await UserService.patchUserOfficeAddress({ id: AuthStore.id, body });
    await this.getApplicationOfficeAddress();
  }

  async updateApplicationAlternateAddress(
    {
      namePrefix,
      nameFirst,
      nameLast,
      email,
      phoneNumber,
      phoneType,
      country,
      addressLine1,
      addressLine2,
      city,
      state,
      zip,
      addressType,
    },
    id,
  ) {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    const body = {
      address: {
        address_line_1: addressLine1,
        address_line_2: addressLine2,
        city: city,
        state: state,
        zip: zip,
        country: country,
        phone_number: phoneNumber,
        phone_type: phoneType,
        address_type: addressType,
        email: email,
        name_prefix: namePrefix,
        name_first: nameFirst,
        name_last: nameLast,
      },
      application: ApplicationStore.application.id,
    };

    await ApplicationService.updateApplicationSectionItem({
      body: body,
      applicationId: ApplicationStore.application.id,
      section: 'addresses',
      id: id,
    });
    await this.getApplicationAlternateAddress();
  }

  async getApplicationAlternateAddress() {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    const result = await ApplicationService.getCurrentApplicationSectionItem({
      applicationId: ApplicationStore.application.id,
      section: 'addresses',
    });
    this.alternateAddress = result.data[0];
  }

  async getApplicationHomeAddress() {
    const result = await UserService.getUserHomeAddress({
      id: AuthStore.id,
    });

    if (!Array.isArray(result.data)) {
      this.homeAddress = result.data;
    } else {
      this.homeAddress = null;
    }
  }

  async getApplicationOfficeAddress() {
    const result = await UserService.getUserOfficeAddress({
      id: AuthStore.id,
    });

    if (!Array.isArray(result.data)) {
      this.officeAddress = result.data;
    } else {
      this.officeAddress = null;
    }
  }

  async getApplicationAddressesSection() {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    const result = await ApplicationService.getCurrentApplicationSection({
      applicationId: ApplicationStore.application.id,
      section: 'addresses',
    });
    this.section = result.data[0];
  }

  async acknowledgeApplicationAddressesSection() {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }
    if (!this.section) {
      await this.getApplicationAddressesSection();
    }

    const body = {
      acknowledged: true,
      application: this.section.application,
    };

    return ApplicationService.updateApplicationSection({
      body: body,
      applicationId: ApplicationStore.application.id,
      section: 'addresses',
      id: this.section.id,
    }).then((resp) => (this.section = resp.data));
  }

  async unacknowledgeApplicationAddressesSection() {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }
    if (!this.section) {
      await this.getApplicationAddressesSection();
    }

    const body = {
      acknowledged: false,
      application: this.section.application,
    };

    return ApplicationService.updateApplicationSection({
      body: body,
      applicationId: ApplicationStore.application.id,
      section: 'addresses',
      id: this.section.id,
    }).then((resp) => (this.section = resp.data));
  }

  async getApplicationHomeAddressOptions() {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    const result = await UserService.getUserHomeAddressOptions({
      id: AuthStore.id,
    });
    this.homeOptions = result.data;
  }

  async getApplicationOfficeAddressOptions() {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    const result = await UserService.getUserOfficeAddressOptions({
      id: AuthStore.id,
    });
    this.officeOptions = result.data;
  }

  async getApplicationAddressesOptions() {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    const result = await ApplicationService.getCurrentApplicationSectionOptions(
      {
        applicationId: ApplicationStore.application.id,
        section: 'addresses',
      },
    );
    this.options = result.data;
  }

  async deleteApplicationAlternateAddress(id) {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    await ApplicationService.deleteApplicationSectionItem({
      id: id,
      section: 'addresses',
      applicationId: ApplicationStore.application.id,
    });

    await this.getApplicationAlternateAddress();
  }

  async deleteUserHomeAddress({ userId }) {
    await UserService.deleteUserHomeAddress({
      id: userId,
    });
    await this.getApplicationHomeAddress();
  }

  async deleteUserOfficeAddress({ userId }) {
    await UserService.deleteUserOfficeAddress({
      id: userId,
    });
    await this.getApplicationOfficeAddress();
  }

  async preferOfficeAddress({ userId }) {
    await UserService.preferOfficeAddress({ userId });
    if (!!this.homeAddress) {
      await UserService.unpreferHomeAddress({ userId });
    }
    await this.getApplicationOfficeAddress();
    await this.getApplicationHomeAddress();
  }

  async preferHomeAddress({ userId }) {
    await UserService.preferHomeAddress({ userId });
    if (!!this.officeAddress) {
      await UserService.unpreferOfficeAddress({ userId });
    }
    await this.getApplicationOfficeAddress();
    await this.getApplicationHomeAddress();
  }
}

export default new AddressesStore();
