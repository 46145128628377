import makeStyles from '@material-ui/core/styles/makeStyles';
import { colors, miscVariables, spacing } from '@styles';

export const AddressesSectionStyles = makeStyles({
  cardsWrapper: {
    padding: '15px 0',
  },
  cardRoot: {
    margin: spacing.spacing_sm,
    width: miscVariables.card_root_width,
    height: miscVariables.card_root_height,
    padding: '8px 0 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cardRootMobile: {
    margin: '8px auto',
    width: miscVariables.card_root_width,
    height: miscVariables.card_root_height,
    padding: '12px 0 0',
  },
  cardContent: {
    padding: '0 8px 8px 16px',
  },
  radio: {
    '&:checked': {
      color: 'green',
    },
  },
  preferredText: {
    fontSize: '13px',
  },
  addressCard: {
    margin: '10px 0',
    flexGrow: 1,
  },
  cardAction: {
    textTransform: 'none',
    color: colors.blue_light,
    marginTop: 'auto',
  },
  radioColor: {
    '&::checked': {
      color: 'green',
    },
  },
  hide: {
    opacity: '0',
  },
});
