import { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteIcon from '@material-ui/icons/Delete';
import { AddressesStore } from '@stores';
import { observer } from 'mobx-react-lite';
import AlternateAddressForm from './AlternateAddressForm';

const AlternateAddressCard = observer(({ unacknowledgeSection }) => {
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [fields, setFields] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      await AddressesStore.getApplicationAlternateAddress();
      setLoading(false);
    }

    void fetchData();
  }, []);

  const handleOpen = () => {
    if (AddressesStore.alternateAddress)
      setFields(AddressesStore.alternateAddress);
    setShow(true);
  };
  const handleClose = () => {
    setFields(null);
    setShow(false);
  };

  const handleOpenDelete = () => {
    setShowDelete(true);
  };

  const handleCloseDelete = () => {
    setShowDelete(false);
  };

  const deleteAddress = async () => {
    handleCloseDelete();
    setLoading(true);
    await AddressesStore.deleteApplicationAlternateAddress(
      AddressesStore.alternateAddress.id,
    )
      .then(() => {
        unacknowledgeSection();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderDeleteDialog = () => (
    <Dialog open={showDelete}>
      <DialogTitle>Attention</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure want do delete?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={deleteAddress}
          variant="contained"
          color="secondary"
          startIcon={<DeleteIcon />}>
          Delete
        </Button>
        <Button onClick={handleCloseDelete}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );

  const renderDeleteButton = () =>
    AddressesStore.alternateAddress ? (
      <button className="text-md text-red-400 my-2" onClick={handleOpenDelete}>
        Delete
      </button>
    ) : null;

  return (
    <>
      <Card>
        <CardContent>
          {loading ? (
            <CircularProgress />
          ) : (
            <div className="p-4">
              <h3 className="text-3xl font-semibold mb-2 mt-3">
                Alternate Contact <span className="text-red-600">*</span>
              </h3>
              {AddressesStore.alternateAddress ? (
                <>
                  <p>
                    {AddressesStore.alternateAddress.address.name_prefix}{' '}
                    {AddressesStore.alternateAddress.address.name_first}{' '}
                    {AddressesStore.alternateAddress.address.name_last}
                  </p>
                  {AddressesStore.alternateAddress.address.institution_other ? (
                    <p>
                      {
                        AddressesStore.alternateAddress.address
                          .institution_other
                      }
                    </p>
                  ) : null}
                  {AddressesStore.alternateAddress.address.hall ? (
                    <p>{AddressesStore.alternateAddress.address.hall}</p>
                  ) : null}
                  {AddressesStore.alternateAddress.address.address_line_1 ? (
                    <p>
                      {AddressesStore.alternateAddress.address.address_line_1}
                    </p>
                  ) : null}
                  {AddressesStore.alternateAddress.address.address_line_2 ? (
                    <p>
                      {AddressesStore.alternateAddress.address.address_line_2}
                    </p>
                  ) : null}
                  <p>
                    {AddressesStore.alternateAddress.address.city},&nbsp;
                    {AddressesStore.alternateAddress.address.state},&nbsp;
                    {AddressesStore.alternateAddress.address.zip}
                  </p>
                </>
              ) : (
                <p>
                  Enter an address for an alternate contact person other than{' '}
                  yourself
                </p>
              )}
              <button
                className="text-md text-blue-400 my-6"
                onClick={handleOpen}>
                Edit Address
              </button>
              <br />
              {renderDeleteButton()}
            </div>
          )}
        </CardContent>
      </Card>
      <Dialog open={show} fullWidth maxWidth="lg">
        <DialogContent>
          <AlternateAddressForm fields={fields} submitHandler={handleClose} />
        </DialogContent>
      </Dialog>
      {renderDeleteDialog()}
    </>
  );
});

export default AlternateAddressCard;
