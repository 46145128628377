import withStyles from '@material-ui/core/styles/withStyles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { observer } from 'mobx-react-lite';
import { useDataTableStyles } from '../styles';

/**
 * `handleClickSort` the function determines when to delete the sorting.
 * - When you click on the column for the 1st time, it sets the "asc"
 * - When you click for the 2nd time, it sets the "desc"
 * - When you click for the 3rd time, it removes the sorting
 */

const StyledTableCell = withStyles(() => ({
  head: {
    border: 'none',
    padding: '8px',
  },
}))(TableCell);

export const TableHeader = observer(
  ({ columns, urlParams, setSort, removeSort }) => {
    const classes = useDataTableStyles();
    const sortingDirection = urlParams.get('sorting_direction');
    const orderBy = urlParams.get('order_by');

    const handleClickSort = (headCell) => {
      if (sortingDirection && orderBy && orderBy === headCell) {
        if (sortingDirection === 'asc') {
          return setSort(headCell, 'desc');
        }
        if (sortingDirection === 'desc') {
          return removeSort();
        }
      }

      return setSort(headCell, 'asc');
    };

    return (
      <TableHead>
        <TableRow>
          {columns.map((headCell) => (
            <StyledTableCell
              key={headCell.id}
              align="left"
              className={classes.tableHeadCell}
              padding="default">
              <TableSortLabel
                disabled={!headCell.sortable}
                active={headCell.id === orderBy}
                direction={sortingDirection || 'asc'}
                onClick={() => handleClickSort(headCell.id)}>
                {headCell.label}
              </TableSortLabel>
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  },
);
