import { ResetConfirmForm } from './components';

const PasswordResetConfirmScene = () => (
  <div className="text-gray-500">
    <h1
      className="
              text-5xl text-black
              font-calibri font-normal
              text-center
              my-10
              md:my-28
            ">
      Reset Password
    </h1>
    <div className="px-8 py-4 bg-gray-100 rounded-md mx-auto max-w-xl">
      <ResetConfirmForm />
    </div>
  </div>
);

export default PasswordResetConfirmScene;
