import { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { UserStore } from '@stores';
import { observer } from 'mobx-react-lite';

const UserContextLayout = observer(({ children }) => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    async function fetchData() {
      if (!UserStore.user) {
        await UserStore.getCurrentUser();
      }
      await setLoading(false);
    }

    fetchData();
  }, []);

  if (
    UserStore.shouldUserNavigateToStart() &&
    location.pathname !== '/user/profile'
  ) {
    return <Redirect to="/user/profile" />;
  }

  return loading ? null : children;
});

export default UserContextLayout;
