import { makeAutoObservable } from 'mobx';
import NotesService from '../services/notes.service';

export default class Note {
  constructor(note) {
    this.id = note.id;
    this.fm_id = note.fm_id;
    this.application = note.application;
    this.user = note.user;
    this.type = note.type;
    this.section = note.section;
    this.note = note.note;
    this.sent_in_revision_request = note.sent_in_revision_request;
    this.date_created = note.date_created;
    this.date_last_modified = note.date_last_modified;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  updateNote(updatableNote) {
    this.note = updatableNote;
  }

  async update() {
    await NotesService.patchNoteItem(this.id, this.note, this.application);
  }
}
