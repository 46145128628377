import { HelpText } from '@components';

const ApplicationCreationClosedCard = () => (
  <div className="text-center my-20">
    <HelpText
      className="text-center text-2xl text-blue-900"
      metaTextName="competition-closed-create"
    />
  </div>
);

export default ApplicationCreationClosedCard;
