import { ApplicationService } from '@services';
import { ApplicationStore } from '@stores';
import { makeAutoObservable } from 'mobx';

class OrganizationsStore {
  constructor() {
    this.section = null;
    this.organizations = [];
    this.options = null;
    makeAutoObservable(this);
  }

  async createApplicationOrganization({ name }) {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    const body = {
      name: name,
      application: ApplicationStore.application.id,
    };

    await ApplicationService.createApplicationSectionItem({
      body: body,
      applicationId: ApplicationStore.application.id,
      section: 'organizations',
    });
    await this.getApplicationOrganizations();
  }

  async updateApplicationOrganization({ name }, id) {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    const body = {
      name: name,
      application: ApplicationStore.application.id,
    };

    await ApplicationService.updateApplicationSectionItem({
      body: body,
      applicationId: ApplicationStore.application.id,
      section: 'organizations',
      id: id,
    });
    await this.getApplicationOrganizations();
  }

  async getApplicationOrganizations() {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    const result = await ApplicationService.getCurrentApplicationSectionItem({
      applicationId: ApplicationStore.application.id,
      section: 'organizations',
    });
    this.organizations = result.data;
  }

  async getApplicationOrganizationsSection() {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    const result = await ApplicationService.getCurrentApplicationSection({
      applicationId: ApplicationStore.application.id,
      section: 'organizations',
    });
    this.section = result.data[0];
  }

  async acknowledgeApplicationOrganizationsSection() {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }
    if (!this.section) {
      await this.getApplicationOrganizationsSection();
    }

    const body = {
      acknowledged: true,
      application: this.section.application,
    };

    const result = await ApplicationService.updateApplicationSection({
      body: body,
      applicationId: ApplicationStore.application.id,
      section: 'organizations',
      id: this.section.id,
    });
    this.section = result.data;
  }

  async unacknowledgeApplicationOrganizationsSection() {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }
    if (!this.section) {
      await this.getApplicationOrganizationsSection();
    }

    const body = {
      acknowledged: false,
      application: this.section.application,
    };

    const result = await ApplicationService.updateApplicationSection({
      body: body,
      applicationId: ApplicationStore.application.id,
      section: 'organizations',
      id: this.section.id,
    });
    this.section = result.data;
  }

  async getApplicationOrganizationsOptions() {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    const result = await ApplicationService.getCurrentApplicationSectionOptions(
      {
        applicationId: ApplicationStore.application.id,
        section: 'organizations',
      },
    );
    this.options = result.data;
  }

  async deleteApplicationOrganization(id) {
    if (!ApplicationStore.application) {
      await ApplicationStore.getCurrentUserApplication();
    }

    await ApplicationService.deleteApplicationSectionItem({
      id: id,
      section: 'organizations',
      applicationId: ApplicationStore.application.id,
    });

    await this.getApplicationOrganizations();
  }
}

export default new OrganizationsStore();
