import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { colors } from '@styles';

const useStyles = makeStyles({
  dialogAction: {
    background: colors.gray,
    textTransform: 'none',
    color: colors.black,
    margin: '0 8px',
  },
});

export const WarningDialog = ({
  open,
  onClose,
  onSave,
  title,
  submitButtonText,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">Warning!</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {title}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid container justify="center" alignItems="center">
          <Button
            className={classes.dialogAction}
            onClick={onClose}
            color="primary"
            size="small"
            autoFocus>
            Cancel
          </Button>
          <Button
            className={classes.dialogAction}
            onClick={onSave}
            size="small"
            color="primary">
            {submitButtonText}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
