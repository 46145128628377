import { lighten } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { colors, mixins, spacing } from '@styles';

export const useDataTableStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paperWrapper: {
    width: '97%',
    margin: '8px auto 0',
    position: 'sticky',
  },
  tableContainer: {
    height: '65vh',
  },
  tableHeadCell: {
    fontWeight: 'bold',
  },
  tableRow: {
    cursor: 'pointer',
  },
  viewTableCell: {
    color: colors.blue_light,
    width: '10%',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: spacing.spacing_mdxl,
    width: 1,
  },
  rootToolbar: {
    ...mixins.flexToolBar,
    padding: '0 40px',
  },
  buttonToolbar: {
    marginLeft: spacing.spacing_mdl,
    textTransform: 'none',
    background: colors.gray,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
}));
