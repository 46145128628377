import { http } from '@utils';

export default class UtilsService {
  static getCountries() {
    return http.options('/api/country-list/');
  }

  static async getCurrentCompetition() {
    const response = await http.get('/api/competition/current/');
    return response.data;
  }

  static getStatuses(applicationId) {
    return http.options(`/api/applications/${applicationId}`);
  }

  static getGroupsets() {
    return http.get('/api/meta/groupsets/');
  }

  static getCurrentGroupsets() {
    return http.get('/api/meta/groupsets/', {
      params: {
        current: true,
      },
    });
  }

  static getInstitutions() {
    return http.get('/api/institutions/');
  }

  static getInstitutionStates() {
    return http.get('/api/institutions/states/');
  }

  static getAccountTermsAndConditions() {
    return http.get('/api/meta/text/toc/');
  }

  static getMetaTexts() {
    return http.get('/api/meta/text/');
  }

  static fetchMetaPasswordCriteria() {
    return http.get('api/meta/text/password_criteria/');
  }

  static fetchPhoneTypes({ id, section }) {
    return http.options(`/api/applications/${id}/${section}/`);
  }
}
