import { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import { AdminApplicationLayoutStore } from '@stores';
import { colors, miscVariables } from '@styles';
import { observer } from 'mobx-react-lite';
import RevisionRequestDialogStore from '../../services/RevisionRequestDialogStore';

const useStyles = makeStyles({
  dialogAction: {
    background: colors.gray,
    textTransform: 'none',
    color: colors.black,
    margin: '0 8px',
  },
  dialogRoot: {
    minWidth: miscVariables.dialog_root_min_width,
  },
  actionsWrapper: {
    padding: '16px 0',
  },
});

export const RevisionRequestContent = observer(({ content, applicationId }) => {
  const classes = useStyles();
  const { isMobile, showRevisionRequestDialog, setCloseRevisionRequestDialog } =
    AdminApplicationLayoutStore;
  const [inputValue, setInputValue] = useState(content.body);

  const handleInputFieldChange = (event) => {
    const { value } = event.target;
    setInputValue(value);
  };

  const handleSave = () => {
    RevisionRequestDialogStore.sendRevisionRequestContent(
      applicationId,
      inputValue,
    );
  };

  return (
    <Dialog
      className={classes.dialogRoot}
      open={showRevisionRequestDialog}
      fullScreen={isMobile}
      scroll="paper"
      maxWidth="md">
      <DialogTitle>Revision request email body</DialogTitle>
      <DialogContent className={classes.dialogRoot}>
        <TextField
          multiline
          fullWidth
          rows={10}
          value={inputValue}
          variant="outlined"
          onChange={handleInputFieldChange}
        />
      </DialogContent>
      <DialogActions className={classes.actionsWrapper}>
        <Grid container justify="center" alignItems="center">
          <Button
            className={classes.dialogAction}
            onClick={setCloseRevisionRequestDialog}
            color="primary">
            Cancel
          </Button>
          <Button
            className={classes.dialogAction}
            onClick={handleSave}
            color="primary">
            Send
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
});
