import { useEffect, useRef, useState } from 'react';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import { UtilsStore } from '@stores';

const TermsAndConditionsDialog = ({ onClose, onSave, open }) => {
  const [loading, setLoading] = useState(true);
  const ref = useRef();

  useEffect(() => {
    async function fetchData() {
      await UtilsStore.getAccountTC();
      await setLoading(false);
      ref.current.innerHTML = UtilsStore.accountTC?.value || '';
    }

    fetchData();
  }, []);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogContent>
        <h2 className="text-2xl text-center mb-4">Terms & Conditions</h2>
        {loading ? (
          <div className="text-center">
            <CircularProgress />
          </div>
        ) : (
          <p ref={ref} />
        )}
      </DialogContent>
      <DialogActions>
        <button
          onClick={onClose}
          type="button"
          className="px-8 py-3 text-2xl bg-gray-400 text-white rounded-md">
          Cancel
        </button>
        <button
          onClick={onSave}
          type="button"
          className="px-8 py-3 text-2xl bg-primary text-white rounded-md">
          Accept
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default TermsAndConditionsDialog;
