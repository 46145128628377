import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { HelpInfo, HelpText, UserNavbar } from '@components';
import { ApplicationStore, UserStore } from '@stores';
import { observer } from 'mobx-react-lite';

const Home = observer(() => {
  if (UserStore.shouldUserNavigateToStart()) {
    return <Redirect to="/user/profile" />;
  }

  useEffect(() => {
    async function fetchData() {
      await ApplicationStore.getCurrentUserApplication();
    }

    fetchData();
  }, []);

  return (
    <>
      <div className="mt-10">
        <h1 className="text-4xl text-black font-calibri font-normal mt-2">
          Home&nbsp;
          <HelpInfo metaTextName="account-home" />
        </h1>
      </div>
      <div className="border rounded-md border-gray-300 md:mt-10 shadow-lg">
        <div className="flex flex-col md:flex-row">
          <UserNavbar />
          <div className="p-8 w-full md:w-3/4">
            <div
              className="
                  flex flex-col
                  md:flex-nowrap
                  justify-center
                  md:justify-around
                  p-8
                ">
              <HelpText
                metaTextName="account-home"
                className="text-center text-2xl text-blue-900"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default Home;
