import { privateResolverWithPayload } from '@services';
import { ApplicationStore } from '@stores';
import { makeAutoObservable } from 'mobx';
import AdminSectionsService from '../services/adminSections.service';

export class SectionStatus {
  constructor(status) {
    this.id = status.id;
    this.application = status.application;
    this.editable = status.editable;
    this.acknowledged = status.acknowledged;
    this.approved = status.approved;
    this.date_created = status.date_created;
    this.date_last_modified = status.date_last_modified;
    this.editable_pending = status.editable_pending;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setStatus(updatedStatus) {
    ApplicationStore.getSectionStatus(updatedStatus.application);
    ApplicationStore.getVerifiableApplication({
      id: updatedStatus.application,
    });
  }

  update({ status, applicationId, sectionName, onCloseDialog }) {
    const payload = { id: applicationId, section: sectionName, status };
    privateResolverWithPayload({
      fetcher: AdminSectionsService.patchSectionStatusItem,
      payload,
      setter: this.setStatus,
      closeDialog: onCloseDialog,
    });
  }
}
