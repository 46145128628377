import { InputSelectField } from '@components';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { AdminApplicationLayoutStore } from '@stores';
import { observer } from 'mobx-react-lite';
import SectionNoteDialogStore from '../../../services/SectionNoteDialogStore';
import { useDocumentsSectionStyles } from '../services/styles';

export const DocumentItemCard = observer(
  ({ document, disabledSection, documentReviewedStatusTypes }) => {
    const classes = useDocumentsSectionStyles();
    const { isMobile } = AdminApplicationLayoutStore;
    const { setVerifiableSectionName } = SectionNoteDialogStore;

    const handleCheckboxClick = (event) => {
      const { name, checked } = event.target;
      if (name === 'editable' && checked) {
        setVerifiableSectionName('document', disabledSection);
        return document.document.update({
          editable: true,
          approved: false,
        });
      }
      if (name === 'approved' && checked) {
        return document.document.update({
          editable: false,
          approved: true,
        });
      }
      document.document.update({ [name]: checked });
    };

    const handleSelectFieldChange = (name, value) => {
      document.document.update({
        [name]: value,
      });
    };

    return (
      <Card
        key={document.id}
        variant="outlined"
        className={isMobile ? classes.cardRootMobile : classes.cardRoot}>
        <CardContent className={classes.cardContent}>
          <Typography variant="h6" gutterBottom>
            {document.document.document_type.name.split(' ')[1]}
            <span className={classes.documentTitleMark}>*</span>
          </Typography>
          <InputSelectField
            disabled={disabledSection}
            name="reviewed_status"
            label="Reviewer Status"
            value={document.document.reviewed_status}
            options={documentReviewedStatusTypes}
            onClick={handleSelectFieldChange}
          />
          <FormControlLabel
            labelPlacement="end"
            control={
              <Checkbox
                checked={document.document.approved}
                onClick={handleCheckboxClick}
                color="default"
                name="approved"
                disabled={disabledSection}
              />
            }
            label="Approved"
          />
          <FormControlLabel
            labelPlacement="end"
            control={
              <Checkbox
                checked={document.document.editable}
                onClick={handleCheckboxClick}
                color="default"
                name="editable"
                disabled={disabledSection}
              />
            }
            label="Editable"
          />
        </CardContent>
        <CardActions disableSpacing className={classes.cardAction}>
          <Link
            target="_blank"
            href={document.document.file}
            className={classes.nameDocumentCard}>
            View document
          </Link>
          <Typography variant="overline" component="p">
            {document.document.name}
          </Typography>
        </CardActions>
      </Card>
    );
  },
);
