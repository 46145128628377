import { useEffect, useState } from 'react';
import { CompetitionStore } from '@stores';
import { observer } from 'mobx-react-lite';

const CompetitionContextLayout = observer(({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      if (!CompetitionStore.currentCompetition) {
        await CompetitionStore.getCurrentCompetition();
      }
      await setLoading(false);
    }

    fetchData();
  }, []);

  return loading ? null : children;
});

export default CompetitionContextLayout;
