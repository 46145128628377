import { privateResolverWithPayload } from '@services';
import { makeAutoObservable } from 'mobx';
import { ReferencesService } from '../services/references.service';

export class Reference {
  constructor(reference) {
    this.id = reference.id;
    this.fm_id = reference.fm_id;
    this.application = reference.application;
    this.applicant_name = reference.applicant_name;
    this.address = reference.address;
    this.matched_user = reference.matched_user;
    this.date_created = reference.date_created;
    this.date_last_modified = reference.date_last_modified;
    this.date_reference_requested = reference.date_reference_requested;
    this.requested = reference.requested;
    this.date_file_submitted = reference.date_file_submitted;
    this.declined_reason = reference.declined_reason;
    this.matched_user_substituted = reference.matched_user_substituted;
    this.active = reference.active;
    this.letter = reference.letter;
    this.editable = reference.editable;
    this.approved = reference.approved;
    this.revision_requested = reference.revision_requested;
    this.reviewed_status = reference.reviewed_status;
    this.note = reference.note;
    this.received = reference.received;
    this.revision_received = reference.revision_received;
    this.display_status = reference.display_status;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  update(reference) {
    Object.keys(reference).map((key) => (this[key] = reference[key]));
  }

  get fullName() {
    return `${this.address.name_first} ${this.address.name_last}`;
  }

  patch(payload, closeDialogAction) {
    const patchPayload = {
      id: this.id,
      ...payload,
    };

    privateResolverWithPayload({
      fetcher: ReferencesService.patchReference,
      payload: patchPayload,
      setter: this.update,
      closeDialog: closeDialogAction,
    });
  }
}
