import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import { colors } from '@styles';
import { PencilBoxOutline } from 'mdi-material-ui';

const StyledCheckbox = withStyles({
  root: {
    color: colors.white_01,
    '&$checked': {
      color: colors.white,
    },
  },
})((props) => <Checkbox disableRipple color="default" {...props} />);

const StyledIconButton = withStyles({
  root: {
    color: '#e7e7e7',
  },
})((props) => <IconButton color="default" {...props} />);

export const InputCheckboxField = ({
  checked,
  onChange,
  disabled,
  name,
  label,
  showPencilBox = false,
  onClickPencil,
}) => (
  <>
    <FormControlLabel
      control={<StyledCheckbox checked={checked} />}
      onChange={onChange}
      disabled={disabled}
      name={name}
      label={label}
      labelPlacement="start"
    />
    {showPencilBox && checked ? (
      <StyledIconButton onClick={() => onClickPencil(name)} size="small">
        <PencilBoxOutline />
      </StyledIconButton>
    ) : null}
  </>
);
