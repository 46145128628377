import { http } from '@utils';

class ReferencesService {
  static getReferences({ userId }) {
    return http.get(`/api/users/${userId}/references/`);
  }

  static declineReference({ userId, body, referenceId }) {
    return http.patch(`/api/users/${userId}/references/${referenceId}/`, body);
  }

  static getReferenceOptions({ userId }) {
    return http.options(`/api/users/${userId}/references/`);
  }

  static acceptReference({ userId, body, referenceId }) {
    return http.patch(`/api/users/${userId}/references/${referenceId}/`, body);
  }
}

export default ReferencesService;
