import { useEffect, useState } from 'react';
import { HelpInfo, HomeAddressCard, OfficeAddressCard } from '@components';
import { SectionsStore, AddressesStore, ProgressStore } from '@stores';
import { observer } from 'mobx-react-lite';
import {
  AcknowledgeButton,
  SectionContentLayout,
  SectionHeader,
  SectionHeaderHint,
  SectionLayout,
} from '../../components';
import { AlternateAddressCard } from './components';

const AddressesScene = observer(() => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      await AddressesStore.getApplicationAddressesSection();
      await AddressesStore.getApplicationAlternateAddress();
      await AddressesStore.getApplicationOfficeAddress();
      await AddressesStore.getApplicationHomeAddress();
      await setLoading(false);
    }

    fetchData();
  }, []);

  const acknowledgeSection = async () => {
    ProgressStore.setInProgress();
    await AddressesStore.acknowledgeApplicationAddressesSection()
      .then(async () => await SectionsStore.getApplicationSectionsStatuses())
      .finally(() => ProgressStore.setNotInProgress());
  };

  const unacknowledgeSection = async () => {
    ProgressStore.setInProgress();
    await AddressesStore.unacknowledgeApplicationAddressesSection()
      .then(async () => await SectionsStore.getApplicationSectionsStatuses())
      .finally(() => ProgressStore.setNotInProgress());
  };

  const renderAcknowledge = () => {
    if (
      AddressesStore.section.editable &&
      !AddressesStore.section.acknowledged
    ) {
      return (
        <div className="flex flex-col md:flex-row">
          <SectionHeaderHint hintName="unacknowledged" />
          <AcknowledgeButton
            onClick={acknowledgeSection}
            disabled={
              !(
                AddressesStore.alternateAddress &&
                AddressesStore.homeAddress &&
                (AddressesStore.homeAddress?.preferred ||
                  AddressesStore.officeAddress?.preferred)
              )
            }>
            Acknowledge section and continue
          </AcknowledgeButton>
        </div>
      );
    }

    if (
      AddressesStore.section.editable &&
      AddressesStore.section.acknowledged
    ) {
      return (
        <div className="flex flex-row">
          <SectionHeaderHint hintName="acknowledged" />
          <AcknowledgeButton onClick={unacknowledgeSection}>
            Unacknowledge
          </AcknowledgeButton>
        </div>
      );
    }

    if (!AddressesStore.section.editable) {
      return (
        <div className="flex flex-row">
          <SectionHeaderHint hintName="submitted" />
          <AcknowledgeButton disabled>Submitted</AcknowledgeButton>
        </div>
      );
    }

    return null;
  };

  const renderContent = () => {
    if (AddressesStore.section.editable) {
      return (
        <div className="flex flex-row flex-wrap w-full">
          <div className="md:w-1/3 p-2">
            <HomeAddressCard unacknowledgeSection={unacknowledgeSection} />
          </div>
          <div className="md:w-1/3 p-2">
            <AlternateAddressCard unacknowledgeSection={unacknowledgeSection} />
          </div>
          <div className="md:w-1/3 p-2">
            <OfficeAddressCard unacknowledgeSection={unacknowledgeSection} />
          </div>
        </div>
      );
    } else {
      return 'This section has been final submitted and has not been unlocked for a Revision Request.';
    }
  };

  const renderPreferHint = () => {
    if (
      AddressesStore.alternateAddress &&
      AddressesStore.homeAddress &&
      !(
        AddressesStore.homeAddress?.preferred ||
        AddressesStore.officeAddress?.preferred
      )
    ) {
      return (
        <div className="container my-1">
          <p className="text-red-600 text-right">
            You have to select the "Preferred Address"
          </p>
        </div>
      );
    } else {
      return null;
    }
  };

  const renderSection = () => (
    <SectionLayout>
      <SectionHeader>
        <h1 className="text-4xl text-black font-calibri font-normal md:mt-2 text-center md:text-left mb-2 md:mb-0">
          Edit Addresses&nbsp;
          <HelpInfo metaTextName="application-addresses" />
        </h1>
        {renderAcknowledge()}
      </SectionHeader>
      {renderPreferHint()}
      <SectionContentLayout>{renderContent()}</SectionContentLayout>
    </SectionLayout>
  );

  return loading ? null : renderSection();
});
export default AddressesScene;
