import { useHistory } from 'react-router-dom';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { UserStore } from '@stores';
import { observer } from 'mobx-react-lite';

const ReferencesLink = observer(() => {
  const history = useHistory();

  if (!UserStore.user.user_summary.reference) {
    return null;
  }

  return (
    <li>
      <div
        className="user-navbar__item"
        onClick={() => history.push('/references')}>
        <div>References&nbsp;</div>
        <RadioButtonUncheckedIcon className="user-navbar__item-icon user-navbar__item-icon_unchecked" />
      </div>
    </li>
  );
});

export default ReferencesLink;
